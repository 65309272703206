import { Button, Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';

function LoanIdModal({ isModalOpen, handleOk, handleCancel, onFinish, isLoading, preFillData }) {
  return (
    <Modal title="Lender" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
      <Form layout="vertical" className="mt-6" onFinish={onFinish} initialValues={preFillData}>
        <Form.Item label="Loan ID" name="loanId" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <div className="text-right">
          <Button onClick={handleCancel} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default LoanIdModal;

LoanIdModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  onFinish: PropTypes.func,
  isLoading: PropTypes.bool,
  preFillData: PropTypes.object
};
