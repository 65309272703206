import { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Alert } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { API_HOST } from '../../helpers/constants';

export const SetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onFinish = async (values) => {
    const { password, confirmPassword } = values;
    if (password !== confirmPassword) {
      setMessage('Passwords do not match!');
      setIsAlertVisible(true);
      return;
    }
    const state = searchParams.get('state');
    const [encodedEmail, encodedToken, encodedType] = state.split('.');
    const email = atob(encodedEmail);
    const token = atob(encodedToken);
    const type = atob(encodedType);
    setIsProcessing(true);
    try {
      await axios.post(`${API_HOST}/auth/password`, {
        email,
        token,
        password,
        type
      });

      navigate('/auth/login', { replace: true });
    } catch (e) {
      if (e.response) {
        setMessage(e.response.data.message);
        setIsAlertVisible(true);
      }
    }
    setIsProcessing(false);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true
      }}
      onFinish={onFinish}>
      {isAlertVisible && (
        <Alert description={message} type="error" showIcon style={{ marginBottom: 20 }} />
      )}
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input password!'
          }
        ]}>
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          size="large"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: 'Please re enter the new password!'
          }
        ]}>
        <Input.Password
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Confirm Password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={isProcessing}>
          Set Password
        </Button>
      </Form.Item>
    </Form>
  );
};
