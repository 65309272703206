import { Modal } from 'antd';
import { memo } from 'react';
import PropTypes from 'prop-types';

function CpdPreviewModal({ isOpen, handleCancel, documentUrl, onSend, isLoading }) {
  return (
    <Modal
      title="Preview Credit Proposal Disclosure"
      open={isOpen}
      width={900}
      style={{ top: 20 }}
      cancelText="Close"
      onCancel={handleCancel}
      onOk={onSend}
      okButtonProps={{ loading: isLoading }}
      okText="Send">
      <div className="max-h-[600px] overflow-hidden pr-3">
        <iframe src={documentUrl} height="580" width="100%" className="border p-3 "></iframe>
      </div>
    </Modal>
  );
}

export default memo(CpdPreviewModal);

CpdPreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  documentUrl: PropTypes.string,
  handleCancel: PropTypes.func,
  onSend: PropTypes.func,
  isLoading: PropTypes.bool
};
