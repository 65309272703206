import { Alert, Form, Skeleton, Typography } from 'antd';
import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_HOST, STATUS_PRE_SETTLEMENT } from '../../../helpers/constants';
import useSWR from 'swr';
import { fetcher, headers } from '../../../utils';
import dayjs from 'dayjs';
import axios from 'axios';
import { getShouldEnablePreSettlement, openNotification } from '../../../helpers';
import CommonSettlementSection from '../../common/CommonSettlementSection';

function PreSettlement() {
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application, mutate } = useSWR(applicationUrl, fetcher);
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const preSettlementEndpoint = `${applicationUrl}/pre-settlement`;
  const loanIdUpdateEndpoint = `${applicationUrl}/loan-id`;
  const [isLoanIdModalOpen, setIsLoanIdModalOpen] = useState(false);
  const [isUpdatingLoanId, setIsUpdatingLoanId] = useState(false);

  const loanDetails = application?.loanDetails;
  const decisionData = loanDetails?.decision;
  const preSettlementData = loanDetails?.preSettlement;

  const onLoanDetailsEditClick = () => {
    setIsEditing(true);
  };

  const onLoanIdModalSubmission = async (data) => {
    try {
      setIsUpdatingLoanId(true);
      await axios.put(loanIdUpdateEndpoint, data, { headers });
      mutate();
      setIsUpdatingLoanId(false);
      setIsLoanIdModalOpen(false);
      openNotification('Loan ID updated successfully');
    } catch (error) {
      console.log(error);
      setIsUpdatingLoanId(false);
      openNotification('Could not update Loan ID', error.response?.data?.message, true);
    }
  };

  const savePreSettlement = async (data) => {
    try {
      setIsSubmitting(true);
      await axios.post(preSettlementEndpoint, isEditing ? data : null, { headers });
      setIsSubmitting(false);
      setIsEditing(false);
      openNotification('Pre-settlement saved successfully');
      await axios.patch(applicationUrl, { status: STATUS_PRE_SETTLEMENT }, { headers });
      mutate();
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      openNotification('Could not save pre-settlement', error.response?.data?.message, true);
    }
  };

  if (!application) return <Skeleton />;

  const shouldEnablePreSettlement = getShouldEnablePreSettlement(
    decisionData?.decidedAt,
    decisionData?.outcomeOfApplication
  );

  if (application && !shouldEnablePreSettlement)
    return (
      <Alert
        type="error"
        showIcon
        message="Pre-settlement tab can only be accessed once loan is approved in Decision tab"
        className="mt-5"
      />
    );

  return (
    <div className="pr-8 mt-4">
      {!!preSettlementData && (
        <Alert
          message={<Typography.Text strong>Application has been pre-settled</Typography.Text>}
          description={
            <span className="!text-xs">
              Application was pre-settled at{' '}
              {dayjs(preSettlementData?.preSettlementAt).format('DD/MM/YYYY hh:mm a')}
            </span>
          }
          type="success"
          showIcon
        />
      )}
      <CommonSettlementSection
        currentTabData={preSettlementData}
        previousTabData={decisionData}
        loanDetails={loanDetails}
        setIsLoanIdModalOpen={setIsLoanIdModalOpen}
        isLoanIdModalOpen={isLoanIdModalOpen}
        onLoanIdModalSubmission={onLoanIdModalSubmission}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        saveData={savePreSettlement}
        onLoanDetailsEditClick={onLoanDetailsEditClick}
        isSubmitting={isSubmitting}
        isUpdatingLoanId={isUpdatingLoanId}
        form={form}
      />
    </div>
  );
}

export default memo(PreSettlement);
