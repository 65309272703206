/* eslint-disable react/jsx-no-script-url */
/* eslint-disable no-script-url */
import { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Checkbox, Alert, Row, Col, Card, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { API_HOST, WEB_URL } from '../../helpers/constants';
import { parseJwt } from '../../helpers';

function LoginForm() {
  const [form] = Form.useForm();
  const [message, setMessage] = useState(null);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [additionalLink, setAdditionalLink] = useState(null);

  const sendVerificationEmail = async (email) => {
    axios.post(`${API_HOST}/auth/resend-invitation`, {
      email
    });
    setMessage({ label: 'Email has been sent', type: 'info' });
  };

  const onFinish = async (values) => {
    const { username: email, password } = values;
    setIsProcessing(true);

    try {
      const response = await axios.post(`${API_HOST}/auth/login`, {
        email,
        password
      });
      setIsAlertVisible(false);
      const { token } = response.data;
      const { preferences } = parseJwt(token);

      const obj = {
        token,
        preferences,
        user: parseJwt(token)
      };

      window.localStorage.setItem('loanspad', JSON.stringify(obj));
      window.location.href = WEB_URL;
    } catch (e) {
      if (e.response) {
        const msg = e.response.data.message;
        if (msg === 'User is not verified!') {
          setAdditionalLink({
            text: 'Resend Email?',
            onClick: () => sendVerificationEmail(email)
          });
        }

        setMessage({ label: e.response.data.message, type: 'error' });
        setIsAlertVisible(true);
      }
    }
    setIsProcessing(false);
  };

  const alertDescription = (message, additionalLink) => {
    return (
      <div>
        <span>{message.label}</span>&nbsp;
        {additionalLink ? (
          <a href="javascript:;" onClick={additionalLink.onClick}>
            {additionalLink.text}
          </a>
        ) : null}
      </div>
    );
  };

  return (
    <div className="login-page">
      <Row>
        <Col span={12} flex style={{ flexDirection: 'column', background: '#f3f3f3' }}>
          <div style={{ width: '50%', margin: 'auto', marginTop: 250 }}>
            {/* <img src="/loanspad-logo.svg" width={150} alt="Loanspad" className='mx-auto mb-5' /> */}

            <Card style={{ borderRadius: '6px' }}>
              <Typography.Paragraph>Login here</Typography.Paragraph>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                form={form}>
                {isAlertVisible && (
                  <Alert
                    description={alertDescription(message, additionalLink)}
                    type={message.type}
                    showIcon
                    style={{ marginBottom: 20 }}
                  />
                )}
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Username!'
                    }
                  ]}>
                  <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Password!'
                    }
                  ]}>
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <a className="login-form-forgot" href="/auth/forgot">
                    Forgot password
                  </a>
                </Form.Item>

                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isProcessing}>
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
        <Col span={12}>
          <div className="">
            <Typography.Title level={3}>{/* Welcome to Loanspad! */}</Typography.Title>
            <Typography.Title level={1}>
              {/* Your journey to Fast Loan Approval starts here! */}
            </Typography.Title>
          </div>
          <div className="auth-side-mask"> </div>
          <div className="auth-side" />
        </Col>
      </Row>
    </div>
  );
}

export default LoginForm;
