import { Timeline, Typography } from 'antd';
import moment from 'moment';

function TimeLine() {
  return (
    <div>
      <Typography.Title level={5}>Time line</Typography.Title>
      <div className="mt-5">
        <Timeline>
          <Timeline.Item>
            Create a services
            <p className="text-gray-500 text-xs">{moment().format('DD/MM/YYYY hh:mm a')} by Anil</p>
          </Timeline.Item>
          <Timeline.Item>
            Solve initial network problems
            <p className="text-gray-500 text-xs">{moment().format('DD/MM/YYYY hh:mm a')} by Anil</p>
          </Timeline.Item>
          <Timeline.Item>
            Technical testing
            <p className="text-gray-500 text-xs">{moment().format('DD/MM/YYYY hh:mm a')} by Anil</p>
          </Timeline.Item>
          <Timeline.Item>
            Network problems being solved
            <p className="text-gray-500 text-xs">{moment().format('DD/MM/YYYY hh:mm a')} by Anil</p>
          </Timeline.Item>
        </Timeline>
      </div>
    </div>
  );
}

export default TimeLine;
