import { useEffect, useState } from 'react';
import { Col, Row, Typography, Card, Alert } from 'antd';
import axios from 'axios';
import { VerifyTokenForm } from './VerifyTokenForm';
import { useSearchParams } from 'react-router-dom';
import { API_HOST } from '../../helpers/constants';

function Verify() {
  const [, setEmail] = useState(null);
  const [type, setType] = useState(null);
  const [msg, setMsg] = useState(null);

  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const token = searchParams.get('token');

  const [em, tok] = state.split('.');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post(`${API_HOST}/auth/verify`, { token });
        console.log(JSON.stringify(data, null, 4));
        setEmail(data.email);
        setType(data.type);
      } catch (e) {
        setMsg(e.response.data.message);
      }
    };
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <div className="login-page">
      <Row>
        <Col span={12} flex style={{ flexDirection: 'column', background: '#f3f3f3' }}>
          <div style={{ width: '50%', margin: 'auto', marginTop: 250 }}>
            <Typography.Title level={1} style={{ textAlign: 'center' }}>
              {/* <img src="/logo.svg" height={40} alt="CareKernel" /> */}
            </Typography.Title>
            <Card style={{ borderRadius: '6px' }}>
              {msg ? (
                <Alert description={msg} type="error" />
              ) : (
                <VerifyTokenForm token={atob(tok)} msg={msg} email={atob(em)} type={type} />
              )}
            </Card>
          </div>
        </Col>
        <Col span={12}>
          <div className="auth-side-text">
            <Typography.Title level={3}>We are glad to see you again!</Typography.Title>
            <Typography.Title level={1}>
              Get ready to collaborate using the Most Advanced NDIS Provider Management Software
            </Typography.Title>
          </div>
          <div className="auth-side-mask"> </div>
          <div className="auth-side"></div>
        </Col>
      </Row>
    </div>
  );
}

export default Verify;
