/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Popconfirm,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Tooltip,
  Typography,
  Form,
  Drawer,
  Input,
  Select,
  Skeleton,
  Empty,
  InputNumber
} from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import {
  capitalize,
  currency,
  getApplicantFullName,
  openNotification,
  splitCamelCase
} from '../../helpers';
import {
  API_HOST,
  assetTypes,
  liabilityTypes,
  MOTOR_VEHICLE,
  NON_APPLICANT,
  PROPERTY,
  PROPERTY_USES,
  SAVING_ACCOUNT,
  TERM_DEPOSIT,
  CREDIT_CARD,
  STORE_CARD,
  PERSONAL_LOAN,
  CAR_LOAN,
  HOME_LOAN,
  HOME_CONTENTS,
  TOOLS_EQUIPMENT
} from '../../helpers/constants';

import { Frequency } from '../../types';
import NetPosition from './widgets/NetPosition';
import { fetcher } from '../../utils';

function AssetsLiabilities({ showNetPosition = true }) {
  const [form] = Form.useForm();
  const [isAssetLiabilityDrawerVisible, setIsAssetLiabilityDrawerVisible] = useState(false);
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [type, setType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [assetOrLiability, setAssetOrLiability] = useState(null);
  const [object, setObject] = useState(null);
  const [mode, setMode] = useState('add');

  const url = `${API_HOST}/applications/${applicationId}`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      // const defaultOwnership = responseData.applicants.map((a) => {
      //   return {
      //     percentage: 0,
      //     applicant: a._id
      //   };
      // });
      // form.setFieldsValue(defaultOwnership)
    }
  }, [responseData]);

  // eslint-disable-next-line consistent-return
  const onSubmit = async (values) => {
    const subUrl = assetOrLiability.toLowerCase() === 'asset' ? 'assets' : 'liabilities';

    let durl = `${url}/${subUrl}`;
    let method = 'post';
    if (mode === 'edit') {
      durl = `${durl}/${object._id}`;
      method = 'put';
    }

    const { ownership } = values;
    if (!ownership || !ownership.length) {
      openNotification('Ownership Missing', 'You have not specified the ownerships', true);
      return false;
    }

    const sumPercentages = ownership.reduce((a, o) => a + +o.percentage, 0);
    if (sumPercentages !== 100) {
      openNotification('Incorrect Ownership ', 'Sum of all ownerships should be 100%', true);
      return false;
    }

    try {
      setIsLoading(true);

      await axios[method](durl, values);
      openNotification(`Saved ${assetOrLiability}`, `Saved ${assetOrLiability}`);
      form.resetFields();
      setIsAssetLiabilityDrawerVisible(false);
      mutate(url);
    } catch (e) {
      openNotification('Saving Failed', `Cound not Save ${assetOrLiability}`, true);
      console.log(e.message);
    }
    setIsLoading(false);
  };

  const onEditClick = (object, assetOrLiability) => {
    // console.log({ assetOrLiability })
    setAssetOrLiability(assetOrLiability);
    setObject(object);
    setType(object.type);
    setMode('edit');
    form.setFieldsValue(object);
    setIsAssetLiabilityDrawerVisible(true);
  };

  const onDelete = async (id, assetOrLiability) => {
    const subUrl = assetOrLiability.toLowerCase() === 'asset' ? 'assets' : 'liabilities';
    try {
      setIsLoading(true);
      await axios.delete(`${url}/${subUrl}/${id}`);
      openNotification(`Deleted ${assetOrLiability}`, `Successfully deleted ${assetOrLiability}`);
      setIsAssetLiabilityDrawerVisible(false);
      mutate(url);
    } catch (e) {
      openNotification('Deletion Failed', `Cound not delete ${assetOrLiability}`, true);
      console.log(e.message);
    }
    setIsLoading(false);
  };

  const showDrawer = (assetOrLiability, isVisible) => {
    form.resetFields();
    setAssetOrLiability(assetOrLiability);
    setIsAssetLiabilityDrawerVisible(isVisible);
    if (!isVisible) {
      setType(null);
    }
  };

  const renderAssetLiability = (assetOrLiability, object) => {
    const { type, outstandingAmount, value, ownership, ...rest } = object;
    return (
      <Card
        type="inner"
        title={type}
        bodyStyle={{ padding: 10 }}
        extra={
          <Space>
            <Tooltip title="Edit">
              <a href="#" onClick={() => onEditClick(object, assetOrLiability)}>
                <EditOutlined style={{ fontSize: 16 }} />
              </a>
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                placement="top"
                title={`Are you sure to delete ${assetOrLiability}`}
                onConfirm={() => onDelete(object._id, assetOrLiability)}
                okText="Yes"
                cancelText="No">
                <DeleteOutlined style={{ fontSize: 16 }} className="text-red-700" />
              </Popconfirm>
            </Tooltip>
          </Space>
        }
        className="border-blue-300 shadow-md hover:shadow-lg">
        <Row gutter={16}>
          <Col span={24}>
            <p>
              <Typography.Text strong style={{ display: 'block' }}>
                {!value ? 'Outstanding Amount' : 'Value'}
              </Typography.Text>
              <Typography.Text className="text-xl " strong>
                {!value ? currency(outstandingAmount) : currency(value)}
              </Typography.Text>
            </p>
          </Col>
          <Col span={12} />
        </Row>
        <Divider className="my-1" />
        {/* <Typography.Text strong style={{ display: 'block', marginBottom: 10 }}>Details</Typography.Text> */}
        <Row gutter={8}>
          {Object.keys(rest).map((dk, i) => (
            <Col span={12} key={`${dk}-${i}`} className="mb-3">
              <p>
                <Typography.Text strong style={{ display: 'block' }}>
                  {splitCamelCase(capitalize(dk))}
                </Typography.Text>
                <Typography.Text>{rest[dk]}</Typography.Text>
              </p>
            </Col>
          ))}
        </Row>
        <Divider className="my-1" />
        <Typography.Text strong style={{ display: 'block', marginBottom: 10 }}>
          Ownerships
        </Typography.Text>
        {ownership?.map((ow, i) => (
          <Row gutter={24} key={i} className="mb-0">
            <Col span={18}>
              <p>
                {ow.applicant !== NON_APPLICANT
                  ? application.applicants.find((a) => a._id === ow.applicant)?.userInfo.firstName
                  : NON_APPLICANT}
              </p>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              {' '}
              {ow.percentage}%
            </Col>
          </Row>
        ))}
      </Card>
    );
  };

  const getMainCardTitle = (title, object, singular) => {
    const v = singular === 'Liability' ? 'outstandingAmount' : 'value';
    return (
      <Row gutter={24}>
        <Col span={6}>
          {title}
          <Button
            icon={<PlusOutlined />}
            className="ml-10"
            onClick={() => showDrawer(singular, true)}>
            Add {singular}
          </Button>
        </Col>
        <Col span={8}>
          <Typography.Text className=" text-xl" strong>
            {currency(application[object].reduce((a, o) => a + +o[v], 0))}
          </Typography.Text>
        </Col>
      </Row>
    );
  };

  const renderFields = () => {
    if ([SAVING_ACCOUNT, TERM_DEPOSIT].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Institution" rules={[{ required: true }]} name="institutionName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Account Name" rules={[{ required: true }]} name="accountName">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="BSB" rules={[{ required: true }]} name="bsb">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Account Number" name="accountNumber">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Value" rules={[{ required: true }]} name="value">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  className="w-36"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([MOTOR_VEHICLE].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Make" rules={[{ required: true }]} name="make">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Model" rules={[{ required: true }]} name="model">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Year" rules={[{ required: true }]} name="year">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Rego" name="rego">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Estimate Basis" rules={[{ required: true }]} name="estimateBasis">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Value" rules={[{ required: true }]} name="value">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([PROPERTY].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Address" rules={[{ required: true }]} name="propertyAddress">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Property Use" rules={[{ required: true }]} name="propertyUse">
                <Select>
                  {PROPERTY_USES.map((pu) => (
                    <Select.Option key={pu}>{pu}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Value" rules={[{ required: true }]} name="value">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([CREDIT_CARD, STORE_CARD].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Lender" rules={[{ required: true }]} name="lender">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Payout" rules={[{ required: true }]} name="installmentAmount">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="installmentFrequency"
                label="Frequency"
                rules={[{ required: true, message: 'Frequency is required' }]}>
                <Select>
                  {Object.keys(Frequency).map((key) => (
                    <Select.Option value={Frequency[key]} key={Frequency[key]}>
                      {Frequency[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Credit Limit" rules={[{ required: true }]} name="creditLimit">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Outstanding Amount"
                rules={[{ required: true }]}
                name="outstandingAmount">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([PERSONAL_LOAN, CAR_LOAN, HOME_LOAN].includes(type)) {
      return (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Lender" rules={[{ required: true }]} name="lender">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Outstanding Amount"
                rules={[{ required: true }]}
                name="outstandingAmount">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Installment Amount"
                rules={[{ required: true }]}
                name="installmentAmount">
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Installment Frequency"
                rules={[{ required: true }]}
                name="installmentFrequency">
                <Select style={{ width: '100%' }}>
                  {Object.keys(Frequency).map((k) => (
                    <Select.Option key={k} value={k}>
                      {Frequency[k]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if ([HOME_CONTENTS, TOOLS_EQUIPMENT].includes(type)) {
      return (
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Value" rules={[{ required: true }]} name="value">
              <InputNumber
                className="w-1/2"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const getTypes = () => {
    if (assetOrLiability?.toLowerCase() === 'asset') {
      return assetTypes.map((a) => (
        <Select.Option key={a} value={a}>
          {a}
        </Select.Option>
      ));
    }
    return liabilityTypes.map((a) => (
      <Select.Option key={a} value={a}>
        {a}
      </Select.Option>
    ));
  };

  return application ? (
    <div>
      {showNetPosition && <NetPosition application={application} />}

      <div className="mt-2 px-2">
        <Card
          title={getMainCardTitle('Assets', 'assets', 'Asset')}
          style={{ marginBottom: 20 }}
          bodyStyle={{ padding: 10 }}>
          <Row gutter={6}>
            {application.assets.map((asset) => (
              <Col span={4} key={asset._id}>
                {renderAssetLiability('asset', asset)}
              </Col>
            ))}
            <Col span={24}>{application.assets.length < 1 && <Empty />}</Col>
          </Row>
        </Card>
        <Card
          title={getMainCardTitle('Liabilities', 'liabilities', 'Liability')}
          style={{ marginBottom: 20 }}
          bodyStyle={{ padding: 10 }}>
          <Row gutter={6}>
            {application.liabilities.map((liability) => (
              <Col span={4} key={liability._id}>
                {renderAssetLiability('liability', liability)}
              </Col>
            ))}
            <Col span={24}>{application.liabilities.length < 1 && <Empty />}</Col>
          </Row>
        </Card>
      </div>
      <Drawer
        title={assetOrLiability}
        open={isAssetLiabilityDrawerVisible}
        width={600}
        onClose={() => showDrawer(assetOrLiability, false)}>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Type" rules={[{ required: true }]} name="type">
                <Select onChange={(v) => setType(v)}>{getTypes()}</Select>
              </Form.Item>
            </Col>
          </Row>
          {renderFields()}

          <br />
          <Typography.Title level={4}>Ownership</Typography.Title>
          <Divider className="m-2 mb-4" />
          <Form.List name="ownership">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={12} key={key}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'applicant']}
                        rules={[{ required: true, message: 'Missing applicant' }]}>
                        <Select>
                          {application?.applicants?.map((a) => (
                            <Select.Option key={a._id}>
                              {getApplicantFullName(a.userInfo)}
                            </Select.Option>
                          ))}
                          <Select.Option key={NON_APPLICANT}>{NON_APPLICANT}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'percentage']}
                          rules={[{ required: true, message: 'Missing percentage' }]}>
                          <InputNumber
                            className="w-full"
                            defaultValue={100}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace('%', '')}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Owner
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Form>
      </Drawer>
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default AssetsLiabilities;
