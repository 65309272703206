import { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Alert, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { API_HOST } from '../../helpers/constants';

export default function Forgot() {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onFinish = async (values) => {
    const { email } = values;
    setIsProcessing(true);
    try {
      const { data } = await axios.post(`${API_HOST}/auth/forgot`, { email });
      const { token } = data;
      const state = `${btoa(email)}.${btoa(token)}`;

      // console.log(data);
      // console.log(state);

      navigate(`/auth/verify?state=${state}`, { replace: true });
    } catch (e) {
      if (e.response) {
        setMessage(e.response.data.message);
        setIsAlertVisible(true);
      }
    }
    setIsProcessing(false);
  };

  return (
    <div>
      <Typography.Title level={4}>Forgot password?</Typography.Title>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}>
        {isAlertVisible && (
          <Alert description={message} type="error" showIcon style={{ marginBottom: 20 }} />
        )}
        <p>Enter your email address registered with us and press submit.</p>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email address!'
            }
          ]}>
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <p>Further instrucitions will be sent on the email if you are registered with us.</p>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={isProcessing}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
