/* eslint-disable react/prop-types */
import { Col, Row, Typography, Card } from 'antd';

export default function UnsecureLayout({ children }) {
  return (
    <div className="login-page">
      <Row>
        <Col span={12} flex style={{ flexDirection: 'column', background: '#f3f3f3' }}>
          <div style={{ width: '50%', margin: 'auto', marginTop: 250 }}>
            <Typography.Title level={1} style={{ textAlign: 'center' }}>
              <img src="/logo.svg" height={40} alt="CareKernel" />
            </Typography.Title>
            <Card style={{ borderRadius: '6px' }}>{children}</Card>
          </div>
        </Col>
        <Col span={12}>
          <div className="auth-side-text">
            <Typography.Title level={3}>We are glad to see you again!</Typography.Title>
            <Typography.Title level={1}>
              Get ready to collaborate using the Most Advanced NDIS Provider Management Software
            </Typography.Title>
          </div>
          <div className="auth-side-mask"> </div>
          <div className="auth-side" />
        </Col>
      </Row>
    </div>
  );
}
