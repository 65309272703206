/* eslint-disable react/prop-types */
import { useState } from 'react';
import {
  Layout,
  Menu,
  Row,
  Col,
  Input,
  Dropdown,
  Avatar,
  Button,
  Space,
  Modal,
  List,
  Typography,
  Skeleton
} from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  UserOutlined,
  SearchOutlined,
  BellOutlined,
  UsergroupAddOutlined,
  DatabaseOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Link, Navigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { API_HOST } from '../helpers/constants';
import { clearStorageAndRedirect, fetcher, headers, isAdmin } from '../utils';
import axios from 'axios';

const { Header, Content, Footer, Sider } = Layout;

export function SecureLayout(props) {
  const location = useLocation();
  const { loggedInUser, children } = props;
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [searchTerm, setSearchTerm] = useState();

  const showSearchModal = () => setIsSearchModalOpen(true);
  const hideSearchModal = () => setIsSearchModalOpen(false);

  const [collapsed, setCollapsed] = useState(false);
  let selectedKey;
  if (location.pathname.startsWith('/leads')) {
    selectedKey = 'leads';
  } else if (location.pathname.startsWith('/accounts')) {
    selectedKey = 'accounts';
  } else if (location.pathname.startsWith('/applications')) {
    selectedKey = 'applications';
  } else if (location.pathname.startsWith('/users')) {
    selectedKey = 'users';
  }

  useSWR(`${API_HOST}/users/me`, fetcher);

  if (!loggedInUser) {
    return <Navigate to="/auth/login" />;
  }

  const onLogout = () => {
    clearStorageAndRedirect();
  };

  const items = [
    { label: 'Profile', key: 'profile' }, // remember to pass the key prop
    { label: <Link onClick={() => onLogout()}>Logout</Link>, key: 'logout' }
  ];

  const onSearch = async (value) => {
    if (!value) return;

    setIsProcessing(true);
    try {
      const { data: responseData } = await axios.get(
        `${API_HOST}/leads?filters[term]=${value}&limit=100`,
        {
          headers
        }
      );
      setIsProcessing(false);

      const results = responseData.data.map((lead) => {
        return {
          label: (
            <a href={`/leads/${lead._id}`}>
              {[
                lead?.application.applicants[0].userInfo.firstName,
                lead?.application.applicants[0].userInfo.lastName
              ].join(' ')}
            </a>
          )
        };
      });

      setSearchResults(results);
    } catch (error) {
      setIsProcessing(false);
      console.log(error);
    }
  };

  let leftNavItems = [
    {
      label: <Link to="/leads">Leads</Link>,
      key: 'leads',
      icon: <PieChartOutlined />
    },
    {
      label: <Link to="/accounts">Accounts</Link>,
      key: 'accounts',
      icon: <DesktopOutlined />
    },
    {
      label: <Link to="/applications">Applications</Link>,
      key: 'applications',
      icon: <DesktopOutlined />
    },
    {
      label: 'Boards',
      key: 'boards',
      icon: <DatabaseOutlined />,
      children: [
        {
          label: <Link to="/applications-board">Application status</Link>,
          key: 'applications-board'
        },
        {
          label: <Link to="/leads-board">Leads status</Link>,
          key: 'leads-board'
        }
      ]
    }
  ];

  if (isAdmin(loggedInUser)) {
    const otherOptions = [
      {
        label: <Link to="/users">Users</Link>,
        key: 'users',
        icon: <UsergroupAddOutlined />
      },
      {
        label: 'Content',
        key: 'content',
        icon: <EditOutlined />,
        children: [
          {
            label: <Link to="/blogs">Blogs</Link>,
            key: 'blogs'
          },
          {
            label: <Link to="/blog-posts">Blog Posts</Link>,
            key: 'blog-posts'
          },
          {
            label: <Link to="/blog-authors">Blog Authors</Link>,
            key: 'blog-authors'
          },
          {
            label: <Link to="/pages">Pages</Link>,
            key: 'pages'
          },
          {
            label: <Link to="/blocks">Blocks</Link>,
            key: 'blocks'
          },
          {
            label: <Link to="/faqs">FAQs</Link>,
            key: 'faqs'
          }
        ]
      },
      {
        label: 'Data Sources',
        key: 'data-sources',
        icon: <DatabaseOutlined />,
        children: [
          {
            label: <Link to="/blog-authors">Blog Authors</Link>,
            key: 'blog-authors'
          },
          {
            label: <Link to="/lenders">Lenders</Link>,
            key: 'lenders'
          },
          {
            label: <Link to="/lead-reasons">Lead Reasons</Link>,
            key: 'lead-reasons'
          }
        ]
      }
    ];

    leftNavItems = [...leftNavItems, ...otherOptions];
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      showSearchModal();
    }
  };

  const renderSearchItem = (item) => (
    <List.Item>
      <Typography.Text mark>[LEAD]</Typography.Text> {item.label}
    </List.Item>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo">
          <a href="/">
            <img
              src="/logo.svg"
              height={40}
              width={130}
              style={{ margin: '10px 0px', display: 'block' }}
              alt="Loanspad"
            />
          </a>
        </div>
        <Menu theme="dark" selectedKeys={selectedKey} mode="inline" items={leftNavItems} />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row gutter={0}>
            <Col span={7} />
            <Col span={10}>
              <Input
                allowClear
                size="large"
                placeholder="Search... (Shift+f)"
                prefix={<SearchOutlined />}
                className="topbar__search-input"
                onClick={showSearchModal}
                onKeyDown={onKeyDown}
              />
            </Col>
            <Col span={7} style={{ textAlign: 'right', paddingRight: 30 }}>
              <BellOutlined style={{ fontSize: 18, marginRight: 20, marginTop: 3 }} />
              <Dropdown menu={{ items }} placement="topRight" trigger="click">
                <Button type="text" className="h-16">
                  <Space>
                    <Avatar icon={<UserOutlined />} className="bg-green-400 cursor-pointer" />
                    <div className="flex flex-col text-left h-full">
                      <span className="m-0">
                        {[loggedInUser?.firstName, loggedInUser?.lastName].join(' ')}
                      </span>
                      <span className="text-[10px] font-semibold text-gray-400">
                        {loggedInUser?.role?.toUpperCase()}
                      </span>
                    </div>
                  </Space>
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: '10px 16px' }}>{children}</Content>
        <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Loanspad</Footer>
      </Layout>
      <Modal
        title="Search"
        open={isSearchModalOpen}
        onOk={hideSearchModal}
        height={500}
        closable
        style={{ top: 50 }}
        destroyOnClose
        onCancel={hideSearchModal}>
        <Input.Search
          allowClear
          size="large"
          placeholder="Search..."
          onSearch={onSearch}
          style={{ marginBottom: 16 }}
          ref={(el) => {
            setTimeout(() => el?.focus(), 0);
          }}
          defaultValue={searchTerm}
        />
        <div className="h-80 overflow-y-auto">
          {isProcessing && (
            <div className="flex-col h-full w-full justify-center align-middle items-center">
              <Skeleton active paragraph={false} className="my-5" />
              <Skeleton active paragraph={false} className="my-5" />
              <Skeleton active paragraph={false} className="my-5" />
              <Skeleton active paragraph={false} className="my-5" />
              <Skeleton active paragraph={false} className="my-5" />
              <Skeleton active paragraph={false} className="my-5" />
              <Skeleton active paragraph={false} className="my-5" />
            </div>
          )}
          <List
            size="large"
            dataSource={searchResults}
            renderItem={(item) => renderSearchItem(item)}
          />
        </div>
      </Modal>
    </Layout>
  );
}
