import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { openNotification } from '../../helpers';
import { useLender } from '../../hooks/useLender';
import { Actions } from '../../types';
import { createLender, updateLender } from '../../services/lenders.http';

function LenderForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );

  const navigate = useNavigate();
  const { lenderId } = useParams();

  const { lender } = useLender(lenderId);

  useEffect(() => {
    if (lender) {
      form.setFieldsValue(lender);
    }
  }, [lender]);

  const onSubmit = async (values) => {
    try {
      const { data } =
        mode === Actions.add ? await createLender(values) : await updateLender(lenderId, values);
      navigate(`/lead-reasons/${data._id}/edit`);
      setMode(Actions.edit);
      openNotification('Success', `Lender saved successfully!`);
    } catch (error) {
      openNotification('Error', 'Lender updation failed!', true);
      console.log(error);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        className="my-4"
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/lenders">Lenders</Link> },
          { title: 'Lender' }
        ]}
      />
      <Typography.Title level={4}>Lender</Typography.Title>
      <div className="bg-white p-5">
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Short Name" name="shortName">
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Code" name="code" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Is Partner?" name="isPartner" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Is Active?" name="isActive" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row gutter={24}>
            <Col span={12}>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default LenderForm;
