export const State = {
  nsw: 'NSW',
  qld: 'QLD',
  wa: 'WA',
  act: 'ACT',
  sa: 'SA',
  tas: 'TAS',
  vic: 'VIC'
};

export const Domain = {
  Home: 'Home',
  Car: 'Car',
  Personal: 'Personal'
};

export const Title = {
  mr: 'Mr.',
  ms: 'Ms.',
  mrs: 'Mrs.',
  dr: 'Dr.',
  prof: 'Prof.',
  other: 'Other'
};

export const Gender = {
  male: 'Male',
  female: 'Female',
  other: 'Other'
};

export const Frequency = {
  Weekly: 'Weekly',
  Fortnightly: 'Fortnightly',
  Monthly: 'Monthly',
  Yearly: 'Yearly'
};

export const INCOME_TYPE_RENTAL = 'Rental';
export const INCOME_TYPE_INVESTMENT = 'Investment Income';
export const INCOME_TYPE_SALARY_SACRIFICE = 'Salary Sacrifice';
export const INCOME_TYPE_CENTRELINK = 'Centrelink';
export const INCOME_TYPE_FOREIGN = 'Foreign';
export const INCOME_TYPE_CHILD_MAINTENANCE = 'Child Maintenance';
export const INCOME_TYPE_FAMILY_TAX_BENEFIT = 'Family Tax Benefit';

export const INCOME_TYPES = [
  INCOME_TYPE_RENTAL,
  INCOME_TYPE_INVESTMENT,
  INCOME_TYPE_SALARY_SACRIFICE,
  INCOME_TYPE_CENTRELINK,
  INCOME_TYPE_FOREIGN,
  INCOME_TYPE_CHILD_MAINTENANCE,
  INCOME_TYPE_FAMILY_TAX_BENEFIT
];

export const Actions = {
  edit: 'edit',
  add: 'add',
  view: 'view'
};

export const LEAD_APPLICATION_TYPE_FINANCE = 'Finance';
export const LEAD_APPLICATION_TYPE_REFINANCE = 'Refinance';

export const ResidencyStatus = {
  'Australian Citizen': 'Australian Citizen',
  'Permanently in Australia': 'Permanently in Australia',
  'Temporarily in Australia': 'Temporarily in Australia',
  'Non Resident': 'Non Resident'
};

export const RelationshipStatus = {
  'De Facto': 'De Facto',
  Divorced: 'Divorced',
  Married: 'Married',
  Separated: 'Separated',
  Single: 'Single',
  Unknown: 'Unknown',
  Widowed: 'Widowed'
};

export const IdentificationTypes = {
  Passport: 'Passport',
  'Driver Licence': 'Driver Licence'
};

export const LivingSituations = {
  ownHomeWithMortgage: 'I Own Home with Mortgage',
  ownHomeWithoutMortgage: 'I Own Home without Mortgage',
  renting: 'Renting',
  livingWithParents: 'Living with Parents'
};

export const DocumentPurposes = {
  bankStatement3mths: 'Bank Statement (3 months)',
  bankStatement6mths: 'Bank Statement (6 months)',
  driverLicenceFront: 'Driver Licence Front',
  driverLicenceBack: 'Driver Licence Back',
  medicare: 'Medicare'
};

export const PROPERTY_TYPE_FULLY_DETATCHED_HOME = 'Fully detatched home';
export const PROPERTY_TYPE_FULLY_TOWN_HOUSE = 'Town House';
export const PROPERTY_TYPE_FULLY_APARTMENT = 'Apartment';

export const PROPERTY_TYPES = {
  PROPERTY_TYPE_FULLY_DETATCHED_HOME,
  PROPERTY_TYPE_FULLY_TOWN_HOUSE,
  PROPERTY_TYPE_FULLY_APARTMENT
};

export const PROPERTY_USAGE_OWNER_OCCUPIED = 'Owner Occupied';
export const PROPERTY_USAGE_INVESTMENT = 'Investment';

export const PROPERTY_USAGES = [PROPERTY_USAGE_OWNER_OCCUPIED, PROPERTY_USAGE_INVESTMENT];

// export const EmploymentBasis = {
//   "Casual": "Casual",
//   "Commission Only": "Commission Only",
//   "Contract": "Contract",
//   "Full Time": "Full Time",
//   "Part Time": "Part Time",
//   "Seasonal": "Seasonal",
//   "Temporary": "Temporary",
// }

export const EmploymentType = {
  fullTime: 'Full Time',
  partTime: 'Part Time',
  casual: 'Casual',
  unemployed: 'Unemployed'
};

export const BUYING_SITUATION_JUST_EXPOLORING = 'Just Exploring';
export const BUYING_SITUATION_BUYING_IN_6_MTHS = 'Buying in next 6 months';
export const BUYING_SITUATION_ACTIVELY_LOOKING = 'Actively Looking';
export const BUYING_SITUATION_EXCHANGED_CONTRACTS = 'Exchanged contracts';

export const BUYING_SITUATIONS = [
  BUYING_SITUATION_JUST_EXPOLORING,
  BUYING_SITUATION_BUYING_IN_6_MTHS,
  BUYING_SITUATION_ACTIVELY_LOOKING,
  BUYING_SITUATION_EXCHANGED_CONTRACTS
];

export const CAR_BUYING_SITUATION_ASAP = 'ASAP';
export const CAR_BUYING_SITUATION_WITHIN_A_MONTH = 'Within a month';
export const CAR_BUYING_SITUATION_NO_HURRY = 'No Hurry';

export const CAR_BUYING_SITUATIONS = [
  CAR_BUYING_SITUATION_ASAP,
  CAR_BUYING_SITUATION_WITHIN_A_MONTH,
  CAR_BUYING_SITUATION_NO_HURRY
];

export const LEAD_SOURCE_WEBSITE = 'Website';
export const LEAD_SOURCES = [LEAD_SOURCE_WEBSITE];

export const LEAD_CHANNEL_GOOGLE = 'Google';
export const LEAD_CHANNEL_FACEBOOK = 'Facebook';
export const LEAD_CHANNEL_TIKTOK = 'TikTok';
export const LEAD_CHANNEL_EMAIL = 'Email';
export const LEAD_CHANNELS = [
  LEAD_CHANNEL_GOOGLE,
  LEAD_CHANNEL_FACEBOOK,
  LEAD_CHANNEL_TIKTOK,
  LEAD_CHANNEL_EMAIL
];

export const LEAD_APPLICATION_TYPES = [
  LEAD_APPLICATION_TYPE_FINANCE,
  LEAD_APPLICATION_TYPE_REFINANCE
];

export const LEAD_CREATION_SOURCE_FUNNEL = 'Funnel';
export const LEAD_CREATION_SOURCE_REFERRAL_PORTAL = 'Referral Portal';
export const LEAD_CREATION_SOURCE_CRM = 'CRM';

export const LEAD_CREATION_SOURCES = [
  LEAD_CREATION_SOURCE_FUNNEL,
  LEAD_CREATION_SOURCE_REFERRAL_PORTAL,
  LEAD_CREATION_SOURCE_CRM
];

export const BUILD_TYPE_NEWLY_BUILT = 'Newly build/Off the plan';
export const BUILD_TYPE_ESTABLESHED_HOME = 'Established Home';
export const BUILD_TYPE_VACANT_LAND = 'Vacant Land';
export const BUILD_TYPES = [
  BUILD_TYPE_NEWLY_BUILT,
  BUILD_TYPE_ESTABLESHED_HOME,
  BUILD_TYPE_VACANT_LAND
];

export const DEPICTED_CREDIT_HISTORY_EXCELLENT = 'Excellent';
export const DEPICTED_CREDIT_HISTORY_AVERAGE = 'Average';
export const DEPICTED_CREDIT_HISTORY_FAIR = 'Fair';
export const DEPICTED_CREDIT_HISTORY_DONT_KNOW = "Don't know";
export const DEPICTED_CREDIT_HISTORIES = [
  DEPICTED_CREDIT_HISTORY_EXCELLENT,
  DEPICTED_CREDIT_HISTORY_AVERAGE,
  DEPICTED_CREDIT_HISTORY_FAIR,
  DEPICTED_CREDIT_HISTORY_DONT_KNOW
];

export const EMPLOYEMENT_STATUS_EMPLOYEE = 'Employee';
export const EMPLOYEMENT_STATUS_SELF_EMPLOYED = 'Self-Employed';
export const EMPLOYEMENT_STATUS_OTHER = 'Other';
export const EMPLOYEMENT_STATUSES = [
  EMPLOYEMENT_STATUS_EMPLOYEE,
  EMPLOYEMENT_STATUS_SELF_EMPLOYED,
  EMPLOYEMENT_STATUS_OTHER
];

export const REFINANCE_REASON_DECREASE_REPAYMENTS = 'Decrease Repayments';
export const REFINANCE_REASON_PAYOFF_LOAN_FASTER = 'Pay off loan faster';
export const REFINANCE_REASON_CONSOLIDATE_DEBT = 'Consolidate debt/cash out';
export const REFINANCE_REASON_BUILD_ON_MY_LAND = 'Build on my land or property';
export const REFINANCE_REASONS = [
  REFINANCE_REASON_DECREASE_REPAYMENTS,
  REFINANCE_REASON_PAYOFF_LOAN_FASTER,
  REFINANCE_REASON_CONSOLIDATE_DEBT,
  REFINANCE_REASON_BUILD_ON_MY_LAND
];

export const TASK_TYPE_CALL = 'Call';
export const TASK_TYPE_EMAIL = 'Email';
export const TASK_TYPE_MEET = 'Meet';
export const TASK_TYPES = [TASK_TYPE_CALL, TASK_TYPE_EMAIL, TASK_TYPE_MEET];

export const TASK_STATUS_TODO = 'To Do';
export const TASK_STATUS_IN_PROGRESS = 'In Progress';
export const TASK_STATUS_DONE = 'Done';
export const TASK_STATUSES = [TASK_STATUS_TODO, TASK_STATUS_IN_PROGRESS, TASK_STATUS_DONE];

export const VERB_POST = 'post';
export const VERB_PUT = 'put';

export const USAGE_PERSONAL = 'Personal';
export const USAGE_COMMERCIAL = 'Commercial';
export const USAGES = [USAGE_PERSONAL, USAGE_COMMERCIAL];

export const LOAN_PURPOSE_BUY_A_NEW_PROPERTY_FOR_RESIDENCE = 'Buy a new property for residence';
export const LOAN_PURPOSE_BUY_A_NEW_PROPERTY_FOR_INVESTMENT = 'Buy a new property for investment';
export const LOAN_PURPOSE_REFINANCE_A_HOME_LOAN = 'Refinance a home loan';
export const LOAN_PURPOSE_VEHICLE = 'Vehicle';
export const LOAN_PURPOSE_LEISURE = 'Leisure';
export const LOAN_PURPOSE_MEDICAL = 'Medical';
export const LOAN_PURPOSE_DEBT_CONSOLIDATION = 'Debt consolidation';
export const LOAN_PURPOSE_TRAVEL = 'Travel';
export const LOAN_PURPOSE_OTHER = 'Other';
export const LOAN_PURPOSES = [
  LOAN_PURPOSE_BUY_A_NEW_PROPERTY_FOR_RESIDENCE,
  LOAN_PURPOSE_BUY_A_NEW_PROPERTY_FOR_INVESTMENT,
  LOAN_PURPOSE_REFINANCE_A_HOME_LOAN,
  LOAN_PURPOSE_VEHICLE,
  LOAN_PURPOSE_LEISURE,
  LOAN_PURPOSE_MEDICAL,
  LOAN_PURPOSE_DEBT_CONSOLIDATION,
  LOAN_PURPOSE_TRAVEL,
  LOAN_PURPOSE_OTHER
];

export const LOAN_FEATURE_LOWEST_INTEREST_RATE = 'Lowest interest rate';
export const LOAN_FEATURE_BALLOON_ON_THE_LOAN = 'Balloon on the loan';
export const LOAN_FEATURE_NO_EARLY_EXIT_FEES = 'No early exit fees';
export const LOAN_FEATURE_ABILITY_ADDITIONAL_REPAYMENTS = 'Ability to make additional repayments';
export const LOAN_FEATURE_OFFSET_ACCOUNT = 'Offset account';
export const LOAN_FEATURE_REDRAW_FACILITY = 'Redraw facility';
export const LOAN_FEATURES = [
  LOAN_FEATURE_LOWEST_INTEREST_RATE,
  LOAN_FEATURE_BALLOON_ON_THE_LOAN,
  LOAN_FEATURE_NO_EARLY_EXIT_FEES,
  LOAN_FEATURE_ABILITY_ADDITIONAL_REPAYMENTS,
  LOAN_FEATURE_OFFSET_ACCOUNT,
  LOAN_FEATURE_REDRAW_FACILITY
];

export const LOAN_SUBMISSION_ESIGN_DONE_MSG = 'Compliance documents are e-signed';
// export const LOAN_SUBMISSION_ESIGN_DONE_DESC = "Loan"
export const LOAN_SUBMISSION_ESIGN_PENDING_MSG =
  'E-signature on compliance documents have not been performed';
export const LOAN_SUBMISSION_ESIGN_PENDING_DESC =
  'Compliance documents must be esigned to enable loan submission';

export const APPROVED = 'Approved';
export const WITHDRAWN = 'Withdrawn';
export const DECLINED = 'Declined';
export const LOAN_DECISIONS = [APPROVED, WITHDRAWN, DECLINED];

export const DECLINED_REASON_DOES_NOT_SERVICE = 'Does Not Service';
export const DECLINED_REASON_ADVERSE_CREDIT_HISTORY = 'Adverse Credit History';
export const DECLINED_REASON_POOR_BANK_ACCOUNT_CONDUCT = 'Poor Bank Account Conduct';
export const DECLINED_REASON_POOR_REPAYMENT_HISTORY = 'Poor Repayment History';
export const DECLINED_REASON_OUTSIDE_LENDER_GUIDELINES = 'Outside Lender Guidelines';
export const DECLINED_REASON_OUTSIDE_VEHICLE_GUIDELINES = 'Outside Vehicle Guidelines';
export const DECLINED_REASON_FINANCIALS_NOT_ACCEPTED = 'Financials Not Accepted';
export const DECLINED_REASON_NO_CAPACITY = 'No Capacity';
export const DECLINED_REASON_FRAUD = 'Fraud';
export const DECLINED_REASON_OTHER = 'Other';
export const DECLINED_REASONS = [
  DECLINED_REASON_POOR_REPAYMENT_HISTORY,
  DECLINED_REASON_ADVERSE_CREDIT_HISTORY,
  DECLINED_REASON_OUTSIDE_LENDER_GUIDELINES,
  DECLINED_REASON_OUTSIDE_VEHICLE_GUIDELINES,
  DECLINED_REASON_FINANCIALS_NOT_ACCEPTED,
  DECLINED_REASON_DOES_NOT_SERVICE,
  DECLINED_REASON_POOR_BANK_ACCOUNT_CONDUCT,
  DECLINED_REASON_NO_CAPACITY,
  DECLINED_REASON_FRAUD,
  DECLINED_REASON_OTHER
];

export const WITHDRAWN_REASON_ADVERSE_CREDIT_INFORMATION = 'Adverse Credit Information';
export const WITHDRAWN_REASON_BANK_ACCOUNT_CONDUCT = 'Bank Account Conduct';
export const WITHDRAWN_REASON_BANKRUPTCY = 'Bankruptcy';
export const WITHDRAWN_REASON_DOES_NOT_SERVICE = 'Does Not Service';
export const WITHDRAWN_REASON_DOESNT_MEET_ANY_LENDER_CRITERIA = "Doesn't Meet Any Lender Criteria";
export const WITHDRAWN_REASON_DO_NOT_CONTACT = 'Do Not Contact';
export const WITHDRAWN_REASON_DUPLICATE = 'Duplicate';
export const WITHDRAWN_REASON_FRAUD = 'Fraud';
export const WITHDRAWN_REASON_FUNDING_NOT_URGENT = 'Funding Not Urgent';
export const WITHDRAWN_REASON_NOT_THE_RIGHT_PRODUCT = 'Not the Right Product';
export const WITHDRAWN_REASON_WANTS_NOVATED_LEASE = 'Wants Novated Lease';
export const WITHDRAWN_REASON_OTHER = 'Other';

export const WITHDRAWN_REASONS = [
  WITHDRAWN_REASON_DUPLICATE,
  WITHDRAWN_REASON_DOES_NOT_SERVICE,
  WITHDRAWN_REASON_ADVERSE_CREDIT_INFORMATION,
  WITHDRAWN_REASON_FRAUD,
  WITHDRAWN_REASON_BANK_ACCOUNT_CONDUCT,
  WITHDRAWN_REASON_BANKRUPTCY,
  WITHDRAWN_REASON_WANTS_NOVATED_LEASE,
  WITHDRAWN_REASON_DO_NOT_CONTACT,
  WITHDRAWN_REASON_FUNDING_NOT_URGENT,
  WITHDRAWN_REASON_DOESNT_MEET_ANY_LENDER_CRITERIA,
  WITHDRAWN_REASON_NOT_THE_RIGHT_PRODUCT,
  WITHDRAWN_REASON_OTHER
];

export const FREQUENCY_MONTHLY = 'Monthly';
export const FREQUENCY_ANNUALLY = 'Annually';
