import {
  Alert,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Switch,
  Typography
} from 'antd';
import { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  API_HOST,
  STATUS_READY_FOR_SUBMISSION,
  STATUS_SUBMITTED_TO_LENDER
} from '../../../helpers/constants';
import useSWR from 'swr';
import { fetcher, headers } from '../../../utils';
import {
  LOAN_SUBMISSION_ESIGN_DONE_MSG,
  LOAN_SUBMISSION_ESIGN_PENDING_DESC,
  LOAN_SUBMISSION_ESIGN_PENDING_MSG
} from '../../../types';
import LoanDetailsRateFeesSection from './LoanDetailsRateFeesSection';
import axios from 'axios';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
import CpdPreviewModal from './CpdPreviewModal';
import { getFileAccessUrl, openNotification } from '../../../helpers';

const rowGutter = 80;

const getYesOrNo = (bool) => (bool ? 'Yes' : 'No');

function LoanSubmission() {
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application, mutate } = useSWR(applicationUrl, fetcher);
  const [form] = Form.useForm();
  const saveSubmissionEndpoint = `${applicationUrl}/save-submission`;
  const submitLoanDetailsEndpoint = `${applicationUrl}/submit-loan-details`;
  const cpdSendEndpoint = `${applicationUrl}/send-cpd`;
  const [isGeneratingAssessment, setIsGeneratingAssessment] = useState(false);
  const [isCpdPreviewModalOpen, setIsCpdPreviewModalOpen] = useState(false);
  const [cpdDocumentUrl, setCpdDocumentUrl] = useState(null);
  const [isSendingCpd, setIsSendingCpd] = useState(false);
  const [isSubmittingLoanDetails, setIsSubmittingLoanDetails] = useState(false);
  const [isEditingSubmission, setIsEditingSubmission] = useState(false);

  const onFinish = async (data) => {
    try {
      setIsGeneratingAssessment(true);
      await axios.put(saveSubmissionEndpoint, data, { headers });
      mutate();
      setIsGeneratingAssessment(false);
      setIsEditingSubmission(false);
      openNotification('Preliminary assessment generated successfully');
    } catch (error) {
      console.log(error);
      setIsGeneratingAssessment(false);
      openNotification(
        'Could not generate preliminary assessment',
        error.response?.data?.message,
        true
      );
    }
  };

  const handleCpdModalCancel = useCallback(() => {
    setIsCpdPreviewModalOpen(false);
  }, []);

  const onPreviewCpdClick = async () => {
    try {
      const res = await axios.get(getFileAccessUrl(cpd.key), { headers });
      setIsCpdPreviewModalOpen(true);
      setCpdDocumentUrl(res.data.url);
    } catch (error) {
      console.log(error);
    }
  };

  const onCpdSendClick = useCallback(async () => {
    try {
      setIsSendingCpd(true);
      await axios.post(cpdSendEndpoint, null, { headers });
      setIsSendingCpd(false);
      setIsCpdPreviewModalOpen(false);
      openNotification('CPD emailed successfully!');
      await axios.patch(applicationUrl, { status: STATUS_READY_FOR_SUBMISSION }, { headers });
      mutate();
    } catch (error) {
      console.log(error);
      setIsSendingCpd(false);
      openNotification('Could not send CPD email', error.response?.data?.message, true);
    }
  }, []);

  const submitLoanDetails = async () => {
    try {
      setIsSubmittingLoanDetails(true);
      await axios.post(submitLoanDetailsEndpoint, null, { headers });
      setIsSubmittingLoanDetails(false);
      openNotification('Submitted successfully!');
      await axios.patch(applicationUrl, { status: STATUS_SUBMITTED_TO_LENDER }, { headers });
      mutate();
    } catch (error) {
      console.log(error);
      setIsSubmittingLoanDetails(false);
      openNotification('Could not submit', error.response?.data?.message, true);
    }
  };

  const submission = application?.loanDetails?.submission;
  const questionnaireItems = submission && [
    {
      key: '1',
      label: 'Does the customer agree with the proposed loan details?',
      children: <p className="font-semibold">{getYesOrNo(submission.isAgreeingToLoanDetails)}</p>,
      span: 3
    },
    {
      key: '2',
      label: `Does the proposed loan satisfy the customer's objective and requirements?`,
      children: <p className="font-semibold">{getYesOrNo(submission.isSatisfyingRequirements)}</p>,
      span: 3
    },
    {
      key: '3',
      label:
        'Will the customer be able to comply with the financial obligations of this loan without incurring substantial hardship?',
      children: (
        <p className="font-semibold">{getYesOrNo(submission.canComplyWithFinancialObligations)}</p>
      ),
      span: 3
    },
    {
      key: '4',
      label:
        'Do you agree there are no known or likely changes to circumstances in the immediate future which will impact the suitability of this proposed loan?',
      children: <p className="font-semibold">{getYesOrNo(submission.canCircumstancesChange)}</p>,
      span: 3
    },
    {
      key: '5',
      label:
        'Have you made the customer aware of any potential costs associated with repaying the credit contract early?',
      children: (
        <p className="font-semibold">{getYesOrNo(submission.isAwareOfEarlyRepaymentCosts)}</p>
      ),
      span: 3
    }
  ];
  const { applicants } = application;

  const hasAllApplicantsSignedPrivacy = applicants.every(
    (applicant) =>
      applicant.complianceDocsRequests && !!applicant.complianceDocsRequests[0]?.signedAt
  );

  const isLoanDetailsSubmitted = !!application?.loanDetails?.submission?.submittedAt;
  const isPrelimAssessmentGenerated = !!submission?.prelimAssessment?.generatedAt;
  const cpd = submission?.cpd;

  const onEditLoanSubmissionClick = () => {
    setIsEditingSubmission(true);
  };

  if (!application) return <Skeleton />;

  return (
    <div className="pr-8 mt-4">
      <Alert
        message={
          <Typography.Text strong>
            {hasAllApplicantsSignedPrivacy
              ? LOAN_SUBMISSION_ESIGN_DONE_MSG
              : LOAN_SUBMISSION_ESIGN_PENDING_MSG}
          </Typography.Text>
        }
        description={!hasAllApplicantsSignedPrivacy && LOAN_SUBMISSION_ESIGN_PENDING_DESC}
        type={hasAllApplicantsSignedPrivacy ? 'success' : 'warning'}
        showIcon
      />
      {isPrelimAssessmentGenerated && !isEditingSubmission && (
        <Alert
          message={<Typography.Text strong>Preliminary Assessment</Typography.Text>}
          description={`Preliminary assessment was generated at ${dayjs(submission.prelimAssessment.generatedAt).format('DD/MM/YYYY hh:mm a')}`}
          type={'success'}
          showIcon
          className="mt-4"
          action={
            <>
              <Button
                icon={<EditOutlined className="mr-2" />}
                className="mt-3 mr-2"
                onClick={onEditLoanSubmissionClick}>
                Edit loan submission
              </Button>
              <Button className="mt-3 mr-2">
                <a
                  rel="noreferrer"
                  href={`/applications/${application._id}/files?key=${submission.prelimAssessment.key}`}
                  target="_blank">
                  View Assessment
                </a>
              </Button>
            </>
          }
        />
      )}

      {cpd && !cpd.emailSentAt && !isEditingSubmission && (
        <Alert
          message={<Typography.Text strong>Credit Proposal Disclosure</Typography.Text>}
          description="CPD has been generated. Use the CTA to preview and send it as an email"
          type="info"
          showIcon
          className="mt-4"
          action={
            <Button className="mt-3 mr-2" type="primary" onClick={onPreviewCpdClick}>
              Preview & Send
            </Button>
          }
        />
      )}

      {cpd?.emailSentAt && !isEditingSubmission && (
        <Alert
          message={<Typography.Text strong>Credit Proposal Disclosure</Typography.Text>}
          description={`CPD sent at ${dayjs(cpd.emailSentAt).format('DD/MM/YYYY hh:mm a')}`}
          type="success"
          showIcon
          className="mt-4"
        />
      )}
      {cpd?.emailSentAt && !isLoanDetailsSubmitted && !isEditingSubmission && (
        <Alert
          message={<Typography.Text strong>Submission</Typography.Text>}
          description="Use the CTA to submit loan details"
          type={'info'}
          showIcon
          className="mt-4"
          action={
            <Button
              className="mt-3 mr-2"
              onClick={submitLoanDetails}
              loading={isSubmittingLoanDetails}>
              Submit loan details
            </Button>
          }
        />
      )}
      {isLoanDetailsSubmitted && !isEditingSubmission && (
        <Alert
          message={<Typography.Text strong>Submission</Typography.Text>}
          description={`Loan details was submitted at ${dayjs(application.loanDetails.submission.submittedAt).format('DD/MM/YYYY hh:mm a')}`}
          type={'success'}
          showIcon
          className="mt-4"
        />
      )}
      <div className="pr-8 mt-8">
        <Form onFinish={onFinish} layout="vertical" form={form} initialValues={submission}>
          <Divider orientation="left" orientationMargin="0">
            <Typography.Title level={4}>Lender</Typography.Title>
          </Divider>

          <Row gutter={rowGutter}>
            <Col span={12}>
              {!isEditingSubmission && isPrelimAssessmentGenerated ? (
                <Descriptions
                  key="lender"
                  colon={false}
                  layout="vertical"
                  items={[{ children: <p className="font-semibold">{submission.lender}</p> }]}
                />
              ) : (
                <Form.Item label="Lender" name="lender" rules={[{ required: true }]}>
                  <Select>
                    <Select.Option value="demo">Demo</Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
          <LoanDetailsRateFeesSection
            rowGutter={rowGutter}
            isReadOnly={!isEditingSubmission && isPrelimAssessmentGenerated}
            data={submission}
          />
          <Divider></Divider>
          {!isEditingSubmission && isPrelimAssessmentGenerated && (
            <Descriptions layout="horizontal" items={questionnaireItems} colon={false} />
          )}
          {(!isPrelimAssessmentGenerated || isEditingSubmission) && (
            <>
              <Form.Item
                label="Does the customer agree with the proposed loan details?"
                name="isAgreeingToLoanDetails"
                initialValue={false}>
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item
                label="Does the proposed loan satisfy the customer's objective and requirements?"
                name="isSatisfyingRequirements"
                initialValue={false}>
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item
                label="Will the customer be able to comply with the financial obligations of this loan without incurring substantial hardship?"
                name="canComplyWithFinancialObligations"
                initialValue={false}>
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item
                label="Do you agree there are no known or likely changes to circumstances in the immediate future which will impact the suitability of this proposed loan?"
                name="canCircumstancesChange"
                initialValue={false}>
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item
                label="Have you made the customer aware of any potential costs associated with repaying the credit contract early?"
                name="isAwareOfEarlyRepaymentCosts"
                initialValue={false}>
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item
                label="How does the selected lender and loan product meet the customer's requirements?"
                name="matchDescription">
                <Input.TextArea rows={4} placeholder="Describe here.."></Input.TextArea>
              </Form.Item>
              <Form.Item className="mt-12">
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={!hasAllApplicantsSignedPrivacy}
                  loading={isGeneratingAssessment}>
                  Save details & generate assessment
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </div>
      <CpdPreviewModal
        isOpen={isCpdPreviewModalOpen}
        handleCancel={handleCpdModalCancel}
        documentUrl={cpdDocumentUrl}
        onSend={onCpdSendClick}
        isLoading={isSendingCpd}
      />
    </div>
  );
}

export default memo(LoanSubmission);
