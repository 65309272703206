/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Skeleton,
  Drawer,
  Typography,
  Form,
  Button,
  Popconfirm,
  Checkbox,
  Table,
  Space,
  Tooltip,
  Tag,
  InputNumber
} from 'antd';
import useSWR, { mutate } from 'swr';
import axios from 'axios';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { API_HOST } from '../../helpers/constants';
import { Frequency, INCOME_TYPES } from '../../types';
import NetPosition from './widgets/NetPosition';
import { currency, getApplicantFullName, openNotification } from '../../helpers';
import { fetcher } from '../../utils';

function Income({ showNetPosition = true }) {
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [applicantId, setApplicantId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOtherIncomeDrawerVisible, setIsOtherIncomeDrawerVisible] = useState(false);
  const [employmentId, setEmploymentId] = useState(null);
  const [incomeId, setIncomeId] = useState(null);
  const [hasExtraEarnings, setHasExtraEarnings] = useState(false);
  const [form] = Form.useForm();
  const [otherIncomesForm] = Form.useForm();
  const [isEmploymentIncomeDrawerVisible, setIsEmploymentIncomeDrawerVisible] = useState(false);
  const [selectedEmployment, setSelectedEmployment] = useState();

  const url = `${API_HOST}/applications/${applicationId}`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
    }
  }, [responseData]);

  const onSubmit = async (values) => {
    setIsLoading(true);

    try {
      await axios.patch(
        `${API_HOST}/applications/${applicationId}/applicants/${applicantId}/employments/${employmentId}/incomes`,
        values
      );
      form.resetFields();
      setIsEmploymentIncomeDrawerVisible(false);
      mutate(url);
      openNotification('Success', 'Employment Income saved');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      openNotification('Error', 'Employment Income could not be saved', true);
      setIsLoading(false);
    }
  };

  const onOtherIncomeSubmit = async (values) => {
    setIsLoading(true);
    let iurl = `${API_HOST}/applications/${applicationId}/applicants/${applicantId}/incomes`;
    iurl = incomeId ? `${iurl}/${incomeId}` : iurl;

    try {
      await axios.put(iurl, values);
      otherIncomesForm.resetFields();
      setIsOtherIncomeDrawerVisible(false);
      mutate(url);
      openNotification('Success', 'Incomes saved');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      openNotification('Error', 'Incomes could not be saved', true);
      setIsLoading(false);
    }
  };

  const onShowDrawer = (aid, eid) => {
    setEmploymentId(eid);
    setApplicantId(aid);
    const applicant = application.applicants.find((a) => a._id === aid);
    const employment = applicant.employments?.find((e) => e._id === eid);
    setHasExtraEarnings(employment.hasExtraEarnings);
    setSelectedEmployment(employment);
    form.resetFields();
    form.setFieldsValue(employment);
    setIsEmploymentIncomeDrawerVisible(true);
  };
  const onShowOtherIncomeDrawer = (aid, incomeId) => {
    if (!incomeId) {
      otherIncomesForm.resetFields();
    }
    setIncomeId(incomeId);
    setApplicantId(aid);
    const applicant = application.applicants.find((a) => a._id === aid);
    const income = applicant.incomes.find((e) => e._id === incomeId);
    otherIncomesForm.setFieldsValue(income);
    setIsOtherIncomeDrawerVisible(true);
  };

  const onHasExtraEarningsChanged = (e) => {
    setHasExtraEarnings(e.target.checked);
  };

  const onDelete = async (applicantId, incomeId) => {
    try {
      setIsLoading(true);
      await axios.delete(
        `${API_HOST}/applications/${applicationId}/applicants/${applicantId}/incomes/${incomeId}`
      );
      openNotification(`Deleted income`, `Successfully deleted income`);
      mutate(url);
    } catch (e) {
      openNotification('Deletion Failed', `Cound not delete income`, true);
      console.log(e.message);
    }
    setIsLoading(false);
  };

  const columns = (applicantId) => [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '30%'
    },
    {
      title: 'Net Amount',
      dataIndex: 'netIncome',
      key: 'netIncome',
      width: '20%',
      render: (text) => currency(text)
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      width: '15%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '20%',
      key: 'description'
    },
    {
      title: 'Action',
      key: 'action',
      width: '15%',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onShowOtherIncomeDrawer(applicantId, record._id)}>
            <EditOutlined />
          </a>
          <a>
            <Tooltip>
              <Popconfirm
                placement="top"
                title="Are you sure to delete?"
                onConfirm={() => onDelete(applicantId, record._id)}
                okText="Yes"
                cancelText="No">
                <DeleteOutlined style={{ fontSize: 16 }} className="text-red-700" />
              </Popconfirm>
            </Tooltip>
          </a>
        </Space>
      )
    }
  ];

  return application ? (
    <div>
      {showNetPosition && <NetPosition application={application} />}
      <div className="mt-2">
        <Card title="Incomes">
          {application.applicants.map((applicant, i) => (
            <div key={applicant._id} className="mb-4">
              <Card
                title={getApplicantFullName(application.applicants[i].userInfo)}
                type="inner"
                className="mb-1">
                <Row gutter={24} style={{ marginBottom: 20 }}>
                  <Col span={7} className="font-bold">
                    Employment
                  </Col>
                  <Col span={5} className="font-bold">
                    Net Income
                  </Col>
                  <Col span={5} className="font-bold">
                    Frequency
                  </Col>
                </Row>
                {applicant.employments?.map((e) => (
                  <Row gutter={24} style={{ marginBottom: 20 }} key={e._id}>
                    <Col span={7}>
                      {e.employerName}{' '}
                      {e.isCurrent ? (
                        <Tag color="orange" className="ml-4">
                          Current
                        </Tag>
                      ) : (
                        ''
                      )}{' '}
                    </Col>
                    <Col span={5}>{e.netIncome ? currency(e.netIncome) : '--'}</Col>
                    <Col span={5}>{e.netIncomeFrequency || '--'}</Col>
                    <Col span={3}>
                      <Button
                        type="link"
                        onClick={() => onShowDrawer(applicant._id, e._id)}
                        icon={<EditOutlined />}
                      />
                    </Col>
                  </Row>
                ))}
                <Divider />
                <Row gutter={24} style={{ marginBottom: 20 }}>
                  <Col span={5}>
                    <Typography.Text className="font-bold">Other Incomes</Typography.Text>
                  </Col>
                  <Col span={5}>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => onShowOtherIncomeDrawer(applicant._id)}>
                      Add Other Incomes
                    </Button>
                  </Col>
                </Row>
                <Table
                  columns={columns(applicant._id)}
                  dataSource={applicant.incomes}
                  rowKey={(row) => row._id}
                />
              </Card>
            </div>
          ))}
        </Card>
      </div>
      <Drawer
        title="Employment Income"
        open={isEmploymentIncomeDrawerVisible}
        width={600}
        onClose={() => setIsEmploymentIncomeDrawerVisible(false)}
        closable>
        <Typography.Title level={3}>{selectedEmployment?.employerName}</Typography.Title>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="netIncome"
                label="Net Income"
                rules={[{ required: true, message: 'Net Income is required' }]}>
                <InputNumber
                  className="w-full"
                  defaultValue={1000}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="netIncomeFrequency"
                label="Frequency"
                rules={[{ required: true, message: 'Frequency is required' }]}>
                <Select>
                  {Object.keys(Frequency).map((key) => (
                    <Select.Option value={Frequency[key]} key={Frequency[key]}>
                      {Frequency[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="hasExtraEarnings"
                label="Has Extra Earnings?"
                valuePropName="checked">
                <Checkbox onChange={onHasExtraEarningsChanged} />
              </Form.Item>
            </Col>
          </Row>
          {hasExtraEarnings ? (
            <>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name="commissionPretax" label="Commission Pretax">
                    <InputNumber
                      className="w-full"
                      defaultValue={1000}
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="commissionPretaxFrequency" label="Frequency">
                    <Select>
                      {Object.keys(Frequency).map((key) => (
                        <Select.Option value={Frequency[key]} key={Frequency[key]}>
                          {Frequency[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name="overtimePretax" label="Overtime Pretax">
                    <InputNumber
                      className="w-full"
                      defaultValue={1000}
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="overtimePretaxFrequency" label="Frequency">
                    <Select>
                      {Object.keys(Frequency).map((key) => (
                        <Select.Option value={Frequency[key]} key={Frequency[key]}>
                          {Frequency[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name="allowancesPretax" label="Allowances Pretax">
                    <InputNumber
                      className="w-full"
                      defaultValue={1000}
                      formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="allowancesPretaxFrequency" label="Frequency">
                    <Select>
                      {Object.keys(Frequency).map((key) => (
                        <Select.Option value={Frequency[key]} key={Frequency[key]}>
                          {Frequency[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : null}
          <Divider />
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Form>
      </Drawer>
      <Drawer
        title="Other Incomes"
        open={isOtherIncomeDrawerVisible}
        width={600}
        onClose={() => setIsOtherIncomeDrawerVisible(false)}
        closable>
        <Form layout="vertical" form={otherIncomesForm} onFinish={onOtherIncomeSubmit}>
          <Form.Item
            rules={[{ required: true, message: 'Type is required' }]}
            label="Type"
            name="type">
            <Select>
              {INCOME_TYPES.map((incomeType) => (
                <Select.Option value={incomeType} key={incomeType}>
                  {incomeType}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Net Income"
                name="netIncome"
                rules={[{ required: true, message: 'Net Income is required' }]}>
                <InputNumber
                  className="w-full"
                  defaultValue={1000}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Frequency"
                name="frequency"
                rules={[{ required: true, message: 'Frequency is required' }]}>
                <Select>
                  {Object.keys(Frequency).map((key) => (
                    <Select.Option value={Frequency[key]} key={Frequency[key]}>
                      {Frequency[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Divider />
          <Row gutter={24}>
            <Col span={3}>
              <Button type="primary" loading={isLoading} htmlType="submit">
                Save
              </Button>
            </Col>
            {/* <Col span={6}>
              <Button loading={isLoading} htmlType='submit'>Save and Add New</Button>
            </Col> */}
          </Row>
        </Form>
      </Drawer>
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default Income;
