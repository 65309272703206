import { CheckSquareOutlined, FileOutlined, PieChartOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  };
}

function ApplicationSider({ isCollapsed, setCollapsed, data: { resource, resourceId } }) {
  const items = [
    getItem(<Link to={`/${resource}/${resourceId}`}>Details</Link>, '0', <PieChartOutlined />),
    getItem(
      <Link to={`/${resource}/${resourceId}/tasks`}>Tasks</Link>,
      `/${resource}/${resourceId}/tasks`,
      <CheckSquareOutlined />
    ),
    getItem(
      <Link to={`/${resource}/${resourceId}/notes`}>Notes</Link>,
      `/${resource}/${resourceId}/notes`,
      <FileOutlined />
    )
  ];

  return (
    <Sider
      collapsible
      collapsed={isCollapsed}
      onCollapse={(val) => setCollapsed(val)}
      theme="light"
      width={140}>
      <Menu
        theme="light"
        defaultSelectedKeys={['0']}
        selectedKeys={[location.pathname]}
        mode="inline"
        items={items}
        className="mt-4"
      />
    </Sider>
  );
}

ApplicationSider.propTypes = {
  isCollapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  data: PropTypes.shape({
    resource: PropTypes.oneOf(['applications', 'leads']).isRequired,
    resourceId: PropTypes.string
  })
};

export default ApplicationSider;
