import { Button, Card, Col, Form, Input, Row, Skeleton } from 'antd';
import useSWR from 'swr';
import { fetcher, headers } from '../../../utils';
import { API_HOST } from '../../../helpers/constants';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { openNotification } from '../../../helpers';

export default function SellerDetails() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = async (data) => {
    try {
      setIsSubmitting(true);
      await axios.post(sellerDetailsEndpoint, data, { headers: headers });
      mutate();
      setIsSubmitting(false);
      openNotification('Seller details saved successfully!');
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      openNotification('Could not save seller details', error.response?.data?.message, true);
    }
  };

  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application, mutate } = useSWR(applicationUrl, fetcher);
  const sellerDetailsEndpoint = `${applicationUrl}/settlement/seller-details`;

  if (!application) return <Skeleton />;

  return (
    <Card type="inner" title="Seller Details">
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={application.loanDetails.settlement?.sellerDetails}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="First name" name="firstName" rules={[{ required: true }]}>
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last name" name="lastName" rules={[{ required: true }]}>
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Phone number" name="phone" rules={[{ required: true }]}>
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="text-right">
              <Button htmlType="submit" type="primary" loading={isSubmitting}>
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
