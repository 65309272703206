import { Breadcrumb, Button, Col, Input, Popconfirm, Row, Space, Table, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { getApplicantFullName, openNotification } from '../../helpers';
import { deleteUser } from '../../services/users.http';
import { useUsers } from '../../hooks/useUsers';
import Highlighter from 'react-highlight-words';

function UsersList() {
  const defaultRoleFilters = ['broker', 'admin'];

  const [allParams, setAllParams] = useState({
    skip: 0,
    limit: 10,
    'filters[role]': defaultRoleFilters
  });
  const navigate = useNavigate();

  const { users, meta, revalidate, isLoading } = useUsers(allParams);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAllParams({ ...allParams, [`filters[${dataIndex}]`]: selectedKeys[0] });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined
        }}
      />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const getColumnSortProps = (column, sortOrder) => {
    setAllParams({
      ...allParams,
      sortBy: sortOrder === 'descend' ? `-${column}` : column
    });
  };

  const onDeleteClick = async (id) => {
    try {
      await deleteUser(id);
      revalidate();
      openNotification('Success', `Deleted successfully!`);
    } catch (e) {
      console.log(e);
    }
  };

  const onAddUserClick = () => {
    navigate('/users/create', { replace: true });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b, sortOrder) => getColumnSortProps('firstName', sortOrder),
      ...getColumnSearchProps('firstName'),
      render: (text, row) => <Link to={`/users/${row._id}`}>{getApplicantFullName(row)}</Link>
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      ...getColumnSearchProps('mobile'),
      key: 'mobile'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email')
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      sorter: true,
      filters: ['customer', 'broker'].map((r) => ({
        text: r,
        value: r
      })),
      onFilter: () => true,
      defaultFilteredValue: defaultRoleFilters
    },
    {
      title: 'Email Verified?',
      dataIndex: 'isEmailVerified',
      key: 'isEmailVerified',
      align: 'center',
      render: (text) =>
        text ? (
          <CheckCircleFilled style={{ color: 'green' }} className="text-lg" />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} className="text-lg" />
        )
    },
    {
      title: 'Enabled?',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      align: 'center',
      render: (text) =>
        text ? (
          <CheckCircleFilled style={{ color: 'green' }} className="text-lg" />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} className="text-lg" />
        )
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/users/${record._id}`}>Edit</Link>
          <Popconfirm
            title="Are you sure to delete?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onDeleteClick(record._id)}>
            <a href="#">Delete</a>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb items={[{ title: <Link to="/">Home</Link> }, { title: 'Users' }]} />
      <Row gutter={24} className="mt-4 mb-2">
        <Col span={12}>
          <Typography.Title level={4}>Users List</Typography.Title>
        </Col>
        <Col span={12} className="text-right">
          <Button type="primary" icon={<PlusOutlined />} onClick={onAddUserClick}>
            Add User
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={users}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        pagination={{
          total: meta?.total,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          }
        }}
        footer={() => (
          <Typography.Title level={5}>Total Records: {meta?.total || '--'}</Typography.Title>
        )}
      />
    </div>
  );
}

export default UsersList;
