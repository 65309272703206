/* eslint-disable react/prop-types */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Col, Popconfirm, Row, Space, Tag, Tooltip, Typography } from 'antd';

function AddressCard({ address, onEditClick, onDeleteClick }) {
  return (
    <Card
      type="inner"
      className="mb-4"
      title={
        <Space>
          <span>Address</span>
          {address.isCurrent ? <Tag color="orange">Current</Tag> : ''}
        </Space>
      }
      extra={
        <Space size="middle">
          <a href="#" onClick={() => onEditClick(address)}>
            <EditOutlined />
          </a>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure to delete applicant?"
              onConfirm={() => onDeleteClick(address._id)}>
              <a href="!#">
                <DeleteOutlined className="text-red-600" style={{ fontSize: 18 }} />{' '}
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      }>
      <Row gutter={24}>
        <Col span={6}>
          <Typography.Text strong className="block">
            Unit #
          </Typography.Text>
          {address.unitNumber || '--'}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Street #
          </Typography.Text>
          {address.streetNumber}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Street Name
          </Typography.Text>
          {address.streetName}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Suburb
          </Typography.Text>
          {address.city}
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={6}>
          <Typography.Text strong className="block">
            State
          </Typography.Text>
          {address.state}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Postcode
          </Typography.Text>
          {address.postcode}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Country
          </Typography.Text>
          {address.country}
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={6}>
          <Typography.Text strong className="block">
            Time at address (Yrs){' '}
          </Typography.Text>
          {address.yearsDuration}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Time at address (Mths)
          </Typography.Text>
          {address.monthsDuration}
        </Col>
      </Row>
    </Card>
  );
}

export default AddressCard;
