/* eslint-disable react/jsx-props-no-spreading */
import { Column } from '@ant-design/plots';

function MonthlySalesChart() {
  const data = [
    {
      month: 'Jan',
      sales: 38000
    },
    {
      month: 'Feb',
      sales: 52000
    },
    {
      month: 'Mar',
      sales: 61000
    },
    {
      month: 'Apr',
      sales: 15000
    },
    {
      month: 'May',
      sales: 48000
    },
    {
      month: 'Jun',
      sales: 38000
    },
    {
      month: 'Jul',
      sales: 32000
    },
    {
      month: 'Aug',
      sales: 58000
    }
  ];
  const config = {
    data,
    xField: 'month',
    yField: 'sales',
    // color: '#72C1C2',
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.6
      }
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      }
    },
    meta: {
      month: {
        alias: 'Month'
      },
      sales: {
        alias: 'Sales'
      }
    }
  };
  return <Column {...config} />;
}

export default MonthlySalesChart;
