import { DeleteOutlined, FileOutlined, InboxOutlined, WarningFilled } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Modal,
  Popconfirm,
  Row,
  Transfer,
  Typography,
  Upload
} from 'antd';
import { useState } from 'react';
import { API_HOST, SETTLEMENT_DOCUMENTS } from '../../../helpers/constants';
import { fetcher, headers } from '../../../utils';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { openNotification } from '../../../helpers';
import axios from 'axios';
import dayjs from 'dayjs';
const { Dragger } = Upload;

export default function SettlementDocuments() {
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application, mutate } = useSWR(applicationUrl, fetcher);

  const requestDocsEndpoint = `${applicationUrl}/settlement/bulk`;

  const [isAddingDocuments, setisAddingDocuments] = useState(false);
  const [isAddDocsModalOpen, setIsAddDocsModalOpen] = useState(false);
  const [transferTargetKeys, setTransferTargetKeys] = useState([]);
  const [transferSelectedKeys, setTransferSelectedKeys] = useState([]);

  const requestedDocs = application?.loanDetails?.settlement?.requestedDocuments;
  const documents = application?.loanDetails?.settlement?.documents;

  const alreadyRequestedDocs = requestedDocs?.map(({ type }) => type);

  const allDocuments = SETTLEMENT_DOCUMENTS.filter((doc) => !alreadyRequestedDocs?.includes(doc))
    .sort()
    .map((d) => ({ key: d, title: d }));

  const props = {
    name: 'file',
    multiple: true,
    customRequest: async ({ data, file, onError, onSuccess }) => {
      const formData = new FormData();
      if (data) {
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
      }
      formData.append('file', file);

      try {
        let durl = `${applicationUrl}/settlement/documents/upload`;
        await axios.put(durl, formData, { headers });
        onSuccess('Ok');
        openNotification('Document uploaded successfully');
        mutate();
      } catch (err) {
        console.error(err);
        openNotification('Could not upload document', err.response?.data.message, true);
        onError(err);
      }
    }
  };

  const deleteRequestedDocument = async (requestedDocId) => {
    try {
      await axios.delete(`${applicationUrl}/settlement/requestedDoc/${requestedDocId}`, {
        headers
      });
      openNotification('Deleted successfully');
      mutate();
    } catch (error) {
      console.log(error);
      openNotification('Could not delete document', error.response?.data?.message, true);
    }
  };

  const deleteDocument = async (docId) => {
    try {
      await axios.delete(`${applicationUrl}/settlement/document/${docId}`, { headers });
      openNotification('Deleted successfully!');
      mutate();
    } catch (error) {
      console.error(error);
      openNotification('Could not delete document', error.response?.data?.message, true);
    }
  };

  const getDocuments = () => {
    const docsTree = {};
    requestedDocs.forEach((requestedDoc) => {
      const _key = requestedDoc.type;
      docsTree[_key] = docsTree[_key] || {};
      const docs = (documents || []).filter((doc) => doc.type == _key);
      docsTree[_key].id = requestedDoc._id;
      docsTree[_key].requestedAt = requestedDoc.requestedAt;
      docsTree[_key].docs = docs;
    });

    const jsx = Object.entries(docsTree).map(([docType, docObject]) => {
      return (
        <Col span={8} key={docType}>
          <Card
            styles={{ body: { padding: 10, paddingRight: 12 } }}
            className="border-gray-400 shadow mb-2">
            <Row gutter={24} className="mb-2">
              <Col span={21}>
                {!docObject.docs[0]?.uploadedAt && (
                  <WarningFilled className="text-xl mr-2" style={{ color: 'orange' }} />
                )}
                <Typography.Text strong>{docType}</Typography.Text>
              </Col>
              <Col span={3}>
                <Popconfirm
                  placement="top"
                  title="Are you sure to delete the document type?"
                  onConfirm={() => deleteRequestedDocument(docObject.id)}
                  okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
                  okText="Delete"
                  cancelButtonProps={{ size: 'middle' }}>
                  <DeleteOutlined className="text-red-600 text-base" />
                </Popconfirm>
              </Col>
            </Row>
            <div>
              <Dragger data={{ type: docType }} {...props} className="my-1">
                <p>
                  <InboxOutlined className="text-base mr-2" />
                  <span className="!text-xs text-slate-500">
                    Click or drag file to this area to upload
                  </span>
                </p>
              </Dragger>
              <p>
                <Typography.Text style={{ fontSize: '0.65rem' }} type="secondary">
                  Requested: {dayjs(docObject?.requestedAt).format('DD/MM/YYYY hh:mm a')}
                </Typography.Text>
              </p>
              <div className="mt-2">
                {docObject.docs?.map((doc) => (
                  <div className="mb-2 border border-dashed rounded-sm py-1 px-2" key={doc._id}>
                    <Row>
                      <Col span={21}>
                        <a
                          rel="noreferrer"
                          href={`/applications/${application._id}/files?key=${doc.url}`}
                          target="_blank"
                          className="text-blue-500 block">
                          {doc.fileName}
                        </a>
                        {doc.uploadedAt && (
                          <Typography.Text style={{ fontSize: '0.65rem' }} type="secondary">
                            Uploaded: {dayjs(doc.uploadedAt).format('DD/MM/YYYY hh:mm a')}
                          </Typography.Text>
                        )}
                      </Col>

                      <Col span={3}>
                        <Popconfirm
                          placement="top"
                          title="Are you sure to delete document"
                          onConfirm={() => deleteDocument(doc._id)}
                          okText="Delete"
                          okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
                          cancelButtonProps={{ size: 'middle' }}>
                          <DeleteOutlined className="text-red-600 text-sm" />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </Col>
      );
    });

    return jsx;
  };

  const onDocumentTransferred = (nextTargetKeys) => {
    setTransferTargetKeys(nextTargetKeys);
  };

  const onTransferSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    console.log(sourceSelectedKeys);
    setTransferSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const addSelectedDocsFromTransfer = async () => {
    if (transferTargetKeys.length < 1) {
      return openNotification('Please move at least one document to right panel first', '', true);
    }
    const payload = transferTargetKeys.map((documentName) => ({ type: documentName }));
    try {
      setisAddingDocuments(true);
      await axios.post(requestDocsEndpoint, payload, { headers });
      setisAddingDocuments(false);
      openNotification(`${payload.length} document types added successfully`);
      setIsAddDocsModalOpen(false);
      setTransferTargetKeys([]);
      mutate();
    } catch (err) {
      openNotification('Could not add document types', err.response?.data.message, true);
      console.error(err);
      setisAddingDocuments(false);
    }
  };

  return (
    <div>
      <Card type="inner" title="Settlement Documents">
        {!requestedDocs || requestedDocs?.length < 1 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : null}
        <Row gutter={[14, 16]} className="mb-4">
          {requestedDocs && getDocuments()}
        </Row>
        <Button
          icon={<FileOutlined className="mr-2" />}
          onClick={() => {
            setIsAddDocsModalOpen(true);
          }}>
          Add Documents
        </Button>
      </Card>
      <Modal
        title="Add Documents"
        style={{ top: 20 }}
        open={isAddDocsModalOpen}
        onCancel={() => setIsAddDocsModalOpen(false)}
        width={800}
        onOk={addSelectedDocsFromTransfer}
        okText="Add Documents"
        okButtonProps={{ loading: isAddingDocuments }}>
        <div className="max-h-[600px] overflow-x-hidden pr-3">
          <Transfer
            onChange={onDocumentTransferred}
            onSelectChange={onTransferSelectChange}
            targetKeys={transferTargetKeys}
            selectedKeys={transferSelectedKeys}
            dataSource={allDocuments}
            titles={['To select', 'Selected']}
            render={(item) => item.title}
            oneWay
            listStyle={{
              width: 400,
              height: 580
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
