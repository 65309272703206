import { useEffect } from 'react';
import useSWR from 'swr';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { fetcher } from '../../utils';
import { API_HOST } from '../../helpers/constants';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function File() {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const url = `${API_HOST}/files`;
  const { data: responseData } = useSWR(key ? `${url}?key=${key}` : null, fetcher);

  useEffect(() => {
    if (responseData) {
      if (window !== undefined) {
        window.location.href = responseData.url;
      }
    }
  }, [responseData]);

  return (
    <div className="flex h-[90%]">
      <div className="m-auto">
        <Spin size="large" indicator={antIcon} className="mr-4" />
        Requested document is getting ready.
      </div>
    </div>
  );
}

export default File;
