import { useEffect, useState } from 'react';
import { Alert, Button, Card, Skeleton, Space, Table, Typography } from 'antd';
import useSWR, { mutate } from 'swr';
import dayjs from 'dayjs';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { fetcher } from '../../utils';
import { API_HOST } from '../../helpers/constants';
import { getApplicantFullName, openNotification } from '../../helpers';

function CreditReport() {
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [dataSources, setDataSources] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const url = `${API_HOST}/applications/${applicationId}`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);

      const applicantCreditReports = {};
      responseData.applicants.forEach((a) => {
        applicantCreditReports[a._id] = a.creditReports.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      });
      setDataSources(applicantCreditReports);
    }
  }, [responseData]);

  const makeCreditReportRequest = async (applicantId) => {
    try {
      setIsLoading({ ...isLoading, [applicantId]: true });
      await axios.get(`${url}/applicants/${applicantId}/credit-reports`);
      openNotification('Success', 'Credit Report fetched successfully!');
      mutate(url);
    } catch (error) {
      openNotification('Error', 'Credit Report fetching failed!', true);
      console.log(error);
    }
    setIsLoading({ ...isLoading, [applicantId]: false });
  };

  const checkConditions = (applicant) => {
    if (applicant.addresses.length < 1) {
      return true;
    }
    return false;
  };

  const columns = [
    {
      title: 'Credit Score',
      dataIndex: 'creditScore',
      key: 'creditScore',
      render: (text) => text || '--'
    },
    {
      title: 'Credit Report Ran at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (text ? dayjs(text).format('DD/MM/YYYY hh:mm a') : '--')
    },
    {
      title: 'Last Error',
      dataIndex: 'error',
      key: 'error',
      width: '40%',
      render: (text) =>
        text ? <Alert type="error" style={{ padding: 5 }} description={text} showIcon /> : '--'
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text, record) => (
        <Space size="middle">
          <Button
            disabled={!record.url}
            target="_blank"
            href={`/applications/${record.id}/file?key=${record.url}`}>
            View
          </Button>
        </Space>
      )
    }
  ];

  return application ? (
    <div className="m-4">
      <Typography.Title level={4}>Credit Reports</Typography.Title>
      {application.applicants.map((a) => (
        <Card
          key={a._id}
          title={getApplicantFullName(a.userInfo)}
          bodyStyle={{ padding: 0, margin: 0 }}
          className="mb-4"
          extra={
            <Button
              type="primary"
              disabled={checkConditions(a)}
              loading={isLoading[a._id]}
              onClick={() => makeCreditReportRequest(a._id)}>
              Run Credit Check
            </Button>
          }>
          <Table rowKey={(row) => row._id} dataSource={dataSources[a._id]} columns={columns} />
        </Card>
      ))}
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default CreditReport;
