/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  SwapOutlined
} from '@ant-design/icons';
import {
  Card,
  Col,
  Row,
  Space,
  Typography,
  Menu,
  Breadcrumb,
  Skeleton,
  Badge,
  Select,
  Modal,
  Input,
  Form,
  Tooltip,
  Popconfirm,
  Button,
  Layout
} from 'antd';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import axios from 'axios';
import dayjs from 'dayjs';
import { BiNotepad, BiPencil, BiTask } from 'react-icons/bi';
import {
  API_HOST,
  APPLICANT_PRIMARY,
  APPLICANT_SECONDARY,
  APPLICATION_STATUSES,
  APPLICATION_STATUSES_SEQUENCE,
  STATUS_DECLINED,
  STATUS_WITHDRAWN,
  WITHDRAW_REASONS,
  WITHDRAW_REASON_OTHER
} from '../../helpers/constants';
import { getApplicantFullName, openNotification } from '../../helpers';
import { fetcher, getDomainIcon, headers } from '../../utils';
import { Domain } from '../../types';
import CarLoanFields from './CarLoanFields';
import HomeLoanFields from './HomeLoanFields';
import LoanDetailsModal from './LoanDetailsModal';
import ApplicationSider from '../common/ApplicationSider';

function Application(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [loanDetailsForm] = Form.useForm();
  const [users, setUsers] = useState([]);
  const { applicationId } = useParams();

  const [isCloneModalVisible, setIsCloneModalVisible] = useState(false);
  const [application, setApplication] = useState(null);
  const [primaryApplicant, setPrimaryApplicant] = useState(null);
  const [isLoanDetailsModalOpen, setIsLoanDetailsModalOpen] = useState(false);
  const [secondaryApplicants, setSecondaryApplicants] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [withdrawlReason, setWithdrawlReason] = useState();
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(true);

  const url = `${API_HOST}/applications/${applicationId}`;
  const usersUrl = `${API_HOST}/users?role[]=broker&role[]=admin&limit=1000`;

  const showLoanDetailsModal = () => setIsLoanDetailsModalOpen(true);
  const hideLoanDetailsModal = () => setIsLoanDetailsModalOpen(false);

  const getRoutePrefix = () => {
    if (params.leadId) {
      return `/leads/${params.leadId}`;
    }
    return '';
  };

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);
  const { data: usersResponseData } = useSWR(usersUrl, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      const { applicants } = responseData;
      setPrimaryApplicant(applicants?.find((a) => a.type === APPLICANT_PRIMARY));
      setSecondaryApplicants(applicants?.filter((a) => a.type === APPLICANT_SECONDARY));

      loanDetailsForm.setFieldsValue(responseData);
    }
  }, [responseData]);

  useEffect(() => {
    if (usersResponseData) {
      setUsers(usersResponseData.data);
    }
  }, [usersResponseData]);

  let selectedKey;

  if (location.pathname.includes('/requirements')) {
    selectedKey = 'requirements';
  } else if (location.pathname.includes('/applicants')) {
    selectedKey = 'applicants';
  } else if (location.pathname.includes('/property')) {
    selectedKey = 'property';
  } else if (location.pathname.includes('/income')) {
    selectedKey = 'income';
  } else if (location.pathname.includes('/expense')) {
    selectedKey = 'expense';
  } else if (location.pathname.includes('/assets')) {
    selectedKey = 'assets';
  } else if (location.pathname.includes('/compliance')) {
    selectedKey = 'compliance';
  } else if (location.pathname.includes('/credit-report')) {
    selectedKey = 'credit-report';
  } else if (
    location.pathname.includes('/documents') &&
    !location.pathname.includes('/settlement')
  ) {
    selectedKey = 'documents';
  } else if (location.pathname.includes('/quotes')) {
    selectedKey = 'quotes';
  } else if (
    location.pathname.includes('/loan-details') &&
    !location.pathname.includes('/settlement')
  ) {
    selectedKey = 'loan-details';
  } else if (location.pathname.includes('/settlement')) {
    selectedKey = 'settlement';
  } else if (location.pathname.includes('/notes')) {
    selectedKey = 'notes';
  } else if (location.pathname.includes('/tasks')) {
    selectedKey = 'tasks';
  } else {
    selectedKey = 'requirements';
  }

  const onStatusChange = async (status) => {
    if (status === STATUS_WITHDRAWN) {
      setIsStatusModalVisible(true);
      return;
    }
    await updateStatus({ status, statusReason: null, statusReasonOther: null });
  };

  const onWithdrawlReasonChange = (reason) => {
    setWithdrawlReason(reason);
  };

  const updateStatus = async (values) => {
    try {
      setIsLoading(true);
      await axios.patch(url, values, { headers });
      openNotification('Success', 'Status updated!');
      mutate(url);
      setIsStatusModalVisible(false);
      setIsLoading(false);
    } catch (error) {
      openNotification('Request Failed', 'Status updation failed!', true);
      setIsStatusModalVisible(false);
      setIsLoading(false);
    }
  };

  const onFieldChange = async (field, value) => {
    try {
      const { data } = await axios.patch(url, { [field]: value }, { headers });
      setApplication(data);
      openNotification('Success', 'Updated!');
    } catch (e) {
      openNotification('Failure', 'Sorry, could not update', true);
      console.log(e.message);
    }
  };

  const onWithdrawnReasonSubmit = async (values) => {
    const v = { ...values, status: STATUS_WITHDRAWN };
    await updateStatus(v);
  };

  const onDeleteApplicant = async (id) => {
    try {
      await axios.delete(`${API_HOST}/applications/${applicationId}/applicants/${id}`);
      openNotification('Applicant Deleted', 'Applicant deleted successfully');
      navigate(`/applications/${applicationId}`, { replace: true });
    } catch (e) {
      console.log(e);
      openNotification('Failure', 'Applicant cannot be deleted successfully', true);
    }
  };

  const onMakeApplicantPrimary = async (id) => {
    try {
      await axios.patch(`${API_HOST}/applications/${applicationId}/applicants/${id}/primary`);
      openNotification('Applicant made Primary', 'Applicant made primary successfully');
      mutate(url);
    } catch (error) {
      console.log(error);
      openNotification('Failure', 'Applicant cannot be made Primary', true);
    }
  };

  const showCloneModal = () => setIsCloneModalVisible(true);

  const cloneApplication = async () => {
    try {
      await axios.post(`${API_HOST}/applications/${applicationId}`, {}, { headers });
    } catch (error) {
      console.log(error);
    }
  };

  return application ? (
    <>
      {!props.isWidget && (
        <div style={{ margin: 10 }}>
          <Breadcrumb
            className="mb-3"
            items={[
              { title: <Link to="/">Home</Link> },
              { title: <Link to="/applications">Applications</Link> },
              { title: application?.shortId }
            ]}
          />
          <Badge.Ribbon text={application?.type} placement="start" style={{ marginTop: '-13px' }} />
          <div className="bg-white rounded-md shadow py-7 px-3">
            <Row gutter={24}>
              <Col span={3}>
                <Typography.Text strong>Application Id</Typography.Text>
                <p className="mt-2">{getDomainIcon(application.domain)}</p>
                <p className="mt-0 text-xs">{application?.shortId}</p>
              </Col>
              {application?.domain === Domain.Car ? (
                <Col span={3}>
                  <Typography.Text strong>Purpose</Typography.Text>
                </Col>
              ) : (
                <Col span={3}>
                  <Typography.Text strong>Purpose</Typography.Text>
                  <p className="mt-2">{application?.properties[0]?.usage}</p>
                </Col>
              )}

              <Col span={3}>
                <Typography.Text strong>Applicants</Typography.Text>
                <p className="mt-2">{application?.applicants?.length > 1 ? 'Joint' : 'Single'}</p>
              </Col>
              <Col span={3}>
                <Typography.Text strong>Created At</Typography.Text>
                <p className="mt-2">{dayjs(application?.createdAt).format('DD/MM/YYYY hh:mm a')}</p>
              </Col>
              <Col span={3}>
                <Typography.Text strong>Owner</Typography.Text>
                <div className="mt-2">
                  <Select
                    value={application?.owner}
                    onChange={(v) => onFieldChange('owner', v)}
                    className="w-full"
                    options={users?.map((u) => ({
                      label: `${u.firstName} ${u.lastName}`,
                      value: u._id
                    }))}
                  />
                </div>
              </Col>
              <Col span={9}>
                <Typography.Text strong>Status</Typography.Text>
                <Row gutter={12}>
                  <Col span={7}>
                    <div className="mt-2">
                      <Select
                        className="w-52"
                        onChange={onStatusChange}
                        defaultValue={application?.status}
                        loading={isLoading}>
                        {APPLICATION_STATUSES.map((a) => (
                          <Select.Option key={a} value={a}>
                            {a}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={6}>
                    {[STATUS_WITHDRAWN, STATUS_DECLINED].includes(application?.status) && (
                      <Button type="primary" className="mt-2" onClick={showCloneModal}>
                        Clone
                      </Button>
                    )}
                  </Col>
                  <Col span={8}>
                    <Space size="small">
                      <Link to={`/applications/${applicationId}/notes`}>
                        <Button
                          type="default"
                          icon={<BiNotepad className="inline text-xl -mt-1" />}>
                          Notes{' '}
                          {application?.notes?.length ? (
                            <Badge className="ml-1" count={application?.notes?.length} />
                          ) : null}
                        </Button>
                      </Link>
                      <Link to={`/applications/${applicationId}/tasks`}>
                        <Button
                          type="default"
                          icon={<BiTask className="inline text-lg mr-2 -mt-1" />}>
                          Tasks
                        </Button>
                      </Link>
                    </Space>
                  </Col>
                </Row>
                <Modal
                  title="Clone Application"
                  open={isCloneModalVisible}
                  onOk={cloneApplication}
                  onCancel={() => setIsCloneModalVisible(false)}>
                  <Typography.Title level={5}>
                    Are you sure to clone this application?
                  </Typography.Title>
                  <Typography.Text>
                    All other information will be clones except the loan details, so you can submit
                    to other lender
                  </Typography.Text>
                </Modal>
              </Col>
            </Row>
          </div>

          <div style={{ margin: '10px 0' }}>
            <Row gutter={10}>
              <Col span={6}>
                <Card
                  title="Primary Applicant"
                  className="rounded-md shadow"
                  bodyStyle={{ padding: 20 }}
                  extra={
                    <Space size="large">
                      <a href="!#">
                        <PhoneOutlined style={{ fontSize: 18 }} />{' '}
                      </a>
                      <a href="!#">
                        <MailOutlined style={{ fontSize: 18 }} />{' '}
                      </a>
                    </Space>
                  }>
                  <Row gutter={12}>
                    <Col span={16}>
                      <Typography.Text strong>Name</Typography.Text>
                      <p className="mt-1">{getApplicantFullName(primaryApplicant.userInfo)}</p>
                    </Col>
                    <Col span={8}>
                      {primaryApplicant.creditReports.length ? (
                        <div style={{ height: 50 }}>
                          <Typography.Text strong>Credit Score</Typography.Text>
                          <p className="mt-1">
                            {primaryApplicant.creditReports[
                              primaryApplicant.creditReports.length - 1
                            ]?.creditScore
                              ? primaryApplicant.creditReports[
                                  primaryApplicant.creditReports.length - 1
                                ]?.creditScore
                              : primaryApplicant.depictedCreditHistory}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <Typography.Text strong>Depicted CR</Typography.Text>
                          <p className="mt-1">{primaryApplicant.depictedCreditHistory}</p>
                        </div>
                      )}

                      {/* <p className='mt-2'>{application.applicants[0].depictedCreditHistory}</p> */}
                    </Col>
                  </Row>
                </Card>
              </Col>
              {application.applicants.length > 1
                ? secondaryApplicants.map((secApplicant) => (
                    <Col span={6} key={secApplicant._id}>
                      <Card
                        title="Secondary Applicant"
                        bodyStyle={{ padding: 20 }}
                        className="rounded-md shadow"
                        extra={
                          <Space size="large">
                            <Tooltip placement="bottom" title="Make Primary">
                              <Popconfirm
                                title="Are you sure to make applicant Primary?"
                                onConfirm={() => onMakeApplicantPrimary(secApplicant._id)}>
                                <a href="!#">
                                  <SwapOutlined style={{ fontSize: 18 }} />{' '}
                                </a>
                              </Popconfirm>
                            </Tooltip>
                            <a href="!#">
                              <PhoneOutlined style={{ fontSize: 18 }} />{' '}
                            </a>
                            <a href="!#">
                              <MailOutlined style={{ fontSize: 18 }} />{' '}
                            </a>
                            <Tooltip placement="bottom" title="Delete">
                              <Popconfirm
                                title="Are you sure to delete applicant?"
                                onConfirm={() => onDeleteApplicant(secApplicant._id)}>
                                <a href="!#">
                                  <DeleteOutlined
                                    className="text-red-600"
                                    style={{ fontSize: 18 }}
                                  />{' '}
                                </a>
                              </Popconfirm>
                            </Tooltip>
                          </Space>
                        }>
                        <Row gutter={12}>
                          <Col span={16}>
                            <Typography.Text strong>Name</Typography.Text>
                            <p className="mt-1">{getApplicantFullName(secApplicant.userInfo)}</p>
                          </Col>

                          <Col span={8}>
                            {secApplicant.creditReports.length ? (
                              <div style={{ height: 50 }}>
                                <Typography.Text strong>Credit Score</Typography.Text>
                                <p className="mt-1">
                                  {secApplicant.creditReports[secApplicant.creditReports.length - 1]
                                    ?.creditScore
                                    ? secApplicant.creditReports[
                                        secApplicant.creditReports.length - 1
                                      ]?.creditScore
                                    : secApplicant.depictedCreditHistory}
                                </p>
                              </div>
                            ) : (
                              <div>
                                <Typography.Text strong>Depicted CR</Typography.Text>
                                <p className="mt-1">{secApplicant.depictedCreditHistory || '--'}</p>
                              </div>
                            )}

                            {/* <p className='mt-2'>{application.applicants[1].depictedCreditHistory}</p> */}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))
                : null}

              <Col span={application.applicants.length > 1 ? 12 : 18}>
                <Card
                  title="Loan Details"
                  extra={
                    <Button
                      type="link"
                      icon={<BiPencil className="text-xl" />}
                      onClick={showLoanDetailsModal}
                    />
                  }
                  className="rounded-md shadow"
                  bodyStyle={{ padding: 20 }}>
                  <Row gutter={12}>
                    {application.domain === Domain.Car ? (
                      <CarLoanFields application={application} />
                    ) : (
                      <HomeLoanFields application={application} />
                    )}
                    <Col span={3}>
                      <Typography.Text strong>Loan Term</Typography.Text>
                      <p className="mt-1">{application.loanTermYears} Yrs</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div className="bg-white border-y mx-2 mb-3">
        <div className="breadcrumb flat">
          {APPLICATION_STATUSES.map((st) => (
            <Link
              key={st}
              className={
                APPLICATION_STATUSES_SEQUENCE[application?.status]?.includes(st) ? 'active' : null
              }
              onClick={() => onFieldChange('status', st)}>
              {st}
            </Link>
          ))}
        </div>
      </div>
      <Layout>
        <ApplicationSider
          isCollapsed={isSiderCollapsed}
          setCollapsed={setIsSiderCollapsed}
          data={{ resource: 'applications', resourceId: applicationId }}
        />
        <Layout.Content>
          <div className="bg-white rounded-md shadow py-1 mx-2" style={{ minHeight: 360 }}>
            <Menu mode="horizontal" selectedKeys={selectedKey}>
              {!props.isWidget && (
                <Menu.Item key="requirements">
                  <Link to={`${getRoutePrefix()}/applications/${applicationId}/requirements`}>
                    Requirements
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item key="applicants">
                <Link
                  to={`${getRoutePrefix()}/applications/${applicationId}/applicants/${application.applicants[0]._id}/personal`}>
                  Applicants
                </Link>
              </Menu.Item>
              {application?.domain !== Domain.Car ? (
                <Menu.Item key="property">
                  <Link to={`${getRoutePrefix()}/applications/${applicationId}/properties`}>
                    Property Details
                  </Link>
                </Menu.Item>
              ) : (
                <Menu.Item key="vehicle">
                  <Link to={`${getRoutePrefix()}/applications/${applicationId}/vehicles`}>
                    Vehicle Details
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item key="income">
                <Link to={`${getRoutePrefix()}/applications/${applicationId}/incomes`}>
                  Incomes
                </Link>
              </Menu.Item>
              <Menu.Item key="expense">
                <Link to={`${getRoutePrefix()}/applications/${applicationId}/expenses`}>
                  Expenses
                </Link>
              </Menu.Item>
              <Menu.Item key="assets">
                <Link to={`${getRoutePrefix()}/applications/${applicationId}/assets`}>
                  Assets &amp; Liabilities
                </Link>
              </Menu.Item>
              <Menu.Item key="compliance">
                <Link to={`${getRoutePrefix()}/applications/${applicationId}/compliance`}>
                  Compliance
                </Link>
              </Menu.Item>
              <Menu.Item key="credit-report">
                <Link to={`${getRoutePrefix()}/applications/${applicationId}/credit-reports`}>
                  Credit Report
                </Link>
              </Menu.Item>
              <Menu.Item key="documents">
                <Link to={`${getRoutePrefix()}/applications/${applicationId}/documents`}>
                  Documents
                </Link>
              </Menu.Item>
              <Menu.Item key="quotes">
                <Link to={`${getRoutePrefix()}/applications/${applicationId}/quotes`}>Quotes</Link>
              </Menu.Item>
              {!props.isWidget && (
                <>
                  {/* <Menu.Item key="notes">
                  <Link to={`${getRoutePrefix()}/applications/${applicationId}/timeline`}>Timeline</Link>
                </Menu.Item> */}
                  <Menu.Item key="loan-details">
                    <Link to={`${getRoutePrefix()}/applications/${applicationId}/loan-details`}>
                      Loan Details
                    </Link>
                  </Menu.Item>
                </>
              )}
              <Menu.Item key="settlement">
                <Link to={`${getRoutePrefix()}/applications/${applicationId}/settlement`}>
                  Settlement
                </Link>
              </Menu.Item>
            </Menu>
            <div style={{ padding: '12px 0' }}>{props.children}</div>
            <Modal
              open={isStatusModalVisible}
              title="Are you sure to do this action?"
              onCancel={() => setIsStatusModalVisible(false)}
              closable
              onOk={form.submit}>
              <Form form={form} layout="vertical" onFinish={onWithdrawnReasonSubmit}>
                <div className="text-center">
                  <ExclamationCircleOutlined className="text-4xl mb-4 text-yellow-500" />
                  <Typography.Title level={4}>Withdraw Application</Typography.Title>
                  <Typography.Text>
                    Are you sure you want to withdraw the application?
                  </Typography.Text>

                  <div className="mt-4">
                    <Form.Item
                      name="statusReason"
                      label={
                        <Typography.Text strong className="text-center">
                          Withdrawl Reason
                        </Typography.Text>
                      }
                      rules={[{ required: true, message: 'Status is required' }]}>
                      <Select onChange={onWithdrawlReasonChange}>
                        {WITHDRAW_REASONS.map((w) => (
                          <Select.Option key={w} value={w}>
                            {w}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  {withdrawlReason === WITHDRAW_REASON_OTHER ? (
                    <Form.Item
                      name="statusReasonOther"
                      label="Other Reason"
                      rules={[{ required: true, message: 'Other reason is required' }]}>
                      <Input.TextArea rows={3} placeholder="Specify the reason here" />
                    </Form.Item>
                  ) : null}
                </div>
              </Form>
            </Modal>
            <LoanDetailsModal
              isLoanDetailsModalOpen={isLoanDetailsModalOpen}
              hideLoanDetailsModal={hideLoanDetailsModal}
              loanDetailsForm={loanDetailsForm}
              afterSubmissionCallback={() => setIsLoanDetailsModalOpen(false)}
              application={application}
              mutationUrl={url}
            />
          </div>
        </Layout.Content>
      </Layout>
    </>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default Application;
