import { Pie } from '@ant-design/plots';

function LoansByLenderChart() {
  const data = [
    {
      lender: 'Plenti',
      value: 27
    },
    {
      lender: 'Pepper',
      value: 25
    },
    {
      lender: 'Firstmac',
      value: 18
    },
    {
      lender: 'Grow',
      value: 15
    },
    {
      lender: 'Liberty',
      value: 10
    },
    {
      lender: 'Wisr',
      value: 5
    }
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'lender',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}'
    },
    interactions: [
      {
        type: 'pie-legend-active'
      },
      {
        type: 'element-active'
      }
    ]
  };
  return <Pie {...config} />;
}

export default LoansByLenderChart;
