import { Button, Card, Modal, Skeleton, Space } from 'antd';
import { memo, useState } from 'react';
import ComplianceBanner from './ComplianceBanner';
import { useParams } from 'react-router-dom';
import { API_HOST } from '../../../helpers/constants';
import useSWR from 'swr';
import { fetcher, headers } from '../../../utils';
import axios from 'axios';
import { openNotification } from '../../../helpers';
import clsx from 'clsx';
const fallbackComplianceDocsRequests = [
  {
    docs: [
      {
        type: 'Privacy Form'
      },

      {
        type: 'Credit Guide'
      },
      {
        type: 'Credit Quote'
      }
    ]
  }
];

function ComplianceTab() {
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application, mutate } = useSWR(applicationUrl, fetcher);
  const [isGeneratingDocs, setIsGeneratingDocs] = useState(false);
  const [viewingDoc, setViewingDoc] = useState(null);
  const getDocumentFetchUrl = (key) => `${API_HOST}/files?key=${key}`;

  const generateComplianceDocs = async ({ shouldSendEmail, applicantId }) => {
    let endpoint = `${API_HOST}/applications/${applicationId}/applicants/${applicantId}/compliance-docs`;
    if (shouldSendEmail) endpoint = `${endpoint}?${new URLSearchParams({ shouldSendEmail })}`;
    try {
      setIsGeneratingDocs(true);
      await axios.post(endpoint, null, { headers });
      setIsGeneratingDocs(false);
      openNotification(`Generated${shouldSendEmail ? ' and emailed' : ''} compliance docs`);
      mutate();
    } catch (error) {
      console.log(error);
      setIsGeneratingDocs(false);
      openNotification('Failed to generate compliance docs', '', true);
    }
  };

  const onViewClick = async (doc) => {
    try {
      const res = await axios.get(getDocumentFetchUrl(doc.key), { headers });
      setViewingDoc({ ...doc, accessUrl: res.data.url });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setViewingDoc(null);
  };

  if (!application) return <Skeleton />;

  const { applicants } = application;
  return (
    <>
      <div className="px-6 pt-2">
        {applicants.map(
          ({ _id, userInfo, complianceDocsRequests = fallbackComplianceDocsRequests }) => (
            <Card
              type="inner"
              title={`${userInfo.firstName} ${userInfo.lastName || ''}`}
              key={_id}
              extra={
                <Space>
                  <Button
                    type="primary"
                    className="!font-semibold"
                    loading={isGeneratingDocs}
                    onClick={() =>
                      generateComplianceDocs({ shouldSendEmail: true, applicantId: _id })
                    }>
                    Generate Compliance Docs & Email
                  </Button>
                  <Button
                    type="link"
                    className="!font-semibold"
                    loading={isGeneratingDocs}
                    onClick={() =>
                      generateComplianceDocs({ shouldSendEmail: false, applicantId: _id })
                    }>
                    Generate Only
                  </Button>
                </Space>
              }>
              <div className="grid gap-y-4">
                {complianceDocsRequests[0].docs.map((doc) => (
                  <ComplianceBanner key={doc._id} complianceDoc={doc} onViewClick={onViewClick} />
                ))}
              </div>
            </Card>
          )
        )}
      </div>
      {Boolean(viewingDoc) && (
        <Modal
          title={viewingDoc?.type}
          open={Boolean(viewingDoc)}
          width={900}
          style={{ top: 20 }}
          cancelText="Close"
          onCancel={handleCancel}
          okButtonProps={{ hidden: true }}>
          <div className="max-h-[600px] overflow-hidden pr-3">
            <iframe
              src={viewingDoc?.accessUrl}
              height="580"
              width="100%"
              className={clsx({ 'border p-4 bg-slate-100': !viewingDoc?.signedAt })}></iframe>
          </div>
        </Modal>
      )}
    </>
  );
}

export default memo(ComplianceTab);
