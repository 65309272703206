/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import {
  DownOutlined,
  MailOutlined,
  MessageOutlined,
  MobileOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import {
  Badge,
  Breadcrumb,
  Col,
  Layout,
  Row,
  Select,
  Typography,
  Button,
  Space,
  Dropdown,
  Divider,
  Modal,
  Form,
  Input,
  Radio
} from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import dayjs from 'dayjs';
import axios from 'axios';
import { BiNotepad, BiPencil, BiTask } from 'react-icons/bi';
import { getApplicantFullName, openNotification } from '../../helpers';
import {
  API_HOST,
  LEAD_STATUSES_SEQUENCE,
  STATUS_CONTACTED,
  STATUS_INTERESTED,
  STATUS_LOST,
  STATUS_NEW,
  STATUS_QUALIFIED,
  STATUS_UNQUALIFIED,
  STATUS_WON
} from '../../helpers/constants';
import { fetcher, getDomainIcon, headers } from '../../utils';
import { Domain } from '../../types';
import AccountListWidget from '../accounts/AccountsListWidget';
import HomeLoanFields from '../application/HomeLoanFields';
import CarLoanFields from '../application/CarLoanFields';
import LoanDetailsModal from '../application/LoanDetailsModal';
import ApplicationSider from '../common/ApplicationSider';

const { Content } = Layout;

function LeadLayout({ children }) {
  const [collapsed, setCollapsed] = useState(true);
  const [accountMode, setAccountMode] = useState();
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const { leadId } = useParams();
  const [lead, setLead] = useState(null);
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [shouldShowLoanDetailsModal, setShouldShowLoanDetailsModal] = useState(false);
  const [loanDetailsForm] = Form.useForm();
  const [leadReasons, setLeadReasons] = useState([]);
  const [accountForm] = Form.useForm();
  const [reasonForm] = Form.useForm();
  const url = `${API_HOST}/leads/${leadId}`;
  const leadReasonsUrl = `${API_HOST}/lead-reasons?limit=1000`;
  const tasksUrl = `${API_HOST}/tasks?lead=${leadId}`;
  const usersUrl = `${API_HOST}/users?role[]=broker&role[]=admin&limit=1000`;
  const [isUnqualifiedOrLostReasonModalVisible, setIsUnqualifiedOrLostReasonModalVisible] =
    useState(false);

  const { data: responseData } = useSWR(leadId ? url : null, fetcher);
  const { data: userResponseData } = useSWR(usersUrl, fetcher);
  const { data: leadStatusResponseData } = useSWR(leadReasonsUrl, fetcher);
  const { data: tasksData } = useSWR(tasksUrl, fetcher);

  useEffect(() => {
    if (responseData) {
      setLead(responseData);
      loanDetailsForm.setFieldsValue(responseData.application);
    }
  }, [responseData]);

  useEffect(() => {
    if (userResponseData) {
      setUsers(userResponseData.data);
    }
  }, [userResponseData]);

  useEffect(() => {
    if (leadStatusResponseData) {
      setLeadReasons(leadStatusResponseData.data);
    }
  }, [leadStatusResponseData]);

  useEffect(() => {
    if (tasksData) {
      setTasks(tasksData.data);
    }
  }, [tasksData]);

  const showUnqualifiedOrLostReasonModal = (value) => {
    reasonForm.setFieldValue('status', value);
    setIsUnqualifiedOrLostReasonModalVisible(true);
  };

  const onFieldChange = async (field, value) => {
    if (field === 'status') {
      if (value === STATUS_WON) {
        setIsAccountModalVisible(true);
        return;
      }
      if ([STATUS_UNQUALIFIED, STATUS_LOST].includes(value)) {
        showUnqualifiedOrLostReasonModal(value);
        return;
      }
    }
    try {
      await axios.patch(url, { [field]: value }, { headers });
      openNotification('Success', 'Updated!');
      mutate(url);
    } catch (e) {
      openNotification('Failure', 'Sorry, could not update', true);
      console.log(e.message);
    }
  };

  const onAccountSelect = (accountId) => {
    accountForm.setFieldValue('accountId', accountId);
  };

  const onAccountFormSubmit = async (values) => {
    let accountId;
    try {
      if (values.mode === 'new') {
        const { data } = await axios.post(
          `${API_HOST}/accounts/from-lead`,
          { userId: values.user },
          { headers }
        );
        accountId = data._id;
      } else {
        accountId = values.accountId;
        await axios.patch(`${API_HOST}/leads/${leadId}`, { accountId }, { headers });
      }
      await axios.put(url, { status: STATUS_WON, account: accountId }, { headers });
      mutate(url);
      accountForm.resetFields();
      setIsAccountModalVisible(false);
      openNotification('Success', 'Status Updated!');
    } catch (e) {
      openNotification('Failure', 'Sorry, status could not be updated!', true);
      console.log(e);
    }
  };
  const onReasonFormSubmit = async (values) => {
    try {
      await axios.put(url, { status: values.status, reason: values.reason }, { headers });
      mutate(url);
      accountForm.resetFields();
      setIsUnqualifiedOrLostReasonModalVisible(false);
      openNotification('Success', 'Updated!');
    } catch (e) {
      openNotification('Failure', 'Sorry, could not be updated!', true);
      console.log(e);
    }
  };

  return (
    <div>
      <div className="block lead">
        <Breadcrumb className="mt-0 mb-2">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/leads">Leads</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{lead?.shortId}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <Badge.Ribbon
            text={lead?.application?.type}
            placement="start"
            style={{ marginTop: '-13px' }}
          />
          <div className="bg-white pt-2 pb-6 px-4">
            <Row gutter={24}>
              <Col span={6}>
                <p className="pt-4 mb-2 text-2xl">
                  {lead ? getApplicantFullName(lead?.application?.applicants[0].userInfo) : null}
                </p>
              </Col>
              <Col span={18}>
                <Row gutter={24} className="pt-2">
                  <Col span={7}>
                    <Space size="small">
                      <Button
                        icon={<MailOutlined />}
                        className="bg-gradient-to-b from-gray-50 to-gray-100">
                        Email
                      </Button>
                      <Button
                        icon={<PhoneOutlined />}
                        className="bg-gradient-to-b from-gray-50 to-gray-100">
                        Call
                      </Button>
                      <Button
                        icon={<MessageOutlined />}
                        className="bg-gradient-to-b from-gray-50 to-gray-100">
                        SMS
                      </Button>
                    </Space>
                  </Col>
                  <Col span={12}>
                    <Space size="small">
                      <Link to={`/leads/${leadId}/notes`}>
                        <Button
                          type="default"
                          className="bg-gradient-to-b from-gray-50 to-gray-100"
                          icon={<BiNotepad className="inline text-lg mr-2 -mt-1" />}>
                          <Space>
                            <span>Notes</span>
                            {lead?.notes?.length ? (
                              <Badge count={lead?.notes?.length} color="red" />
                            ) : null}
                          </Space>
                        </Button>
                      </Link>
                      <Link to={`/leads/${leadId}/tasks`}>
                        <Button
                          type="default"
                          className="bg-gradient-to-b from-gray-50 to-gray-100"
                          icon={<BiTask className="inline text-lg mr-2 -mt-1" />}>
                          <Space>
                            <span>Tasks</span>
                            {tasks?.length ? <Badge count={tasks?.length} color="red" /> : null}
                          </Space>
                        </Button>
                      </Link>
                    </Space>
                  </Col>
                  <div className="ml-auto mr-4">
                    <Button
                      type="default"
                      className="bg-gradient-to-b from-gray-50 to-gray-100"
                      icon={<BiPencil className="inline text-lg mr-2 -mt-1" />}
                      onClick={() => setShouldShowLoanDetailsModal(true)}>
                      <Space>
                        <span>Edit Loan Details</span>
                      </Space>
                    </Button>
                  </div>
                </Row>
              </Col>
            </Row>
            <Divider className="my-1" />
            <Row gutter={8} className="pt-3">
              <Col span={6}>
                <p className="mt-2 text-sm">
                  <MailOutlined />
                  {lead?.application?.applicants[0].userInfo.email ? (
                    <span className="ml-2">
                      <a
                        href={
                          lead?.application?.applicants[0].userInfo.email
                            ? `mailto:${lead?.application?.applicants[0].userInfo.email}`
                            : ''
                        }>
                        {lead?.application?.applicants[0].userInfo.email || '--'}
                      </a>
                    </span>
                  ) : (
                    <span className="ml-2">N/A</span>
                  )}
                </p>
                <p className="mt-1 text-sm">
                  <MobileOutlined />{' '}
                  <a href={`tel:${lead?.application?.applicants[0].userInfo.mobile}`}>
                    {lead?.application?.applicants[0].userInfo.mobile}
                  </a>
                </p>
              </Col>
              <Col span={18}>
                <Row className="mb-6">
                  <Col span={3}>
                    <Typography.Paragraph className="!mb-1" strong>
                      Domain
                    </Typography.Paragraph>
                    {getDomainIcon(lead?.application?.domain)}
                  </Col>
                  {lead &&
                    (lead.domain === Domain.Car ? (
                      <CarLoanFields application={lead.application} />
                    ) : (
                      <HomeLoanFields application={lead.application} />
                    ))}
                  <Col span={3}>
                    <Typography.Text strong>Loan Term</Typography.Text>
                    <p className="mt-1">{lead?.application.loanTermYears} Yrs</p>
                  </Col>
                </Row>
                <div className="flex justify-between">
                  <Col span={2}>
                    <Typography.Text strong>Usage</Typography.Text>
                    <p className="mt-1">
                      {lead?.domain === Domain.Home
                        ? lead?.application?.properties && lead?.application?.properties[0]?.usage
                        : lead?.application?.vehicles && lead?.application?.vehicles[0]?.usage}
                    </p>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>Created At</Typography.Text>
                    <p className="mt-1 min-w-max">
                      {dayjs(lead?.createdAt).format('DD/MM/YYYY hh:mm a')}
                    </p>
                  </Col>
                  <Col span={3}>
                    <Typography.Text strong>Credit Score</Typography.Text>
                    <p className="mt-1">{lead?.application?.applicants[0].depictedCreditHistory}</p>
                  </Col>

                  {/* <Col span={3} >
                <Typography.Text strong>Status</Typography.Text>
                <div className='mt-1'>
                  <Select value={lead?.status} style={{ width: '100%' }} onChange={(v) => onFieldChange('status', v)}
                    options={Object.keys(LEAD_STATUSES).map(k => ({ label: LEAD_STATUSES[k], value: k }))}
                  />
                </div>
              </Col> */}
                  <Col span={3}>
                    <Typography.Text strong>Source</Typography.Text>
                    <p className="mt-1">{lead?.source}</p>
                  </Col>
                  <Col span={5}>
                    <Typography.Text strong>Owner</Typography.Text>
                    <Select
                      value={lead?.owner}
                      onChange={(v) => onFieldChange('owner', v)}
                      className="w-11/12"
                      options={users?.map((u) => ({
                        label: `${u.firstName} ${u.lastName}`,
                        value: u._id
                      }))}
                    />
                  </Col>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="bg-white border-y">
          <div className="breadcrumb flat">
            <Link className="active" onClick={() => onFieldChange('status', STATUS_NEW)}>
              New
            </Link>
            <Link
              className={
                LEAD_STATUSES_SEQUENCE[lead?.status]?.includes(STATUS_CONTACTED) ? 'active' : null
              }
              onClick={() => onFieldChange('status', STATUS_CONTACTED)}>
              Contacted
            </Link>
            <Dropdown
              className="w-16"
              menu={{
                items: [
                  {
                    key: '1',
                    label: (
                      <Link onClick={() => onFieldChange('status', STATUS_INTERESTED)}>
                        Interested
                      </Link>
                    )
                  },
                  {
                    key: '2',
                    label: (
                      <Link onClick={() => onFieldChange('status', STATUS_UNQUALIFIED)}>
                        Unqualified
                      </Link>
                    )
                  }
                ]
              }}>
              <Link
                className={
                  LEAD_STATUSES_SEQUENCE[lead?.status]?.includes(STATUS_INTERESTED)
                    ? 'active'
                    : LEAD_STATUSES_SEQUENCE[lead?.status]?.includes(STATUS_UNQUALIFIED)
                      ? 'active_error'
                      : null
                }>
                <Space>
                  Interested/Unqualified
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
            <Link
              className={
                LEAD_STATUSES_SEQUENCE[lead?.status]?.includes(STATUS_QUALIFIED) ? 'active' : null
              }
              onClick={() => onFieldChange('status', STATUS_QUALIFIED)}>
              Qualified
            </Link>
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    label: <Link onClick={() => onFieldChange('status', STATUS_WON)}>Won</Link>
                  },
                  {
                    key: '2',
                    label: <Link onClick={() => onFieldChange('status', STATUS_LOST)}>Lost</Link>
                  }
                ]
              }}>
              <Link
                className={
                  LEAD_STATUSES_SEQUENCE[lead?.status]?.includes(STATUS_WON)
                    ? 'active_success'
                    : LEAD_STATUSES_SEQUENCE[lead?.status]?.includes(STATUS_LOST)
                      ? 'active_error'
                      : null
                }>
                <Space>
                  Won/Lost
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
          </div>
        </div>
      </div>
      <div>
        <Layout style={{ minHeight: '100vh' }}>
          <ApplicationSider
            isCollapsed={collapsed}
            setCollapsed={setCollapsed}
            data={{ resource: 'leads', resourceId: leadId }}
          />
          <Layout className="site-layout">
            <Content className="px-1 py-0 pb-10">{children}</Content>
            <Modal
              title="Account"
              open={isAccountModalVisible}
              onOk={accountForm.submit}
              onCancel={() => setIsAccountModalVisible(false)}>
              <Form form={accountForm} onFinish={onAccountFormSubmit} layout="vertical">
                <Form.Item name="mode" label="Name">
                  <Radio.Group
                    style={{ marginBottom: 8 }}
                    onChange={(e) => setAccountMode(e.target.value)}>
                    <Radio.Button value="new">New</Radio.Button>
                    <Radio.Button value="existing">Existing</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {accountMode === 'new' ? (
                  <Form.Item name="user" label="Applicant" rules={[{ required: true }]}>
                    <Select
                      options={lead?.application?.applicants.map(({ userInfo }) => ({
                        label: getApplicantFullName(userInfo),
                        value: userInfo._id
                      }))}
                    />
                  </Form.Item>
                ) : accountMode === 'existing' ? (
                  <>
                    <div className="hidden">
                      <Form.Item name="accountId" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                    </div>
                    <AccountListWidget onSelect={onAccountSelect} />
                  </>
                ) : null}
              </Form>
            </Modal>
            <Modal
              title="Unquailified or Lost Reason"
              open={isUnqualifiedOrLostReasonModalVisible}
              onOk={reasonForm.submit}
              onCancel={() => setIsUnqualifiedOrLostReasonModalVisible(false)}>
              <Form layout="vertical" onFinish={onReasonFormSubmit} form={reasonForm}>
                <div className="hidden">
                  <Form.Item name="status" label="Status">
                    <Input />
                  </Form.Item>
                </div>
                <Form.Item name="reason" label="Reason" rules={[{ required: true }]}>
                  <Select
                    options={leadReasons.map((lr) => ({ value: lr._id, label: lr.name }))}
                    size="large"
                  />
                </Form.Item>
              </Form>
            </Modal>
            {lead && (
              <LoanDetailsModal
                isLoanDetailsModalOpen={shouldShowLoanDetailsModal}
                hideLoanDetailsModal={() => setShouldShowLoanDetailsModal(false)}
                loanDetailsForm={loanDetailsForm}
                application={lead.application}
                afterSubmissionCallback={() => setShouldShowLoanDetailsModal(false)}
                mutationUrl={url}
              />
            )}
          </Layout>
        </Layout>
      </div>
    </div>
  );
}

export default LeadLayout;
