/* eslint-disable no-undef */
export const API_HOST = process.env.REACT_APP_API_HOST;
export const WEB_URL = process.env.REACT_APP_CRM_HOST;

export const defaultDateFormat = 'DD/MM/YYYY';
export const defaultDateTimeFormat = 'DD/MM/YYYY hh:mm a';

export const SAVING_ACCOUNT = 'Saving Account';
export const SUPERANNUATION = 'Superannuation';
export const SHARES = 'Shares';
export const MOTOR_VEHICLE = 'Motor Vehicle';
export const WATERCRAFT = 'Watercraft';
export const PROPERTY = 'Property';
export const TERM_DEPOSIT = 'Term Deposit';
export const MANAGED_FUNDS = 'Managed Funds';
export const HOME_CONTENTS = 'Home Contents';
export const TOOLS_EQUIPMENT = 'Tools & Equipment';

export const CREDIT_CARD = 'Credit Card';
export const STORE_CARD = 'Store Card';
export const CAR_LOAN = 'Car Loan';
export const PERSONAL_LOAN = 'Personal Loan';
export const HOME_LOAN = 'Home Loan';

export const ASSET = 'Asset';
export const LIABILITY = 'Liability';

export const assetTypes = [
  SAVING_ACCOUNT,
  SUPERANNUATION,
  SHARES,
  MOTOR_VEHICLE,
  WATERCRAFT,
  PROPERTY,
  TERM_DEPOSIT,
  MANAGED_FUNDS,
  HOME_CONTENTS,
  TOOLS_EQUIPMENT
];

export const liabilityTypes = [CREDIT_CARD, STORE_CARD, CAR_LOAN, PERSONAL_LOAN, HOME_LOAN];

export const OWNER_OCCUPIED = 'Owner Occupied';
export const INVESTMENT = 'Investment';
export const PROPERTY_USES = [OWNER_OCCUPIED, INVESTMENT];

export const NON_APPLICANT = 'Non-applicant';
export const APPLICANT_PRIMARY = 'Primary';
export const APPLICANT_SECONDARY = 'Secondary';

export const APPLICANT_TYPES = [APPLICANT_PRIMARY, APPLICANT_SECONDARY, NON_APPLICANT];

export const DRIVERS_LICENCE_FRONT = 'Driver Licence (Front)';
export const DRIVERS_LICENCE_BACK = 'Driver Licence (Back)';
export const MEDICARE_CARD = 'Medicare Card';
export const PAYSLIP1 = 'Payslip 1';
export const PAYSLIP2 = 'Payslip 2';
export const BANK_STATEMENTS = 'Bank Statements';
export const PASSPORT = 'Passport';
export const RESIDENCY_VISA_DOCS = 'Residency/Visa Doucments';
export const TAX_RETURN_INDIVIDUAL = 'Tax Return (Individual)';
export const COMPANY_FINANCIALS = 'Company Financials';
export const EMPLOYMENT_CONFIRMATION = 'Employment Confirmation';
export const PAYG_SUMMARY = 'PAYG Summary';
export const RENTAL_INCOME_STATEMENTS = 'Rental Income Statements';
export const SPOUSAL_INCOME_CONFIRMATION = 'Spousal Income Confirmation';
export const TENANCY_AGREEMENT = 'Tenancy Agreement';
export const PROOF_OF_ADDRESS = 'Proof of Address';

export const DOCUMENTS = [
  DRIVERS_LICENCE_FRONT,
  DRIVERS_LICENCE_BACK,
  MEDICARE_CARD,
  PAYSLIP1,
  PAYSLIP2,
  BANK_STATEMENTS,
  PASSPORT,
  RESIDENCY_VISA_DOCS,
  TAX_RETURN_INDIVIDUAL,
  COMPANY_FINANCIALS,
  EMPLOYMENT_CONFIRMATION,
  PAYG_SUMMARY,
  RENTAL_INCOME_STATEMENTS,
  SPOUSAL_INCOME_CONFIRMATION,
  TENANCY_AGREEMENT,
  PROOF_OF_ADDRESS
];

export const STATUS_NEW = 'New';
export const STATUS_STARTED = 'Started';
export const STATUS_AWAITING_DOCS = 'Awaiting Docs';
export const STATUS_READY_FOR_SUBMISSION = 'Ready For Submission';
export const STATUS_SUBMITTED_TO_LENDER = 'Submitted To Lender';
export const STATUS_APPROVED = 'Approved';
export const STATUS_CONDITIONALLY_APPROVED = 'Conditionally Approved';
export const STATUS_DECLINED = 'Declined';
export const STATUS_ADDITIONAL_INFO = 'Additional Info';
export const STATUS_PRE_SETTLEMENT = 'Pre-Settlement';
export const STATUS_SETTLED = 'Settled';
export const STATUS_WITHDRAWN = 'Withdrawn';

export const STATUS_CONTACTED = 'Contacted';
export const STATUS_INTERESTED = 'Interested';
export const STATUS_UNQUALIFIED = 'Unqualified';
export const STATUS_QUALIFIED = 'Qualified';
export const STATUS_WON = 'Won';
export const STATUS_LOST = 'Lost';

export const APPLICATION_STATUSES = [
  STATUS_NEW,
  STATUS_STARTED,
  STATUS_AWAITING_DOCS,
  STATUS_READY_FOR_SUBMISSION,
  STATUS_SUBMITTED_TO_LENDER,
  STATUS_APPROVED,
  STATUS_CONDITIONALLY_APPROVED,
  STATUS_DECLINED,
  STATUS_ADDITIONAL_INFO,
  STATUS_PRE_SETTLEMENT,
  STATUS_SETTLED,
  STATUS_WITHDRAWN
];

export const WITHDRAW_REASON_APPROVAL_EXPIRY = 'Approval Expiry';
export const WITHDRAW_REASON_NOT_INTERESTED = 'Not Interested';
export const WITHDRAW_REASON_STOPPED_RESPONDING = 'Stopped Responding';
export const WITHDRAW_REASON_LOST_TO_LENDER = 'Lost to lender';
export const WITHDRAW_REASON_INSUFFICIENT_APPROVAL_AMOUNT = 'Insufficient Approval Amount';
export const WITHDRAW_REASON_OTHER = 'Other';

export const WITHDRAW_REASONS = [
  WITHDRAW_REASON_APPROVAL_EXPIRY,
  WITHDRAW_REASON_NOT_INTERESTED,
  WITHDRAW_REASON_STOPPED_RESPONDING,
  WITHDRAW_REASON_LOST_TO_LENDER,
  WITHDRAW_REASON_INSUFFICIENT_APPROVAL_AMOUNT,
  WITHDRAW_REASON_OTHER
];

export const PROPERTY_TYPE_TOWNHOUSE = 'Town House';
export const PROPERTY_TYPE_FULLY_DETATCHED_HOME = 'Fully Detatched Home';
export const PROPERTY_TYPE_APARTMENT = 'Apartment';
export const PROPERTY_TYPE_VILLA = 'Villa';
export const PROPERTY_TYPE_LAND = 'Land';

export const PROPERTY_TYPES = [
  PROPERTY_TYPE_TOWNHOUSE,
  PROPERTY_TYPE_FULLY_DETATCHED_HOME,
  PROPERTY_TYPE_APARTMENT,
  PROPERTY_TYPE_VILLA,
  PROPERTY_TYPE_LAND
];

export const TASK_TYPES = ['call', 'email', 'meet', 'message', 'other'];
export const TASK_PRIORITIES = ['highest', 'high', 'medium', 'loe', 'lowest'];
export const TASK_STATUSES = ['todo', 'in-progress', 'blocked', 'done'];

export const LEAD_STATUSES = [
  STATUS_NEW,
  STATUS_CONTACTED,
  STATUS_INTERESTED,
  STATUS_UNQUALIFIED,
  STATUS_QUALIFIED,
  STATUS_WON,
  STATUS_LOST
];

export const LEAD_STATUSES_SEQUENCE = {
  [STATUS_NEW]: [STATUS_NEW],
  [STATUS_CONTACTED]: [STATUS_NEW, STATUS_CONTACTED],
  [STATUS_INTERESTED]: [STATUS_NEW, STATUS_CONTACTED, STATUS_INTERESTED],
  [STATUS_UNQUALIFIED]: [STATUS_NEW, STATUS_CONTACTED, STATUS_UNQUALIFIED],
  [STATUS_QUALIFIED]: [STATUS_NEW, STATUS_CONTACTED, STATUS_INTERESTED, STATUS_QUALIFIED],
  [STATUS_WON]: [STATUS_NEW, STATUS_CONTACTED, STATUS_INTERESTED, STATUS_QUALIFIED, STATUS_WON],
  [STATUS_LOST]: [STATUS_NEW, STATUS_CONTACTED, STATUS_INTERESTED, STATUS_QUALIFIED, STATUS_LOST]
};

export const APPLICATION_STATUSES_SEQUENCE = {
  [STATUS_NEW]: [STATUS_NEW],
  [STATUS_STARTED]: [STATUS_NEW, STATUS_STARTED],
  [STATUS_AWAITING_DOCS]: [STATUS_NEW, STATUS_STARTED, STATUS_AWAITING_DOCS],
  [STATUS_READY_FOR_SUBMISSION]: [
    STATUS_NEW,
    STATUS_STARTED,
    STATUS_AWAITING_DOCS,
    STATUS_READY_FOR_SUBMISSION
  ],
  [STATUS_SUBMITTED_TO_LENDER]: [
    STATUS_NEW,
    STATUS_STARTED,
    STATUS_AWAITING_DOCS,
    STATUS_READY_FOR_SUBMISSION,
    STATUS_SUBMITTED_TO_LENDER
  ],
  [STATUS_APPROVED]: [
    STATUS_NEW,
    STATUS_STARTED,
    STATUS_AWAITING_DOCS,
    STATUS_READY_FOR_SUBMISSION,
    STATUS_SUBMITTED_TO_LENDER,
    STATUS_APPROVED
  ],
  [STATUS_CONDITIONALLY_APPROVED]: [
    STATUS_NEW,
    STATUS_STARTED,
    STATUS_AWAITING_DOCS,
    STATUS_READY_FOR_SUBMISSION,
    STATUS_SUBMITTED_TO_LENDER,
    STATUS_CONDITIONALLY_APPROVED
  ],
  [STATUS_DECLINED]: [
    STATUS_NEW,
    STATUS_STARTED,
    STATUS_AWAITING_DOCS,
    STATUS_READY_FOR_SUBMISSION,
    STATUS_SUBMITTED_TO_LENDER,
    STATUS_DECLINED
  ],
  [STATUS_ADDITIONAL_INFO]: [
    STATUS_NEW,
    STATUS_STARTED,
    STATUS_AWAITING_DOCS,
    STATUS_READY_FOR_SUBMISSION,
    STATUS_SUBMITTED_TO_LENDER,
    STATUS_ADDITIONAL_INFO
  ]
};

export const disableRevalidation = {
  revalidateIfStale: false,
  revalidateOnFocus: false
};

export const BROKERAGE_AND_ORIGINATION_INVOICE = 'Brokerage and Origination Invoice';
export const SIGNED_FINANCE_CONTRACT = 'Signed Finance Contract';
export const INSPECTION_REPORT = 'Inspection Report';
export const DEALER_INVOICE = 'Dealer Invoice';

export const SETTLEMENT_DOCUMENTS = [
  BROKERAGE_AND_ORIGINATION_INVOICE,
  SIGNED_FINANCE_CONTRACT,
  INSPECTION_REPORT,
  DEALER_INVOICE
];

export const SETTLEMENT_STATUS_IN_PROGRESS = 'In Progress';
export const SETTLEMENT_STATUS_AWAITING_DOCS = 'Awaiting Docs';
export const SETTLEMENT_STATUS_SETTLED = 'Settled';
export const SETTLEMENT_STATUSES = [
  SETTLEMENT_STATUS_IN_PROGRESS,
  SETTLEMENT_STATUS_AWAITING_DOCS,
  SETTLEMENT_STATUS_SETTLED
];
