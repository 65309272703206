import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import * as slugify from 'slugify';
import TagInput from 'antd-tag-input';
import { openNotification } from '../../helpers';
import { Actions } from '../../types';
import { useBlog } from '../../hooks/useBlog';
import { createBlog, updateBlog } from '../../services/blogs.http';

function BlogForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );

  const { id } = params;

  const { blog } = useBlog(id);

  useEffect(() => {
    if (blog) {
      form.setFieldsValue(blog);
    }
  }, [blog]);

  const onSlugifyClick = () => {
    const slug = slugify(form.getFieldValue('name'), {
      lower: true
    });
    form.setFieldsValue({ slug });
  };

  const onSubmit = async (values) => {
    try {
      const { data } =
        mode === Actions.add ? await createBlog(values) : await updateBlog(id, values);
      navigate(`/blogs/${data._id}/edit`);
      setMode(Actions.edit);
      openNotification('Success', 'Blog saved successfully!');
    } catch (error) {
      openNotification('Error', 'Blog saving failed!', true);
      console.log(error);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/blogs">Blogs</Link> },
          { title: 'Blog' }
        ]}
      />
      <Typography.Title level={4}>Blog</Typography.Title>

      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <div className="bg-white p-5">
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Button onClick={onSlugifyClick} className="mb-4">
                Fill Slug
              </Button>
              <Form.Item label="Slug" name="slug" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Active" name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div className="bg-white mt-4 p-5 shadow-lg rounded-md">
              <h2 className="text-xl">Meta information</h2>
              <Form.Item label="Title" name="metaTitle">
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Description" name="metaDescription">
                <Input.TextArea size="large" rows={3} />
              </Form.Item>
              <Form.Item label="Keywords" name="metaKeywords">
                <TagInput
                  value={metaKeywords}
                  placeholder="please fill in..."
                  onChange={(strArr) => setMetaKeywords(strArr)}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={12}>
            <Button htmlType="submit" type="primary" size="large">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default BlogForm;
