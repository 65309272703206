/* eslint-disable react/prop-types */
import { Badge, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { getApplicantFullName } from '../../../helpers';
import { getDomainIcon, getShortId, toCurrency } from '../../../utils';

function LeadStatusCard({ lead }) {
  return (
    <div className="p-2 bg-white">
      <div>
        <Link to={`/leads/${lead._id}`}>
          {getDomainIcon(lead.application?.domain)}
          <span className="font-bold ml-2">
            {getApplicantFullName(lead?.application?.applicants[0]?.userInfo)}
          </span>
        </Link>

        <div className="text-gray-500 mt-2">
          <Row gutter={8}>
            <Col span={12} className="text-xs">
              Owner
            </Col>
            {/* <Col span={12} className='text-xs text-right'>{getApplicantFullName(lead.owner)} </Col> */}
          </Row>
          <Row gutter={8}>
            <Col span={12} className="text-xs">
              Pur Price
            </Col>
            <Col span={12} className="text-xs text-right">
              {toCurrency(lead?.application.purchasePrice)}
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12} className="text-xs">
              Deposit
            </Col>
            <Col span={12} className="text-xs text-right">
              {toCurrency(lead?.application.depositAmount)}
            </Col>
          </Row>
        </div>
      </div>
      <hr className="my-1" />
      <Row gutter={2}>
        <Col span={12}>
          <Badge count="Finance" style={{ background: '#108ee9' }} />
        </Col>
        <Col span={12} className="text-right">
          <span className="text-gray-400 text-xs">{getShortId(lead._id)}</span>
        </Col>
      </Row>
    </div>
  );
}

export default LeadStatusCard;
