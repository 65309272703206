import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Input, Row, Space, Table, Tag, Typography } from 'antd';
import { useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { currency, getApplicantFullName } from '../../helpers';
import { getApplicationStatusColor, getDomainIcon } from '../../utils';
import { LEAD_APPLICATION_TYPE_FINANCE } from '../../types';
import { useApplications } from '../../hooks/useApplications';
import {
  APPLICATION_STATUSES,
  STATUS_NEW,
  STATUS_SETTLED,
  STATUS_WITHDRAWN
} from '../../helpers/constants';
function ApplicationsList() {
  const defaultStatuses = APPLICATION_STATUSES.filter((status) => {
    return status !== STATUS_NEW && status !== STATUS_SETTLED && status !== STATUS_WITHDRAWN;
  });

  const [allParams, setAllParams] = useState({
    take: 10,
    skip: 0,
    'filters[status]': defaultStatuses
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAllParams({ ...allParams, [`filters[${dataIndex}]`]: selectedKeys[0] });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const { applications, meta, isLoading, isError } = useApplications(allParams);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined
        }}
      />
    ),
    // onFilter: (value, record) => {
    //   if (value) {
    //     return record.status.indexOf(value) === 0;
    //   }
    // },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const getColumnSortProps = (column, sortOrder) => {
    setAllParams({
      ...allParams,
      sortBy: sortOrder === 'descend' ? `-${column}` : column
    });
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'filter' || extra.action === 'sort') {
      const statusArr = `${filters.status}`.split(',');
      setAllParams({ ...allParams, 'filters[status]': statusArr });
    }
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'shortId',
      key: 'shortId',
      width: '5%',
      sorter: (a, b, sortOrder) => getColumnSortProps('shortId', sortOrder),
      ...getColumnSearchProps('shortId'),
      render: (text, row) => (
        <Link to={`/applications/${row._id}`}>
          {getDomainIcon(row.domain)}
          {text}
        </Link>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b, sortOrder) => getColumnSortProps('name', sortOrder),
      ...getColumnSearchProps('name'),
      render: (text, row) => {
        return (
          <>
            <Link to={`/applications/${row._id}/requirements`}>
              <strong>{getApplicantFullName(row?.applicants[0]?.userInfo)}</strong>
            </Link>
            <p className="text-gray-500 text-xs">{row.applicants[0]?.userInfo.mobile}</p>
            <p className="text-gray-500 text-xs">{row.applicants[0]?.userInfo.email}</p>
          </>
        );
      }
    },
    {
      title: 'Details',
      width: '200',
      dataIndex: ['properties', 0, 'purchasePrice'],
      key: 'purchasePrice',
      render: (text, row) => {
        if (row.type === LEAD_APPLICATION_TYPE_FINANCE) {
          return (
            <>
              <h3 className="mb-0 font-bold">{row.type}</h3>
              <Row gutter={24}>
                <Col span={12}>
                  <p>
                    <span className="">Purchase Price</span>
                    <br />
                    {currency(row?.purchasePrice)}
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    <span className="font-semibold">Deposit Amount</span>
                    <br />
                    {currency(row?.depositAmount)}
                  </p>
                </Col>
              </Row>
            </>
          );
        }
        return (
          <Row gutter={24}>
            <Col span={12}>
              <p>
                <strong>Current Loan</strong>
                <br />
                {currency(row.currentLoanAmount)}
              </p>
            </Col>
            <Col span={12}>
              <p>
                <strong>Current Interest (%)</strong>
                <br />
                {`${row.currentInterestRate}%`}
              </p>
            </Col>
          </Row>
        );
      }
    },
    {
      title: 'Owner',
      dataIndex: ['owner', 'firstName'],
      key: 'owner',
      render: (text, row) => [row.owner?.firstName, row.owner?.lastName].join(' ')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: APPLICATION_STATUSES.map((status) => ({
        text: status,
        value: status
      })),
      onFilter: () => true,
      defaultFilteredValue: defaultStatuses,
      render: (text) => <Tag color={getApplicationStatusColor(text)}>{text}</Tag>
    },
    {
      title: 'No. of Applicants',
      dataIndex: 'numApplicants',
      render: (text) => (text === '1' ? 'Single' : 'Joint')
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, row) => (
        <Space size="middle">
          <Link to={`/applications/${row._id}`}>Edit</Link>
        </Space>
      )
    }
  ];

  return (
    <div>
      <Typography.Title level={3} className="my-2">
        Application
      </Typography.Title>
      {isError && (
        <Alert type="error" description="Cannot fetch Applications" message="Error Loading" />
      )}
      <Table
        dataSource={applications}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        onChange={onTableChange}
        pagination={{
          total: meta?.total,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          }
        }}
        footer={() => (
          <Typography.Title level={5}>Total Records: {meta?.total || '--'}</Typography.Title>
        )}
      />
    </div>
  );
}

export default ApplicationsList;
