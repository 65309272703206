import { useRef, useState } from 'react';
import { LEAD_STATUSES, STATUS_LOST, STATUS_WON } from '../../helpers/constants';
import { useLeads } from '../../hooks/useLeads';
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { currency, getDomainIcon, getLeadStatusColor } from '../../utils';
import { Link } from 'react-router-dom';
import { getApplicantFullName } from '../../helpers';
import dayjs from 'dayjs';
import { LEAD_APPLICATION_TYPE_FINANCE } from '../../types';

function LeadsList() {
  const defaultStatuses = LEAD_STATUSES.filter((status) => {
    return status !== STATUS_WON && status !== STATUS_LOST;
  });

  const [allParams, setAllParams] = useState({
    skip: 0,
    limit: 10,
    'filters[status]': defaultStatuses
  });

  const { leads, isLoading, isError, meta } = useLeads(allParams);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAllParams({ ...allParams, [`filters[${dataIndex}]`]: selectedKeys[0] });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined
        }}
      />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'filter' || extra.action === 'sort') {
      const statusArr = `${filters.status}`.split(',');
      setAllParams({ ...allParams, 'filters[status]': statusArr });
    }
  };

  const items = [
    {
      key: 'add-car-loan-lead',
      label: <Link to="/leads/car-loan/create">Car Loan</Link>
    },
    {
      key: 'add-homeloan-lead',
      label: <Link to="/leads/home-loan/create">Home Loan</Link>
    }
  ];

  const getColumnSortProps = (column, sortOrder) => {
    setAllParams({
      ...allParams,
      sortBy: sortOrder === 'descend' ? `-${column}` : column
    });
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'shortId',
      key: 'shortId',
      width: '5%',
      sorter: (a, b, sortOrder) => getColumnSortProps('shortId', sortOrder),
      ...getColumnSearchProps('shortId'),
      render: (text, row) => (
        <Link to={`/leads/${row._id}`}>
          {getDomainIcon(row.application?.domain)}
          <span className="text-xs">{text}</span>
        </Link>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b, sortOrder) => getColumnSortProps('name', sortOrder),
      ...getColumnSearchProps('term'),
      render: (text, row) => {
        return (
          <>
            <Link to={`/leads/${row._id}`}>
              {getApplicantFullName(row.application?.applicants[0]?.userInfo)}
            </Link>
            <p className="text-gray-500 text-xs">
              {row.application?.applicants[0]?.userInfo.mobile}
            </p>
          </>
        );
      }
    },
    {
      title: 'Details',
      width: '200',
      dataIndex: ['application', 'properties', 0, 'purchasePrice'],
      key: 'purchasePrice',
      render: (text, row) => {
        if (row.application.type === LEAD_APPLICATION_TYPE_FINANCE) {
          return (
            <>
              <h3 className="mb-0 font-bold">{row.application.type}</h3>
              <Row gutter={24}>
                <Col span={12}>
                  <p>
                    <span className="">Purchase Price</span>
                    <br />
                    {currency(row.application?.purchasePrice)}
                  </p>
                </Col>
                <Col span={12}>
                  <p>
                    <span className="font-semibold">Deposit Amount</span>
                    <br />
                    {currency(row.application?.depositAmount)}
                  </p>
                </Col>
              </Row>
            </>
          );
        }
        return (
          <Row gutter={24}>
            <Col span={12}>
              <p>
                <strong>Current Loan</strong>
                <br />
                {currency(row.application?.currentLoanAmount)}
              </p>
            </Col>
            <Col span={12}>
              <p>
                <strong>Current Interest (%)</strong>
                <br />
                {`${row.application?.currentInterestRate}%`}
              </p>
            </Col>
          </Row>
        );
      }
    },
    {
      title: 'Owner',
      dataIndex: ['owner', 'firstName'],
      key: 'owner',
      render: (text, row) => [row.owner?.firstName, row.owner?.lastName].join(' ')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: LEAD_STATUSES.map((status) => ({
        text: status,
        value: status
      })),
      onFilter: () => true,
      defaultFilteredValue: defaultStatuses,
      render: (text) => <Tag color={getLeadStatusColor(text)}>{text}</Tag>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '13%',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    },

    {
      title: 'Action',
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Link to={`/leads/${record._id}/edit`}>
              <EditOutlined className="text-lg" />{' '}
            </Link>
          </Tooltip>
        </Space>
      )
    }
  ];

  return (
    <div>
      <Breadcrumb
        className="mb-3"
        items={[{ title: <Link to="/">Home</Link> }, { title: 'Leads' }]}
      />
      <Typography.Title level={4}>Leads List</Typography.Title>
      <Dropdown menu={{ items }} placement="bottomLeft" arrow>
        <Button type="primary">Add Lead</Button>
      </Dropdown>
      {isError && <Alert type="error" description="Cannot fetch Leads" message="Error Loading" />}
      <Table
        dataSource={leads}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        onChange={onTableChange}
        pagination={{
          total: meta?.total,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          }
        }}
        footer={() => (
          <Typography.Title level={5}>Total Records: {meta?.total || '--'}</Typography.Title>
        )}
      />
    </div>
  );
}

export default LeadsList;
