import { Breadcrumb, Button, Card, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { dot, object } from 'dot-object';
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { API_HOST } from '../../helpers/constants';
import { fetcher, headers } from '../../utils';
import { openNotification } from '../../helpers';
import {
  Actions,
  BUILD_TYPES,
  DEPICTED_CREDIT_HISTORIES,
  EMPLOYEMENT_STATUSES,
  LEAD_APPLICATION_TYPES,
  LEAD_APPLICATION_TYPE_FINANCE,
  LEAD_CHANNELS,
  LEAD_CREATION_SOURCES,
  LEAD_SOURCES,
  Domain,
  PROPERTY_USAGES,
  REFINANCE_REASONS,
  CAR_BUYING_SITUATIONS
} from '../../types';

function LeadForm() {
  const [form] = Form.useForm();
  const { leadId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [lead, setLead] = useState();
  const [lenders, setLenders] = useState([]);
  const { data: leadData } = useSWR(leadId ? `${API_HOST}/leads/${leadId}` : null, fetcher);
  const { data: lendersData } = useSWR(`${API_HOST}/lenders?limit=1000`, fetcher);

  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );
  useEffect(() => {
    if (leadData) {
      setLead(leadData);
      const fd = { ...leadData, application: leadData.application };

      const dtd = {
        ...dot(fd),
        'application.applicants[0].firstName': fd.application?.applicants[0]?.userInfo?.firstName,
        'application.applicants[0].lastName': fd.application?.applicants[0]?.userInfo?.lastName,
        'application.applicants[0].email': fd.application?.applicants[0]?.userInfo?.email,
        'application.applicants[0].mobile': fd.application?.applicants[0]?.userInfo?.mobile
      };
      form.setFieldsValue(dtd);
    }
  }, [leadData]);

  useEffect(() => {
    if (lendersData) {
      setLenders(lendersData.data);
    }
  }, [lendersData]);

  const onSubmit = async (values) => {
    const leadObject = object(values);
    const verb = mode === Actions.add ? 'post' : 'put';
    const url = mode === Actions.add ? `${API_HOST}/leads` : `${API_HOST}/leads/${leadId}`;

    leadObject.domain = Domain.Home;

    try {
      const { data } = await axios[verb](url, leadObject, { headers });
      navigate(`/leads/${data._id}/edit`);
      setMode(Actions.edit);
      openNotification(`Success`, `Lead Saved!`);
    } catch (error) {
      openNotification(`Error`, `Lead Saving Failed`, true);
    }
  };

  return (
    <div>
      <Breadcrumb
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/leads">Leads</Link> },
          { title: 'Lead' }
        ]}
      />
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={24}>
          <Col xs={24} lg={12} md={24}>
            <Card title="Lead Info" className="mt-2">
              <Row gutter={24}>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item
                    label="Lead Creation Source"
                    name="leadCreationSource"
                    rules={[{ required: true }]}>
                    <Select
                      size="large"
                      options={LEAD_CREATION_SOURCES.map((t) => ({ label: t, value: t }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item label="Type" name="application.type" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      options={LEAD_APPLICATION_TYPES.map((t) => ({ label: t, value: t }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item label="Source" name="source" rules={[{ required: true }]}>
                    <Select
                      size="large"
                      options={LEAD_SOURCES.map((t) => ({ label: t, value: t }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item label="Channel" name="channel">
                    <Select
                      size="large"
                      options={LEAD_CHANNELS.map((t) => ({ label: t, value: t }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card title="Personal Details" className="mt-4">
              <Row gutter={24}>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item
                    name="application.applicants[0].firstName"
                    label="First Name"
                    rules={[{ required: true }]}>
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item name="application.applicants[0].lastName" label="Last Name">
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item
                    name="application.applicants[0].email"
                    label="Email"
                    rules={[{ required: true, type: 'email' }]}>
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item
                    name="application.applicants[0].mobile"
                    label="Mobile"
                    rules={[{ required: true }]}>
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item
                    name="application.applicants[0].employmentStatus"
                    label="Employment Status">
                    <Select
                      options={EMPLOYEMENT_STATUSES.map((e) => ({ label: e, value: e }))}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item
                    name="application.applicants[0].depictedCreditHistory"
                    label="Depicted Credit History">
                    {/* <Input size="large" /> */}
                    <Select
                      options={DEPICTED_CREDIT_HISTORIES.map((d) => ({ label: d, value: d }))}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} lg={12} md={24}>
            <Card title="Home Loan Details" className="mt-2">
              <Row gutter={24}>
                {lead?.application.type === LEAD_APPLICATION_TYPE_FINANCE ? (
                  <>
                    <Col xs={24} lg={12} md={12}>
                      <Form.Item
                        name="application.purchasePrice"
                        label="Purchase Price"
                        rules={[{ required: true }]}>
                        <InputNumber
                          className="w-full"
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                      <Form.Item
                        name="application.depositAmount"
                        label="Deposit Amount"
                        rules={[{ required: true }]}>
                        <InputNumber
                          className="w-full"
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                      <Form.Item name="application.buyingSituation" label="Buying Situation">
                        <Select
                          options={CAR_BUYING_SITUATIONS.map((l) => ({ label: l, value: l }))}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                      <Form.Item name="application.properties[0].buildType" label="Property Status">
                        <Select
                          options={BUILD_TYPES.map((b) => ({ label: b, value: b }))}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={24} lg={12} md={12}>
                      <Form.Item
                        name="application.currentLoanAmount"
                        label="Current Loans Amount"
                        rules={[{ required: true }]}>
                        <InputNumber
                          className="w-full"
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                      <Form.Item
                        name="application.currentInterestRate"
                        label="Current Interest Rate"
                        rules={[{ required: true }]}>
                        <InputNumber
                          className="w-full"
                          formatter={(value) => `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\\%\s?|(,*)/g, '')}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                      <Form.Item
                        name="application.currentLender"
                        label="Current Lender"
                        rules={[{ required: true }]}>
                        <Select
                          size="large"
                          showSearch
                          options={lenders?.map((l) => ({ label: l.name, value: l.code }))}
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={12} md={12}>
                      <Form.Item name="application.refinanceReason" label="Refinance Reason">
                        <Select
                          size="large"
                          options={REFINANCE_REASONS.map((l) => ({ label: l, value: l }))}
                          style={{ width: '100%' }}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12} md={12}>
                  <Form.Item name="application.properties[0].usage" label="Usage">
                    <Select
                      options={PROPERTY_USAGES.map((p) => ({ label: p, value: p }))}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <p className="my-4">
          <Button type="primary" htmlType="submit" size="large">
            Save
          </Button>
        </p>
      </Form>
    </div>
  );
}

export default LeadForm;
