import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import * as slugify from 'slugify';
import { openNotification } from '../../helpers';
import { Actions } from '../../types';
import { useBlock } from '../../hooks/useBlock';
import { createBlock, updateBlock } from '../../services/blocks.http';

function BlockForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );

  const { id } = params;

  const { block } = useBlock(id);

  useEffect(() => {
    if (block) {
      form.setFieldsValue(block);
    }
  }, [block]);

  const onSlugifyClick = () => {
    const slug = slugify(form.getFieldValue('name'), {
      lower: true
    });
    form.setFieldsValue({ slug });
  };

  const onSubmit = async (values) => {
    try {
      let response;
      if (mode === Actions.add) {
        response = await createBlock(values);
      } else {
        response = await updateBlock(id, values);
      }
      const { data } = response;
      navigate(`/blocks/${data._id}/edit`);
      setMode(Actions.edit);
      openNotification('Success', 'Block saved successfully!');
    } catch (error) {
      openNotification('Error', 'Block saving failed!', true);
      console.log(error);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/blocks">Blocks</Link> },
          { title: 'Block' }
        ]}
      />
      <Typography.Title level={4}>Block</Typography.Title>

      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <div className="bg-white p-5">
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Button onClick={onSlugifyClick} className="mb-4">
                Fill Slug
              </Button>
              <Form.Item label="Slug" name="slug" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Content" name="content">
                {/* <SunEditor
                  height="250px"
                  placeholder="Please type here..."
                  setContents={form.getFieldValue('content')}
                /> */}
                <Input.TextArea rows={15} />
              </Form.Item>
              <Form.Item label="Active" name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={12}>
            <Button htmlType="submit" type="primary" size="large">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default BlockForm;
