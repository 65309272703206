import axios from 'axios';
import { API_HOST } from '../helpers/constants';
import { headers } from '../utils';

const baseUrl = `${API_HOST}/users`;

export const createUser = (payload) => {
  return axios.post(`${baseUrl}`, payload, { headers });
};

export const deleteUser = (id) => {
  return axios.delete(`${baseUrl}/${id}`, { headers });
};

export const updateUser = (id, payload) => {
  return axios.put(`${baseUrl}/${id}`, payload, { headers });
};
