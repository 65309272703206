/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { API_HOST } from '../../helpers/constants';
import { fetcher } from '../../utils';
import { Domain } from '../../types';

function LeadDetailsLayout({ children }) {
  const { leadId, applicationId } = useParams();
  const [application, setApplication] = useState();
  const navigate = useNavigate();
  const [primaryApplicant, setPrimaryApplicant] = useState();
  const [lead, setLead] = useState();
  const url = `${API_HOST}/leads/${leadId}`;
  const { data: responseData } = useSWR(leadId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setLead(responseData);
      setApplication(responseData.application);
      setPrimaryApplicant(responseData.application?.applicants[0]);

      if (!applicationId) {
        navigate(
          `/leads/${leadId}/applications/${responseData.application?._id}/applicants/${responseData.application?.applicants[0]._id}`
        );
      }
    }
  }, [responseData]);

  return (
    <div className="m-2">
      <Menu mode="horizontal">
        <Menu.Item key="applicants">
          <Link
            to={`/leads/${leadId}/applications/${application?._id}/applicants/${primaryApplicant?._id}`}>
            Applicants
          </Link>
        </Menu.Item>
        {lead?.application?.domain === Domain.Home ? (
          <Menu.Item key="property">
            <Link to={`/leads/${leadId}/applications/${application?._id}/properties`}>
              Property Details
            </Link>
          </Menu.Item>
        ) : (
          <Menu.Item key="vehicle">
            <Link to={`/leads/${leadId}/applications/${application?._id}/vehicles`}>
              Vehicle Details
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key="income">
          <Link to={`/leads/${leadId}/applications/${application?._id}/incomes`}>Incomes</Link>
        </Menu.Item>
        <Menu.Item key="expense">
          <Link to={`/leads/${leadId}/applications/${application?._id}/expenses`}>Expenses</Link>
        </Menu.Item>
        <Menu.Item key="assets">
          <Link to={`/leads/${leadId}/applications/${application?._id}/assets`}>
            Assets &amp; Liabilities
          </Link>
        </Menu.Item>
        <Menu.Item key="documents">
          <Link to={`/leads/${leadId}/applications/${application?._id}/documents`}>Documents</Link>
        </Menu.Item>
      </Menu>
      <div>{children}</div>
    </div>
  );
}

export default LeadDetailsLayout;
