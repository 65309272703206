/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Typography
} from 'antd';
import useSWR, { mutate } from 'swr';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Frequency } from '../../types';
import { fetcher } from '../../utils';
import { API_HOST } from '../../helpers/constants';
import NetPosition from './widgets/NetPosition';
import { getApplicantFullName, openNotification } from '../../helpers';

const expenseTypes = [
  'Groceries',
  'Childcare',
  'Education',
  'Clothing and personal care',
  'Telephone, internet, Streaming subscriptions',
  'General Basic Insurances',
  'Higher Education/Vocational Training/Professional Fees',
  'Medical and health',
  'Pet Care',
  'Transport',
  'Recreation and entertainment',
  'Primary Residence Running Costs',
  'Council fee on Principal Place of Residence',
  'Insurance (Sickness and Personal, Accident, Life)',
  'Private Schooling and Tuition',
  'Investment Property Running Costs',
  'Health Insurance',
  'Secondary Residence Running Costs',
  'Other Expense',
  'Rent',
  'Board',
  'Child and Spousal Maintenance',
  'Other Commitment'
].sort();

function Expense({ showNetPosition = true }) {
  const [form] = Form.useForm();
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const url = `${API_HOST}/applications/${applicationId}`;

  // console.log(url)

  const { data: responseData } = useSWR(url, fetcher);

  useEffect(() => {
    if (responseData) {
      // console.log('here')
      setApplication(responseData);

      const { applicants } = responseData;
      const allExpenses = {};
      const allExpenseFrequency = {};
      applicants.forEach((a) => {
        const { expenses } = a;
        expenses.forEach((e) => {
          allExpenses[`${a._id}-${e.type}`] = e.amount || 0;
          allExpenseFrequency[`${e.type}-frequency`] = e.frequency || 'Monthly';
        });
      });

      form.setFieldsValue({ ...allExpenses, ...allExpenseFrequency });
      // expenseTypes.forEach(expenseType => {
      //   form.setFieldsValue({ [`${expenseType}-frequency`]: 'Monthly' })
      // })
    }
  }, [responseData, form]);

  const onSubmit = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      const [applicantId, expenseType] = key.split('-');
      if (!expenseTypes.includes(applicantId)) {
        data[applicantId] = data[applicantId] || [];
        data[applicantId].push({
          type: expenseType,
          amount: values[key],
          frequency: values[`${expenseType}-frequency`]
        });
      }
    });

    // const valuesToBeSubmitted = [];
    // Object.keys(data).forEach(applicantId => {
    //   valuesToBeSubmitted.push({
    //     applicantId: applicantId,
    //     expenses: data[applicantId]
    //   })
    // })

    // console.log(valuesToBeSubmitted)

    // return false;

    try {
      setIsLoading(true);
      const promises = Object.keys(data).map((applicantId) => {
        return axios.put(`${url}/applicants/${applicantId}/expenses`, data[applicantId]);
      });
      await Promise.all(promises);
      openNotification('Saved Expenses', 'Saved Expenses');
      form.resetFields();
      mutate(url);
    } catch (e) {
      openNotification('Saving Failed', 'Cound not Save Expenses', true);
      console.log(e.message);
    }
    setIsLoading(false);
  };

  return application ? (
    <div>
      {showNetPosition && <NetPosition application={application} />}
      <div>
        <Card title="Expenses" style={{ marginTop: 10 }}>
          <Row gutter={12} style={{ marginBottom: 20 }}>
            <Col span={10}>
              <Typography.Text strong>Expense Type</Typography.Text>
            </Col>
            {application.applicants.map((applicant) => (
              <Col span={2} key={applicant._id}>
                <Typography.Text strong>{getApplicantFullName(applicant.userInfo)}</Typography.Text>
              </Col>
            ))}
            <Col span={2}>
              <Typography.Text strong>Frequency</Typography.Text>
            </Col>
          </Row>
          <Divider />
          <Form form={form} onFinish={onSubmit}>
            {expenseTypes.map((expenseType) => (
              <div key={expenseType}>
                <Row gutter={12}>
                  <Col span={10}>
                    <p>
                      <Typography.Text>{expenseType}</Typography.Text>
                      <br />
                      <Typography.Text style={{ fontSize: '0.75rem' }} type="secondary" />
                    </p>
                  </Col>
                  {application.applicants.map((applicant) => (
                    <Col span={2} key={applicant._id}>
                      <Form.Item name={`${applicant._id}-${expenseType}`}>
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                  <Col span={3}>
                    <div>
                      <Form.Item name={`${expenseType}-frequency`}>
                        <Select style={{ width: '100%' }}>
                          {Object.keys(Frequency).map((k) => (
                            <Select.Option key={k} value={k}>
                              {Frequency[k]}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
            <Divider />
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </Form>
        </Card>
      </div>
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default Expense;
