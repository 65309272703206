/* eslint-disable react/prop-types */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Card, Col, Popconfirm, Row, Space, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';

function EmploymentCard({ employment, onEditClick, onDeleteClick }) {
  return (
    <Card
      type="inner"
      className="mb-4"
      title={
        <Space>
          <span>Employment</span>
          {employment.isCurrent ? <Tag color="orange">Current</Tag> : ''}
        </Space>
      }
      extra={
        <Space size="middle">
          <a href="#" onClick={() => onEditClick(employment)}>
            <EditOutlined />
          </a>
          <Tooltip placement="bottom" title="Delete">
            <Popconfirm
              title="Are you sure to delete applicant?"
              onConfirm={() => onDeleteClick(employment._id)}>
              <a href="!#">
                <DeleteOutlined className="text-red-600" style={{ fontSize: 18 }} />{' '}
              </a>
            </Popconfirm>
          </Tooltip>
        </Space>
      }>
      <Row gutter={24}>
        <Col span={6}>
          <Typography.Text strong className="block">
            Employer Name
          </Typography.Text>
          {employment.employerName}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Position
          </Typography.Text>
          {employment.position}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Industry
          </Typography.Text>
          {employment.industry}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            ABN
          </Typography.Text>
          {employment.abn}
        </Col>
      </Row>
      <Row gutter={24} className="mt-4">
        <Col span={6}>
          <Typography.Text strong className="block">
            Contact Name
          </Typography.Text>
          {employment.contactName}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Phone
          </Typography.Text>
          {employment.phone}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Start Date
          </Typography.Text>
          {dayjs(employment.startDate).format('DD/MM/YYYY')}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            End Date
          </Typography.Text>
          {employment.endDate ? dayjs(employment.endDate).format('DD/MM/YYYY') : '--'}
        </Col>
      </Row>

      <Row gutter={24} className="mt-4">
        <Col span={6}>
          <Typography.Text strong className="block">
            Time at employment (Yrs){' '}
          </Typography.Text>
          {employment.yearsDuration}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Time at employment (Mths)
          </Typography.Text>
          {employment.monthsDuration}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Employment Type
          </Typography.Text>
          {employment.type}
        </Col>
      </Row>
      <Typography.Title level={5} className="mt-5">
        Address
      </Typography.Title>
      <Row gutter={24} className="mt-4">
        <Col span={6}>
          <Typography.Text strong className="block">
            Unit #
          </Typography.Text>
          {employment.unitNumber || '--'}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Street #
          </Typography.Text>
          {employment.streetNumber}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Street Name
          </Typography.Text>
          {employment.streetName}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Suburb
          </Typography.Text>
          {employment.city}
        </Col>
      </Row>
      <Row gutter={24} className="mt-3">
        <Col span={6}>
          <Typography.Text strong className="block">
            State
          </Typography.Text>
          {employment.state}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Postcode
          </Typography.Text>
          {employment.postcode}
        </Col>
        <Col span={6}>
          <Typography.Text strong className="block">
            Country
          </Typography.Text>
          {employment.country}
        </Col>
      </Row>
    </Card>
  );
}

export default EmploymentCard;
