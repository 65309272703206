import { Routes, Route, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Suspense } from 'react';
import Home from './components/Home';
import LeadsList from './components/leads/LeadsList';
import ApplicationsList from './components/application/ApplicationsList';
import Application from './components/application/Application';
import ApplicantsLayout from './components/application/applicants/ApplicantsLayout';
import PersonalDetails from './components/application/applicants/PersonalDetails';
import Address from './components/application/applicants/Address';
import Employment from './components/application/applicants/Employment';
import { SecureLayout } from './components/SecureLayout';
import Login from './components/auth/Login';
import CreditReport from './components/application/CreditReport';
import AssetsLiabilities from './components/application/AssetsLiabilities';
import Documents from './components/application/Documents';
import Income from './components/application/Income';
import Property from './components/application/Property';
import Quotes from './components/application/Quotes';
import Expense from './components/application/Expense';
import File from './components/application/File';
import UsersList from './components/users/UsersList';
import UserForm from './components/users/UserForm';
import Verify from './components/auth/Verify';
import SetPassword from './components/auth/SetPassword';
import Forgot from './components/auth/Forgot';
import UnsecureLayout from './components/auth/UnsecureLayout';
import LendersList from './components/lenders/LendersList';
import LenderForm from './components/lenders/LenderForm';
import ApplicationStatusBoard from './components/application/status-board/ApplicationStatusBoard';
import Notes from './components/common/Notes';
import TimeLine from './components/application/TimeLine';
import LeadLayout from './components/leads/LeadLayout';
import Tasks from './components/leads/Tasks';
import ApplicationsView from './components/leads/Applications';
import LeadForm from './components/leads/LeadForm';
import LeadDetailsLayout from './components/leads/LeadDetailsLayout';
import BlogsList from './components/blogs/BlogsList';
import BlogForm from './components/blogs/BlogForm';
import BlogPostsList from './components/blog-posts/BlogPostsList';
import BlogPostForm from './components/blog-posts/BlogPostForm';
import PagesList from './components/pages/PagesList';
import PageForm from './components/pages/PageForm';
import BlogAuthorForm from './components/blog-authors/BlogAuthorForm';
import BlogAuthorsList from './components/blog-authors/BlogAuthorsList';
import BlocksList from './components/blocks/BlocksList';
import BlockForm from './components/blocks/BlockForm';
import FaqsList from './components/faqs/FaqsList';
import FaqForm from './components/faqs/FaqForm';

import AccountView from './components/accounts/AccountView';
import LeadReasonsList from './components/lead-reasons/LeadReasonsList';
import LeadReasonForm from './components/lead-reasons/LeadReasonForm';
import SitemapForm from './components/sitemaps/SitemapForm';
import CarLoanLeadForm from './components/leads/CarLoanLeadForm';
import { getLoggedInUser } from './utils';
import Lpoader from './components/loader/Loader';
import Vehicle from './components/application/Vehicle';
import LeadStatusBoard from './components/application/status-board/LeadStatusBoard';
import AccountList from './components/accounts/AccountsList';
import RequirementsTab from './components/application/RequirementsTab';
import ComplianceTab from './components/application/compliance/ComplianceTab';
import LoanSubmission from './components/application/loan-details/LoanSubmission';
import LoanDetailsLayout from './components/application/loan-details/LoanDetailsLayout';
import LoanDecision from './components/application/loan-details/LoanDecision';
import PreSettlement from './components/application/loan-details/PreSettlement';
import Settlement from './components/application/settlement/Settlement';
import SellerDetails from './components/application/settlement/SellerDetails';
import SettlementLoanDetails from './components/application/settlement/SettlementLoanDetails';
import WarrantyInsurance from './components/application/settlement/WarrantyInsurance';
import SettlementDocuments from './components/application/settlement/SettlementDocuments';

function App() {
  const location = useLocation();

  if (location.pathname === '/auth/login') {
    return <Login />;
  }
  if (location.pathname === '/auth/forgot') {
    return (
      <UnsecureLayout>
        <Forgot />
      </UnsecureLayout>
    );
  }
  if (location.pathname === '/auth/verify') {
    return <Verify />;
  }
  if (location.pathname === '/auth/set-password') {
    return <SetPassword />;
  }

  return (
    <div className="App">
      <Suspense fallback={<Lpoader />}>
        <SecureLayout loggedInUser={getLoggedInUser()}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/users" element={<UsersList />} />
            <Route path="/users/create" element={<UserForm />} />
            <Route path="/users/:userId" element={<UserForm />} />

            <Route path="/lenders" element={<LendersList />} />
            <Route path="/lenders/create" element={<LenderForm />} />
            <Route path="/lenders/:lenderId" element={<LenderForm />} />

            <Route path="/blocks" element={<BlocksList />} />
            <Route path="/blocks/create" element={<BlockForm />} />
            <Route path="/blocks/:id/edit" element={<BlockForm />} />

            <Route path="/faqs" element={<FaqsList />} />
            <Route path="/faqs/create" element={<FaqForm />} />
            <Route path="/faqs/:id/edit" element={<FaqForm />} />

            <Route path="/blogs" element={<BlogsList />} />
            <Route path="/blogs/create" element={<BlogForm />} />
            <Route path="/blogs/:id/edit" element={<BlogForm />} />

            <Route path="/blog-posts" element={<BlogPostsList />} />
            <Route path="/blog-posts/create" element={<BlogPostForm />} />
            <Route path="/blog-posts/:id/edit" element={<BlogPostForm />} />

            <Route path="/blog-authors" element={<BlogAuthorsList />} />
            <Route path="/blog-authors/create" element={<BlogAuthorForm />} />
            <Route path="/blog-authors/:id/edit" element={<BlogAuthorForm />} />

            <Route path="/sitemap" element={<SitemapForm />} />

            <Route path="/pages" element={<PagesList />} />
            <Route path="/pages/create" element={<PageForm />} />
            <Route path="/pages/:id/edit" element={<PageForm />} />

            <Route path="/lead-reasons" element={<LeadReasonsList />} />
            <Route path="/lead-reasons/create" element={<LeadReasonForm />} />
            <Route path="/lead-reasons/:id/edit" element={<LeadReasonForm />} />

            <Route path="/accounts" element={<AccountList />} />
            <Route path="/accounts/create" element={<PageForm />} />
            <Route path="/accounts/:accountId" element={<AccountView />} />

            <Route path="/leads" element={<LeadsList />} />
            <Route path="/leads/car-loan/create" element={<CarLoanLeadForm />} />
            <Route path="/leads/home-loan/create" element={<LeadForm />} />
            <Route path="/leads/create" element={<LeadForm />} />
            {/* <Route path="/leads/:leadId" element={<LeadLayout><LeadDetailsLayout /></LeadLayout>} /> */}
            <Route
              path="/leads/:leadId"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <ApplicantsLayout>
                      <PersonalDetails />
                    </ApplicantsLayout>
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route path="/leads/:leadId/edit" element={<LeadForm />} />
            <Route
              path="/leads/:leadId/tasks"
              element={
                <LeadLayout>
                  <Tasks entity="lead" />
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/notes"
              element={
                <LeadLayout>
                  <Notes className="px-5 py-8" />
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId"
              element={
                <LeadLayout>
                  <ApplicantsLayout />{' '}
                </LeadLayout>
              }
            />

            <Route
              path="/leads/:leadId/applications/:applicationId/credit-reports"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <CreditReport />
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/assets"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <AssetsLiabilities showNetPosition={false} />
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/documents"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <Documents />
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/incomes"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <Income showNetPosition={false} />
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/properties"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <Property />
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/vehicles"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <Vehicle />
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/quotes"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <Quotes />
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/expenses"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <Expense showNetPosition={false} />
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/timeline"
              element={
                <LeadLayout>
                  <ApplicationsView>
                    <Row gutter={24}>
                      <Col span={8}>
                        <Notes />
                      </Col>
                      <Col span={8} />
                      <Col span={8}>
                        <TimeLine />
                      </Col>
                    </Row>
                  </ApplicationsView>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/requirements"
              element={
                <LeadLayout>
                  <ApplicationsView>Requirements</ApplicationsView>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/applicants/:applicantId"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <ApplicantsLayout>
                      <PersonalDetails />
                    </ApplicantsLayout>
                  </LeadDetailsLayout>{' '}
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/applicants/:applicantId/personal"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <ApplicantsLayout>
                      <PersonalDetails />
                    </ApplicantsLayout>
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/applicants/:applicantId/addresses"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <ApplicantsLayout>
                      <Address />
                    </ApplicantsLayout>
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            <Route
              path="/leads/:leadId/applications/:applicationId/applicants/:applicantId/employments"
              element={
                <LeadLayout>
                  <LeadDetailsLayout>
                    <ApplicantsLayout>
                      <Employment />
                    </ApplicantsLayout>
                  </LeadDetailsLayout>
                </LeadLayout>
              }
            />
            {/* <Route path="/leads/:leadId/applications/:applicationId" element={<LeadLayout><Application /></LeadLayout>} /> */}

            <Route path="/applications-board" element={<ApplicationStatusBoard />} />
            <Route path="/leads-board" element={<LeadStatusBoard />} />

            <Route path="/applications" element={<ApplicationsList />} />
            <Route
              path="/applications/:applicationId"
              element={
                <Application>
                  <RequirementsTab />
                </Application>
              }
            />

            <Route
              path="/applications/:applicationId/tasks"
              element={
                <Application>
                  <Tasks entity="application" />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/notes"
              element={
                <Application>
                  <Notes className="px-5 py-8" />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/credit-reports"
              element={
                <Application>
                  <CreditReport />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/assets"
              element={
                <Application>
                  <AssetsLiabilities />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/documents"
              element={
                <Application>
                  <Documents />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/incomes"
              element={
                <Application>
                  <Income />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/properties"
              element={
                <Application>
                  <Property />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/vehicles"
              element={
                <Application>
                  <Vehicle />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/quotes"
              element={
                <Application>
                  <Quotes />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/expenses"
              element={
                <Application>
                  <Expense />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/compliance"
              element={
                <Application>
                  <ComplianceTab />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/loan-details"
              element={
                <Application>
                  <LoanDetailsLayout>
                    <LoanSubmission />
                  </LoanDetailsLayout>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/loan-details/decision"
              element={
                <Application>
                  <LoanDetailsLayout>
                    <LoanDecision />
                  </LoanDetailsLayout>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/loan-details/pre-settlement"
              element={
                <Application>
                  <LoanDetailsLayout>
                    <PreSettlement />
                  </LoanDetailsLayout>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/settlement"
              element={
                <Application>
                  <Settlement>
                    <SettlementDocuments />
                  </Settlement>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/settlement/seller-details"
              element={
                <Application>
                  <Settlement>
                    <SellerDetails />
                  </Settlement>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/settlement/loan-details"
              element={
                <Application>
                  <Settlement>
                    <SettlementLoanDetails />
                  </Settlement>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/settlement/warranty-insurance"
              element={
                <Application>
                  <Settlement>
                    <WarrantyInsurance />
                  </Settlement>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/settlement/documents"
              element={
                <Application>
                  <Settlement>
                    <SettlementDocuments />
                  </Settlement>
                </Application>
              }
            />
            <Route path="/applications/:applicationId/files" element={<File />} />
            <Route
              path="/applications/:applicationId/timeline"
              element={
                <Application>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Notes />
                    </Col>
                    <Col span={8} />
                    <Col span={8}>
                      <TimeLine />
                    </Col>
                  </Row>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/requirements"
              element={
                <Application>
                  <RequirementsTab />
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/applicants/:applicantId/personal"
              element={
                <Application>
                  <ApplicantsLayout>
                    <PersonalDetails />
                  </ApplicantsLayout>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/applicants/:applicantId/addresses"
              element={
                <Application>
                  <ApplicantsLayout>
                    <Address />
                  </ApplicantsLayout>
                </Application>
              }
            />
            <Route
              path="/applications/:applicationId/applicants/:applicantId/employments"
              element={
                <Application>
                  <ApplicantsLayout>
                    <Employment />
                  </ApplicantsLayout>
                </Application>
              }
            />
          </Routes>
        </SecureLayout>
      </Suspense>
    </div>
  );
}

export default App;
