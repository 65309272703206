import { Alert, Breadcrumb, Button, Input, Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState, useRef } from 'react';
import { getApplicantFullName } from '../../helpers';

import { useAccounts } from '../../hooks/useAccounts';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

function AccountList() {
  const [allParams, setAllParams] = useState({
    take: 10,
    skip: 0
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setAllParams({ ...allParams, [`filters[${dataIndex}]`]: selectedKeys[0] });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const { accounts, meta, isLoading, isError } = useAccounts(allParams);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}>
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined
        }}
      />
    ),
    // onFilter: (value, record) => {
    //   if (value) {
    //     return record.status.indexOf(value) === 0;
    //   }
    // },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const getColumnSortProps = (column, sortOrder) => {
    setAllParams({
      ...allParams,
      sortBy: sortOrder === 'descend' ? `-${column}` : column
    });
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'filter' || extra.action === 'sort') {
      const statusArr = `${filters.status}`.split(',');
      setAllParams({ ...allParams, 'filters[status]': statusArr });
    }
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'shortId',
      key: 'shortId',
      width: '5%',
      sorter: (a, b, sortOrder) => getColumnSortProps('shortId', sortOrder),
      ...getColumnSearchProps('shortId'),
      render: (text, row) => <Link to={`/accounts/${row._id}`}>{text}</Link>
    },

    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b, sortOrder) => getColumnSortProps('firstName', sortOrder),
      ...getColumnSearchProps('firstName'),
      render: (text, row) => getApplicantFullName(row)
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    },

    {
      title: 'Action',
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/accounts/${record._id}/edit`}>Edit</Link>
        </Space>
      )
    }
  ];

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        className="mb-3"
        items={[{ title: <Link to="/">Home</Link> }, { title: 'Account' }]}
      />
      <Typography.Title level={4}>Account List</Typography.Title>
      {isError && (
        <Alert type="error" description="Cannot fetch Accounts" message="Error Loading" />
      )}

      <Table
        dataSource={accounts}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        onChange={onTableChange}
        pagination={{
          total: meta?.total,
          current: meta?.page,
          pageSize: meta?.limit,
          showSizeChanger: false,
          onChange: (page, pageSize) => {
            setAllParams({ ...allParams, skip: (page - 1) * pageSize });
          },
          onShowSizeChange: (page, pageSize) => {
            setAllParams({ ...allParams, take: pageSize });
          }
        }}
        footer={() => (
          <Typography.Title level={5}>Total Records: {meta?.total || '--'}</Typography.Title>
        )}
      />
    </div>
  );
}

export default AccountList;
