/* eslint-disable react/prop-types */
import { Button, Skeleton, Table } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_HOST } from '../../helpers/constants';
import { fetcher, headers } from '../../utils';

function AccountListWidget({ onSelect }) {
  const [accounts, setAccount] = useState([]);
  const [meta, setMeta] = useState({});
  const url = `${API_HOST}/accounts`;

  const { data: responseData } = useSWR(url, fetcher);

  useEffect(() => {
    if (responseData) {
      setAccount(responseData.data);
      setMeta(responseData.meta);
    }
  }, [responseData]);

  const fetchData = async (params = {}) => {
    const { data: responseData } = await axios(url, { params, headers });

    const { data, meta } = responseData;
    setAccount(data);
    setMeta(meta);
  };

  const onTableChange = async (pagination, filters, sorter) => {
    const options = {
      // term: searchText,
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      sort: sorter.order === 'descend' ? `-${sorter.field}` : sorter.field,
      ...filters
    };

    await fetchData(options);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'shortId',
      key: 'shortId',
      width: '5%',
      render: (text, row) => <Link to={`/accounts/${row._id}`}>{text}</Link>
    },

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, row) => `${row.firstName} ${row.lastName}`
    },

    {
      title: 'Action',
      key: 'action',
      width: '5%',
      render: (text, row) => (
        <Button onClick={() => onSelect(row._id)} size="small">
          Select
        </Button>
      )
    }
  ];

  return accounts ? (
    <div>
      <Table
        dataSource={accounts}
        columns={columns}
        onChange={onTableChange}
        rowKey={(row) => row.shortId}
        pagination={{
          total: meta.total,
          pageSize: meta.limit
        }}
      />
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default AccountListWidget;
