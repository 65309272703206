/* eslint-disable react/prop-types */
import { Col, Form, InputNumber, Modal, Row, Select, Switch } from 'antd';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { BUYING_SITUATIONS, CAR_BUYING_SITUATIONS, Domain } from '../../types';
import { API_HOST } from '../../helpers/constants';
import { fetcher, headers } from '../../utils';
import { openNotification } from '../../helpers';

export default function LoanDetailsModal({
  isLoanDetailsModalOpen,
  hideLoanDetailsModal,
  loanDetailsForm,
  application,
  afterSubmissionCallback,
  mutationUrl
}) {
  const [lenders, setLenders] = useState();
  const lendersUrl = `${API_HOST}/lenders`;

  const { data: lendersResponseData } = useSWR(lendersUrl, fetcher);

  useEffect(() => {
    if (lendersResponseData) {
      setLenders(lendersResponseData.data);
    }
  }, [lendersResponseData]);

  const filterLenders = (inputValue) => {
    return lenders
      ?.filter((i) => i.name?.toLowerCase().includes(inputValue.toLowerCase()))
      .map((x) => ({ label: x.name, value: x.code }));
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterLenders(inputValue));
      }, 1000);
    });

  const loanTermValues =
    application?.domain !== Domain.Car
      ? new Array(20)
          .fill(0)
          .map((i, index) => ({ label: `${index + 11} years`, value: index + 11 }))
      : new Array(7).fill(0).map((i, index) => ({ label: `${index + 1} years`, value: index + 1 }));

  const onLoanDetailsSubmit = async (values) => {
    try {
      await axios.patch(`${API_HOST}/applications/${application._id}`, values, { headers });
      mutate(mutationUrl);
      openNotification('Application updated', 'Applicant updated successfully');
      afterSubmissionCallback();
    } catch (error) {
      openNotification('Failure', 'Applicant could not be updated', true);
      console.log(error);
    }
  };
  return (
    <Modal
      open={isLoanDetailsModalOpen}
      title="Loan Details"
      onCancel={hideLoanDetailsModal}
      onOk={loanDetailsForm.submit}
      closable>
      <Form form={loanDetailsForm} layout="vertical" onFinish={onLoanDetailsSubmit}>
        {application?.type !== 'Refinance' ? (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Purchase Price" name="purchasePrice" rules={[{ required: true }]}>
                  <InputNumber
                    className="w-full"
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Deposit Amount" name="depositAmount" rules={[{ required: true }]}>
                  <InputNumber
                    className="w-full"
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    size="large"
                  />
                </Form.Item>
              </Col>
              {application.domain !== Domain.Car && (
                <Col span={12}>
                  <Form.Item label="First Home" name="isFirstHomeBuyer" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Form.Item label="Buying Situation" name="buyingSituation" rules={[{ required: true }]}>
              <Select size="large">
                {application.domain !== Domain.Car
                  ? BUYING_SITUATIONS.map((b) => (
                      <Select.Option key={b} value={b}>
                        {b}
                      </Select.Option>
                    ))
                  : CAR_BUYING_SITUATIONS.map((b) => (
                      <Select.Option key={b} value={b}>
                        {b}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
            {application?.domain === Domain.Car && (
              <Form.Item
                label="Balloon Percentage"
                name="balloonPercentage"
                rules={[{ required: true }]}>
                <InputNumber
                  size="large"
                  className="w-full"
                  min={0}
                  max={50}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>
            )}
          </>
        ) : (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Current Loan Amount"
                name="currentLoanAmount"
                rules={[{ required: true }]}>
                <InputNumber
                  className="w-full"
                  defaultValue={1000}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Current  Interest Rate" name="currentInterestRate">
                <InputNumber
                  className="w-full"
                  size="large"
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Current Lender" name="currentLender" rules={[{ required: true }]}>
                <AsyncSelect
                  placeholder="Start typing..."
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Loan Term (Yrs)" name="loanTermYears" rules={[{ required: true }]}>
              <Select size="large" options={loanTermValues} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
