import StatusBoardCard from './StatusBoardCard';
import { APPLICATION_STATUSES } from '../../../helpers/constants';
import { DatePicker } from 'antd';
import { useApplications } from '../../../hooks/useApplications';
import moment from 'moment';
import { getLoggedInUser } from '../../../utils';

function ApplicationStatusBoard() {
  const loggedInUser = getLoggedInUser();

  const defaulDateRange = [moment().add(-7, 'M'), moment()];

  const params = {
    dateRange: defaulDateRange,
    include: 'lead',
    domain: 'Home'
    // leadStatus: 'Won'
  };

  if (loggedInUser.role === 'broker') {
    params['owner'] = loggedInUser.id;
  }

  const { applications } = useApplications(params);

  return (
    <div>
      <div className="my-2">
        <DatePicker.RangePicker />
      </div>
      <div className="flex space-x-1 overflow-auto min-h-screen">
        {APPLICATION_STATUSES.map((applicationStatus) => (
          <div className="w-60 bg-gray-200 p-2 flex-shrink-0" key={applicationStatus}>
            <div title={applicationStatus}>
              <p className="bg-white p-1 text-center font-bold">{applicationStatus}</p>
              {/* <p className='mb-2'>5.00 M</p> */}
              <div className="flex flex-col space-y-2">
                {applications
                  ?.filter((application) => application.status === applicationStatus)
                  .map((application) => (
                    <StatusBoardCard key={application._id} application={application} />
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ApplicationStatusBoard;
