import useSWR, { mutate } from 'swr';
import { API_HOST, disableRevalidation } from '../helpers/constants';
import { fetcher } from '../utils';

export function useFaqs(params) {
  const url = `${API_HOST}/faqs`;

  const urlParams = new URLSearchParams(params).toString();
  const urlWithParams = [url, urlParams].join('?');
  const {
    data: responseData,
    error,
    isLoading
  } = useSWR(urlWithParams, fetcher, disableRevalidation);

  return {
    faqs: responseData?.data || [],
    meta: responseData?.meta,
    isLoading,
    isError: error,
    revalidate: () => mutate([url, params])
  };
}
