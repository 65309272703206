import { useEffect, useState } from 'react';
import { Card, Col, Divider, Row, Skeleton, Tabs } from 'antd';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { fetcher } from '../../utils';
import { API_HOST } from '../../helpers/constants';
import NetPosition from './widgets/NetPosition';
import { currency } from '../../helpers';

const { TabPane } = Tabs;

function Quotes() {
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [quotes, setQuotes] = useState(null);

  const url = `${API_HOST}/applications/${applicationId}`;
  const quotesUrl = `${API_HOST}/applications/${applicationId}/quotes`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);
  const { data: quotesData } = useSWR(applicationId ? quotesUrl : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
    }
  }, [responseData]);

  useEffect(() => {
    if (quotesData) {
      setQuotes(quotesData);
    }
  }, [quotesData]);

  return quotes ? (
    <div>
      <NetPosition application={application} />
      <div style={{ margin: '10px 10px' }}>
        <Card title="Quotes" style={{ marginTop: 10 }} type="inner" bodyStyle={{ padding: 10 }}>
          <Row gutter={12}>
            {quotes.map((q) => (
              <Col span={6} key={q._id}>
                <Card className="border border-blue-500" bodyStyle={{ padding: 10 }}>
                  <Row gutter={24}>
                    <Col span={18}>
                      <p className="text-2xl font-bold">{q.lender.name}</p>
                    </Col>
                    <Col span={6} />
                  </Row>
                  <Row gutter={24} className="mt-4">
                    <Col span={12}>
                      <p className="text-gray-500">Repayment</p>
                      <span className="font-semibold text-2xl text-green-500">
                        {currency(q.emi)}
                      </span>
                      <span className="text-gray-400"> /mo</span>
                    </Col>
                    <Col span={12}>
                      <p className="text-gray-500">Repayment Type</p>
                      <p className="font-semibold">{q.emiType}</p>
                    </Col>
                  </Row>
                  <Row gutter={24} className="mt-6">
                    <Col span={12}>
                      <p className="text-gray-500">Customer Rate</p>
                      <p className="font-semibold">
                        {q.rateType} {q.rate}%
                      </p>
                    </Col>
                    <Col span={12}>
                      <p className="text-gray-500">Comparison Rate</p>
                      <p className="font-semibold">{q.comparisonRate}%</p>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={24} className="mt-6 mb-6">
                    <Col span={12}>
                      <p className="text-gray-500">Lending Commission</p>
                      <p className="font-semibold">{currency(3000)}</p>
                    </Col>
                    <Col span={12}>
                      <p className="text-gray-500">Partner Referral</p>
                      <p className="font-semibold">{currency(300)}</p>
                    </Col>
                  </Row>
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Features" key="1">
                      <ul className="list-disc">
                        <li>First feature</li>
                        <li>Second feature</li>
                        <li>Third feature</li>
                      </ul>
                    </TabPane>
                    <TabPane tab="Fees" key="2">
                      Content of Tab Pane 2
                    </TabPane>
                    <TabPane tab="Knockouts" key="3">
                      Content of Tab Pane 3
                    </TabPane>
                    <TabPane tab="Documents" key="4">
                      Content of Tab Pane 4
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </div>
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default Quotes;
