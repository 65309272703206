/* eslint-disable react/prop-types */
import { Col, Menu, Row } from 'antd';
import { memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API_HOST } from '../../../helpers/constants';
import useSWR from 'swr';
import { fetcher } from '../../../utils';
import { getShouldEnablePreSettlement } from '../../../helpers';

function LoanDetailsLayout({ children }) {
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application } = useSWR(applicationUrl, fetcher);

  const isLoanSubmitted = !!application?.loanDetails?.submission?.submittedAt;

  const shouldEnablePreSettlement = getShouldEnablePreSettlement(
    application?.loanDetails?.decision?.decidedAt,
    application?.loanDetails?.decision?.outcomeOfApplication
  );

  const items = [
    {
      key: 'submission',
      label: <Link to={`/applications/${applicationId}/loan-details`}>Submission</Link>
    },
    {
      key: 'decision',
      label: isLoanSubmitted ? (
        <Link to={`/applications/${applicationId}/loan-details/decision`}>Decision</Link>
      ) : (
        <p>Decision</p>
      ),
      disabled: !isLoanSubmitted
    },
    {
      key: 'pre-settlement',
      label: shouldEnablePreSettlement ? (
        <Link to={`/applications/${applicationId}/loan-details/pre-settlement`}>
          Pre-settlement
        </Link>
      ) : (
        <p>Pre-settlement</p>
      ),
      disabled: !shouldEnablePreSettlement
    }
  ];

  return (
    <Row gutter={24}>
      <Col span={3}>
        <Menu items={items} defaultSelectedKeys={['submission']} />
      </Col>
      <Col span={21}>{children}</Col>
    </Row>
  );
}

export default memo(LoanDetailsLayout);
