/* eslint-disable react/jsx-filename-extension */
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// import "antd/dist/antd.css";
import './index.css';
import { ConfigProvider } from 'antd';
import { StrictMode } from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <ConfigProvider
      theme={{
        components: {
          // Layout: {
          //   colorBgHeader: 'transprent',
          //   colorBgBody: 'transprent',
          // },
          Input: {
            borderRadius: '2px'
          },
          Button: {
            borderRadius: '2px'
          },
          Alert: {
            withDescriptionIconSize: 22,
            withDescriptionPadding: '6px 12px'
          }
        }
      }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
