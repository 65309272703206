import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import useSWR, { mutate } from 'swr';
import moment from 'moment';
import axios from 'axios';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams, useLocation } from 'react-router-dom';
import { openNotification } from '../../../helpers';
import { API_HOST } from '../../../helpers/constants';
import { fetcher } from '../../../services/http';
import { Title, Gender, State, ResidencyStatus, RelationshipStatus } from '../../../types';

function PersonalDetails() {
  const location = useLocation();
  const [form] = Form.useForm();
  const { applicationId, applicantId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const url = `${API_HOST}/applications/${applicationId}`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);

  const isLeadView = location.pathname.startsWith('/leads');

  useEffect(() => {
    if (responseData) {
      const applicant = responseData.applicants?.find((a) => a._id === applicantId);
      form.resetFields();
      form.setFieldsValue({
        ...applicant,
        title: applicant?.userInfo.title,
        firstName: applicant?.userInfo.firstName,
        middleName: applicant?.userInfo.middleName,
        lastName: applicant?.userInfo.lastName,
        email: applicant?.userInfo.email,
        mobile: applicant?.userInfo.mobile,
        dateOfBirth: applicant?.dateOfBirth ? moment(applicant?.dateOfBirth) : null,
        driverLicenceExpiry: applicant?.driverLicenceExpiry
          ? moment(applicant?.driverLicenceExpiry)
          : null
      });
    }
  }, [responseData, form, applicantId]);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await axios.put(`${url}/applicants/${applicantId}`, values);
      openNotification('Saved Applicant', 'Saved Applicant');
      mutate(url);
    } catch (e) {
      if (e.response?.data.field) {
        form.setFields([{ name: e.response?.data.field, errors: [e.response?.data.message] }]);
      }
      openNotification(
        'Saving Failed',
        e.response?.data.message || 'Could not Save Applicant',
        true
      );
      console.log(e.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div style={{ paddingRight: 20 }} className="mt-2">
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Typography.Title level={4}>Basic Details</Typography.Title>
          <Divider style={{ marginTop: 5 }} />
          <Row gutter={24}>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={4}>
                  <Form.Item label="Title" name="title" rules={[{ required: !isLeadView }]}>
                    <Select>
                      {Object.keys(Title).map((key) => (
                        <Select.Option key={key} value={key}>
                          {Title[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[{ required: !isLeadView }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item label="Last Name" name="lastName" rules={[{ required: !isLeadView }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Middle Name" name="middleName">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item label="Gender" name="gender" rules={[{ required: !isLeadView }]}>
                    <Select>
                      {Object.keys(Gender).map((key) => (
                        <Select.Option key={key} value={key}>
                          {Gender[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Date of Birth" name="dateOfBirth">
                    <DatePicker
                      format="DD/MM/YYYY"
                      disabledDate={(current) => {
                        return current >= moment().subtract(18, 'years');
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Typography.Title level={5}>Contact Details</Typography.Title>
          <Divider style={{ marginTop: 5 }} />
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: !isLeadView, type: 'email' }]}>
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Mobile" name="mobile" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Phone" name="phone">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Typography.Title level={5}>Residency Details</Typography.Title>
          <Divider style={{ marginTop: 5 }} />
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Status" name="residencyStatus" rules={[{ required: !isLeadView }]}>
                <Select>
                  {Object.keys(ResidencyStatus).map((key) => (
                    <Select.Option key={key} value={key}>
                      {ResidencyStatus[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Visa Other" name="visaOther">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Typography.Title level={5}>Relationship Details</Typography.Title>
          <Divider style={{ marginTop: 5 }} />
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Status"
                name="relationshipStatus"
                rules={[{ required: !isLeadView }]}>
                <Select>
                  {Object.keys(RelationshipStatus).map((key) => (
                    <Select.Option key={key} value={key}>
                      {RelationshipStatus[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <p className="mb-2">Dependants</p>
              <Form.List name="dependants">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space
                        key={key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'age']}
                          rules={[{ required: true, message: 'Missing age' }]}>
                          <Input placeholder={`Depedant ${index + 1} - Age`} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Dependeant
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Typography.Title level={5}>Identification Details</Typography.Title>
          <Divider style={{ marginTop: 5 }} />
          {/* <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Id Type"
                name="idType"
                rules={[{ required: true }]}
              >
                <Select>
                  {Object.keys(IdentificationTypes).map(key => <Select.Option key={key} value={key}>{IdentificationTypes[key]}</Select.Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>

            </Col>
          </Row> */}
          <Row gutter={24}>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item label="Drivers Licence Number" name="driverLicenceNumber">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Drivers Licence State" name="driverLicenceState">
                    <Select>
                      {Object.keys(State).map((key) => (
                        <Select.Option key={key} value={key}>
                          {State[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Drivers Licence Valid Till" name="driverLicenceExpiry">
                    <DatePicker format="DD/MM/YYYY" className="w-full" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item label="Passport Number" name="passportNumber">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Passport Valid Till" name="passportExpiry">
                    <DatePicker format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider style={{ marginTop: 5 }} />
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default PersonalDetails;
