import { useState, useEffect } from 'react';
import axios from 'axios';
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
  Typography
} from 'antd';
import useSWR, { mutate } from 'swr';
import { useParams } from 'react-router-dom';
import { currency, getApplicantFullName, openNotification } from '../../helpers';
import { API_HOST } from '../../helpers/constants';
import { fetcher } from '../../utils';
import { USAGES } from '../../types';

function Vehicle() {
  const { applicationId, applicantId } = useParams();
  const [isVehicleDrawerVisible, setIsVehicleDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const [application, setApplication] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [mode, setMode] = useState('add');

  const url = `${API_HOST}/applications/${applicationId}`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      // form.setFieldsValue(defaultOwnership)
    }
  }, [responseData, applicantId]);

  const onAddVehicleClick = () => {
    form.resetFields();
    setMode('add');
    setIsVehicleDrawerVisible(true);
  };

  const onEditClick = (vehicle) => {
    setVehicle(vehicle);
    setMode('edit');
    form.setFieldsValue(vehicle);
    setIsVehicleDrawerVisible(true);
  };

  const onSubmit = async (values) => {
    const { ownership } = values;

    let durl = `${url}/vehicles`;
    let method = 'post';

    if (mode === 'edit') {
      durl = `${durl}/${vehicle._id}`;
      method = 'put';
    }

    if (!ownership || !ownership.length) {
      openNotification('Ownership Missing', 'You have not specified the ownerships', true);
      return false;
    }

    const sumPercentages = ownership.reduce((a, o) => a + +o.percentage, 0);
    if (sumPercentages !== 100) {
      openNotification('Incorrect Ownership ', 'Sum of all ownerships should be 100%', true);
      return false;
    }

    try {
      setIsLoading(true);
      await axios[method](durl, values);
      openNotification(`Saved Vehicle`, `Saved Vehicle successfully`);
      form.resetFields();
      setIsVehicleDrawerVisible(false);
      mutate(url);
    } catch (e) {
      console.log(e.message);
      openNotification('Saving Failed', `Cound not save Vehicle`, true);
    }
    setIsLoading(false);
  };

  const onDelete = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`${url}/vehicles/${id}`);
      openNotification(`Deleted Vehicle`, `Successfully deleted Vehicle`);
      setIsVehicleDrawerVisible(false);
      mutate(url);
    } catch (e) {
      openNotification('Deletion Failed', `Cound not delete Vehicle`, true);
    }
    setIsLoading(false);
  };

  return application ? (
    <div className="mt-2 px-4">
      <div style={{ margin: '10px 0 20px' }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={onAddVehicleClick}>
          Add Vehicle
        </Button>
      </div>
      <Row gutter={24}>
        {application.vehicles?.map((p, i) => (
          <Col span={8} key={p._id}>
            <Card
              type="inner"
              title={`Vehicle ${i + 1}`}
              extra={
                <Space>
                  <Tooltip title="Edit">
                    <a href="#" onClick={() => onEditClick(p)}>
                      <EditOutlined style={{ fontSize: 20 }} />
                    </a>
                  </Tooltip>
                  {application.vehicles.length > 1 ? (
                    <Tooltip title="Delete" placement="bottom">
                      <Popconfirm
                        title="Are you sure to delete this vehicle?"
                        onConfirm={() => onDelete(p._id)}>
                        <a>
                          <DeleteOutlined className="text-red-700 text-lg" />
                        </a>
                      </Popconfirm>
                    </Tooltip>
                  ) : null}
                </Space>
              }>
              <p>
                <Typography.Text strong style={{ display: 'block' }}>
                  Usage
                </Typography.Text>
                <Typography.Text className="mt-1">{p.usage || '--'}</Typography.Text>
              </p>

              <Row gutter={24} className="mt-3">
                <Col span={12}>
                  <p>
                    <Typography.Text strong style={{ display: 'block' }}>
                      Price
                    </Typography.Text>
                    <Typography.Text>
                      {p.purchasePrice ? currency(p.purchasePrice) : '--'}
                    </Typography.Text>
                  </p>
                </Col>
              </Row>
              <Row gutter={24} className="mt-3">
                <Col span={12}>
                  <p>
                    <Typography.Text strong style={{ display: 'block' }}>
                      Vehicle Usage
                    </Typography.Text>
                    <Typography.Text>{p.usage || '--'}</Typography.Text>
                  </p>
                </Col>
                <Col span={12}>
                  {/* <p>
                      <Typography.Text strong style={{ display: 'block' }}>Construction Application</Typography.Text>
                      <Typography.Text>{(p.isConstruction) ? <CheckCircleFilled style={{ color: 'green', fontSize: 22 }} /> :
                        <CloseCircleFilled style={{ color: 'red', fontSize: 22 }} />
                      }</Typography.Text>
                    </p> */}
                </Col>
              </Row>
              <Divider className="my-2" />
              <Typography.Text strong style={{ display: 'block', marginBottom: 10 }}>
                Ownerships
              </Typography.Text>
              {p.ownership?.map((ow, i) => (
                <Row gutter={24} key={i} className="mb-1">
                  <Col span={18}>
                    <p>
                      {
                        application.applicants.find((a) => a._id === ow.applicant)?.userInfo
                          .firstName
                      }
                    </p>
                  </Col>
                  <Col span={4} style={{ textAlign: 'right' }}>
                    {' '}
                    {ow.percentage}%
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>
        ))}
      </Row>
      <Drawer
        open={isVehicleDrawerVisible}
        title="Vehicle Detail"
        closable
        onClose={() => setIsVehicleDrawerVisible(false)}
        width={600}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="usage" label="Usage" rules={[{ required: true }]}>
                <Select placeholder="Select Usage">
                  {USAGES.map((t) => (
                    <Select.Option key={t} value={t}>
                      {t}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Typography.Title level={4}>Ownership</Typography.Title>
          <Divider className="m-2 mb-4" />
          <Form.List name="ownership">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={12} key={key}>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        name={[name, 'applicant']}
                        rules={[{ required: true, message: 'Missing applicant' }]}>
                        <Select>
                          {application?.applicants?.map((a) => (
                            <Select.Option key={a._id}>
                              {getApplicantFullName(a.userInfo)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Space
                        key={key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'percentage']}
                          rules={[{ required: true, message: 'Missing percentage' }]}>
                          <InputNumber
                            className="w-full"
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace('%', '')}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Owner
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Form>
      </Drawer>
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default Vehicle;
