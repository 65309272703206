import { Spin } from 'antd';

function LpLoader() {
  return (
    <div className="flex h-screen">
      <div className="w-14 m-auto">
        <Spin size="large" />
      </div>
    </div>
  );
}

export default LpLoader;
