import { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  Row,
  Select
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useSWR, { mutate } from 'swr';
import dayjs from 'dayjs';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { LivingSituations, State } from '../../../types';
import { Countries } from '../../../helpers/countries';

import { API_HOST } from '../../../helpers/constants';
import { fetcher } from '../../../services/http';
import { openNotification } from '../../../helpers';
import AddressCard from './AddressCard';
import { getTotalDuration } from '../../../utils/index';

function Address() {
  const [form] = Form.useForm();
  const [isAddressDrawerVisible, setIsAddressDrawerVisible] = useState(false);
  const { applicationId, applicantId } = useParams();
  const [applicant, setApplicant] = useState(null);
  const [address, setAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDurationAlertVisible, setIsDurationAlertVisible] = useState(false);
  const [mode, setMode] = useState('add');
  const url = `${API_HOST}/applications/${applicationId}`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      const applicant = responseData.applicants.find((a) => a._id === applicantId);

      setApplicant(applicant);

      const { yearsDuration } = getTotalDuration(applicant, 'addresses');
      setIsDurationAlertVisible(yearsDuration < 3);

      form.setFieldsValue({
        ...applicant,
        email: applicant.userInfo.email,
        mobile: applicant.userInfo.mobile,
        dateOfBirth: applicant.dateOfBirth ? dayjs(applicant.dateOfBirth) : null,
        driverLicenceExpiry: applicant.driverLicenceExpiry
          ? dayjs(applicant.driverLicenceExpiry)
          : null
      });
    }
  }, [responseData, form, applicantId]);

  const onSubmit = async (values) => {
    let durl = `${url}/applicants/${applicantId}/addresses`;
    let method = 'post';
    if (mode === 'edit') {
      durl = `${durl}/${address._id}`;
      method = 'put';
    }

    try {
      setIsLoading(true);
      await axios[method](durl, values);
      openNotification('Saved Address', 'Saved Address');
      form.resetFields();
      setIsAddressDrawerVisible(false);
      mutate(url);
    } catch (e) {
      openNotification('Saving Failed', 'Cound not Save Address', true);
      console.log(e.message);
    }
    setIsLoading(false);
  };

  const onEditClick = (address) => {
    setAddress(address);
    setMode('edit');
    form.setFieldsValue(address);
    setIsAddressDrawerVisible(true);
  };

  const onDeleteClick = async (addressId) => {
    try {
      await axios.delete(`${url}/applicants/${applicantId}/addresses/${addressId}`);
      openNotification('Deleted Address', 'Address deleted successfully');
      form.resetFields();
      setAddress(null);
      setMode('add');
      mutate(url);
    } catch (e) {
      openNotification('Deletion Failed', 'Cound not Delete Address', true);
      console.log(e.message);
    }
  };

  return (
    <div>
      <div style={{ paddingRight: 20 }}>
        {isDurationAlertVisible && (
          <Alert
            message="Minimum 3 years of residency is required"
            type="warning"
            showIcon
            style={{ margin: '15px 0' }}
          />
        )}
        {applicant?.addresses?.map((address) => (
          <AddressCard
            key={address._id}
            address={address}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
        {applicant?.addresses?.length === 0 ? <Empty /> : null}
        <Button
          type="default"
          className="mt-4"
          icon={<PlusOutlined />}
          onClick={() => setIsAddressDrawerVisible(true)}>
          Add Address
        </Button>

        <Drawer
          title="Address Details"
          width={800}
          visible={isAddressDrawerVisible}
          onClose={() => setIsAddressDrawerVisible(false)}
          closable>
          <Form layout="vertical" form={form} onFinish={onSubmit}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label="Address" name="address">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} />
            </Row>
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item label="Unit Number" name="unitNumber">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Street Number" name="streetNumber" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Street Name" name="streetName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Suburb" name="city" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="State" name="state" rules={[{ required: true }]}>
                  <Select>
                    {Object.keys(State).map((key) => (
                      <Select.Option key={key} value={key}>
                        {State[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Postcode" name="postcode" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[{ required: true }]}
                  initialValue="AUS">
                  <Select>
                    {Countries.map((c) => (
                      <Select.Option key={c.code} value={c.code}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ marginTop: 5 }} />
            <Row gutter={24}>
              <Col span={6}>
                <Form.Item
                  label="Time at address (Years)"
                  name="yearsDuration"
                  rules={[{ required: true }]}>
                  <Select>
                    {[...Array(50)].map((x, i) => (
                      <Select.Option key={i} value={i}>
                        {i}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Time at address (Mths)"
                  name="monthsDuration"
                  rules={[{ required: true }]}>
                  <Select>
                    {[...Array(12)].map((x, i) => (
                      <Select.Option key={i} value={i}>
                        {i}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Living Situation"
                  name="livingSituation"
                  rules={[{ required: true }]}>
                  <Select>
                    {Object.keys(LivingSituations).map((key) => (
                      <Select.Option key={key} value={key}>
                        {LivingSituations[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Current ?" name="isCurrent" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ marginBottom: 20 }} />
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </Form>
        </Drawer>
      </div>
    </div>
  );
}

export default Address;
