/* eslint-disable react/prop-types */
import { Button, Col, Popconfirm, Row, Space, Table, Tag } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { openNotification } from '../../helpers';
import { API_HOST } from '../../helpers/constants';
import { fetcher, getLeadStatusColor, getShortId, headers } from '../../utils';
import { Actions } from '../../types';
import TaskModal from '../common/TaskModal';

function Tasks({ entity = 'lead' }) {
  const params = useParams();
  const entityId = params[`${entity}Id`];
  const [tasks, setTasks] = useState([]);
  const [meta, setMeta] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [task, setTask] = useState(null);
  const [mode, setMode] = useState(Actions.add);

  const url = `${API_HOST}/tasks?${entity}=${entityId}`;
  const { data: responseData } = useSWR(entityId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setTasks(responseData.data);
      setMeta(responseData.meta);
    }
  }, [responseData]);

  const onEditTask = async (id) => {
    try {
      const { data: taskData } = await axios.get(`${API_HOST}/tasks/${id}`, { headers });
      setIsModalVisible(true);
      setMode(Actions.edit);
      setTask({ ...taskData, dueAt: taskData.dueAt ? dayjs(taskData.dueAt) : null });
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteClick = async (id) => {
    try {
      await axios.delete(`${API_HOST}/tasks/${id}`, { headers });
      mutate(url);
      openNotification('Success', 'Task deleted Successfully');
    } catch (error) {
      openNotification('Error', 'Cannot delete Tasks', true);
      console.log(error);
    }
  };

  const fetchData = async (params = {}) => {
    const { data: responseData } = await axios(url, { params, headers });

    const { data, meta } = responseData;
    setTasks(data);
    setMeta(meta);
  };

  const onTableChange = async (pagination, filters, sorter) => {
    const options = {
      // term: searchText,
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      sort: sorter.order === 'descend' ? `-${sorter.field}` : sorter.field,
      ...filters
    };

    await fetchData(options);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'shortId',
      key: 'shortId',
      width: '5%',
      render: (text, row) => (
        <Button type="link" onClick={() => onEditTask(row._id)}>
          {getShortId(row._id)}
        </Button>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '13%',
      key: 'type'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '13%',
      render: (text) => <Tag color={getLeadStatusColor(text)}>{text}</Tag>
    },
    {
      title: 'Due At',
      dataIndex: 'dueAt',
      key: 'dueAt',
      width: '13%',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '13%',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    },
    {
      title: 'Action',
      key: 'action',
      width: '5%',
      render: (text, row) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEditTask(row._id)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onDeleteClick(row._id)}>
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div className="mx-5 my-8">
      <Row gutter={24}>
        <Col span={12}>
          <h3 className="text-xl">Tasks</h3>
        </Col>
        <Col span={12} className="text-right">
          <Button
            icon={<PlusOutlined />}
            className="mb-2"
            type="primary"
            onClick={() => {
              setIsModalVisible(true);
              setTask(null);
              setMode(Actions.add);
            }}>
            Add Task
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={tasks}
        columns={columns}
        onChange={onTableChange}
        rowKey={(row) => row.shortId}
        pagination={{
          total: meta?.total,
          pageSize: meta?.limit
        }}
      />
      <TaskModal
        isOpen={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        entity={entity}
        entityId={entityId}
        mode={mode}
        task={task}
        onSubmissionCallback={() => {
          mutate(url);
          setIsModalVisible(false);
          setMode(Actions.add);
          setTask(null);
        }}
      />
    </div>
  );
}

export default Tasks;
