/* eslint-disable react/prop-types */
import { Button, Col, Drawer, Form, Input, InputNumber, Row, Select } from 'antd';
import { FREQUENCY_ANNUALLY, FREQUENCY_MONTHLY } from '../../../types';

export default function InsuranceDrawer({ onClose, isOpen, onSave, isSubmitting, data }) {
  return (
    <Drawer title="Insurance" onClose={onClose} open={isOpen}>
      <Form layout="vertical" onFinish={onSave} initialValues={data}>
        <Row>
          <Col span={24}>
            <Form.Item label="Provider" name="provider" rules={[{ required: true }]}>
              <Select>
                <Select.Option value="Demo Provider">Demo Provider</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Policy No." name="policyNumber" rules={[{ required: true }]}>
              <Input className="w-full" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Frequency" name="frequency" rules={[{ required: true }]}>
              <Select>
                <Select.Option value={FREQUENCY_MONTHLY}>{FREQUENCY_MONTHLY}</Select.Option>
                <Select.Option value={FREQUENCY_ANNUALLY}>{FREQUENCY_ANNUALLY}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Premium Amount" name="premiumAmount" rules={[{ required: true }]}>
              <InputNumber className="w-full" addonBefore="$" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Commission Amount"
              name="commissionAmount"
              rules={[{ required: true }]}>
              <InputNumber className="w-full" addonBefore="$" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="text-right">
              <Button htmlType="submit" type="primary" loading={isSubmitting}>
                Save Insurance
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
