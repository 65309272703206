import { useState } from 'react';
import { LEAD_STATUSES } from '../../../helpers/constants';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useLeads } from '../../../hooks/useLeads';
import LeadStatusCard from './LeadStatusCard';
import { getLoggedInUser } from '../../../utils';

function LeadStatusBoard() {
  const [dateRange, setDateRange] = useState([dayjs().add(-3, 'M'), dayjs()]);
  const loggedInUser = getLoggedInUser();

  const params = {
    dateRange
  };

  if (loggedInUser.role === 'broker') {
    params['owner'] = loggedInUser.id;
  }

  const { leads } = useLeads(params);

  const onDateRangeChange = (v) => {
    setDateRange(v);
  };

  return (
    <div>
      <div className="my-2">
        <DatePicker.RangePicker defaultValue={dateRange} onChange={onDateRangeChange} />
      </div>
      <div className="flex space-x-1 overflow-auto min-h-screen">
        {LEAD_STATUSES.map((leadStatus) => (
          <div key={leadStatus} className="w-60 bg-gray-200 p-2 flex-shrink-0">
            <div title={leadStatus}>
              <p className="bg-white p-1 text-center font-bold">{leadStatus}</p>
              {/* <p className='mb-2'>5.00 M</p> */}
              <div className="flex flex-col space-y-2">
                {leads
                  ?.filter((lead) => lead.status === leadStatus)
                  .map((lead) => (
                    <LeadStatusCard key={lead._id} lead={lead} />
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LeadStatusBoard;
