/* eslint-disable react/prop-types */
import { MailFilled, PhoneFilled } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Tag } from 'antd';
import {
  Actions,
  TASK_STATUSES,
  TASK_STATUS_TODO,
  TASK_TYPES,
  TASK_TYPE_CALL,
  VERB_POST,
  VERB_PUT
} from '../../types';
import { API_HOST, APPLICANT_PRIMARY } from '../../helpers/constants';
import axios from 'axios';
import { fetcher, getDomainIcon, headers } from '../../utils';
import { openNotification } from '../../helpers';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import useSWR from 'swr';
import { Link, useLocation } from 'react-router-dom';

function TaskModal({ isOpen, onCancel, entity, entityId, mode, onSubmissionCallback, task }) {
  const [form] = Form.useForm();
  const location = useLocation();
  const entityUrl = `${API_HOST}/${entity}s/${entityId}`;
  const { data: entityData } = useSWR(entityUrl, fetcher);

  let userData;
  let application;
  if (entity == 'lead') {
    userData = entityData?.application.applicants.find(
      (applicant) => (applicant.type = APPLICANT_PRIMARY)
    ).userInfo;
    application = entityData?.application;
  } else if (entity == 'application') {
    userData = entityData?.user[0];
    application = entityData;
  }
  useEffect(() => {
    if (task) {
      form.setFieldsValue(task);
    } else {
      form.resetFields();
      if (mode === Actions.add) {
        form.setFieldsValue({
          type: TASK_TYPE_CALL,
          status: TASK_STATUS_TODO,
          dueAt: dayjs()
        });
      }
    }
  }, [task, mode]);

  const onTaskSubmit = async (values) => {
    // eslint-disable-next-line no-param-reassign
    values[entity] = entityId;

    const verb = mode === Actions.add ? VERB_POST : VERB_PUT;
    const pUrl = mode === Actions.add ? `${API_HOST}/tasks` : `${API_HOST}/tasks/${task?._id}`;

    try {
      await axios[verb](pUrl, values, { headers: headers });
      openNotification('Success', 'Task Saved Successfully');
      onSubmissionCallback();
    } catch (error) {
      openNotification('Error', 'Cannot save Tasks', true);
    }
  };
  return (
    <Modal
      title={
        <div className="flex gap-2 items-center">
          <p>Task</p>
          <p>
            <Tag color="blue">{`${entity.charAt(0).toUpperCase()}${entity.slice(1)}`}</Tag>
          </p>
        </div>
      }
      open={isOpen}
      width={1000}
      onOk={form.submit}
      onCancel={onCancel}>
      <Row gutter={12}>
        <Col span={5}>
          <div className="h-full border-r">
            {getDomainIcon(application?.domain)}
            <h2 className="text-xl mb-2.5 mt-2">
              {userData?.firstName} {userData?.lastName}
            </h2>
            <p>
              <PhoneFilled className="text-base !align-text-bottom" /> {userData?.mobile}
            </p>
            <p>
              <MailFilled className="text-base !align-text-bottom" /> {userData?.email || '--'}
            </p>

            {!location.pathname.includes('/tasks') && (
              <Button>
                <Link to={`/${entity}s/${entityId}`}>Open {entity}</Link>
              </Button>
            )}
          </div>
        </Col>
        <Col span={18}>
          <Form form={form} onFinish={onTaskSubmit} layout="vertical">
            <Row gutter={24}>
              <Col span={18}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: 'Required' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="description" label="Description">
                  <Input.TextArea rows={3} />
                </Form.Item>
                <Form.Item name="outcome" label="Outcome">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[{ required: true, message: 'Required' }]}>
                  <Select options={TASK_TYPES.map((t) => ({ value: t, label: t }))} />
                </Form.Item>
                <Form.Item name="status" label="Status">
                  <Select options={TASK_STATUSES.map((t) => ({ value: t, label: t }))} />
                </Form.Item>
                <Form.Item
                  name="dueAt"
                  label="Due At"
                  rules={[{ required: true, message: 'Required' }]}>
                  <DatePicker showTime format="DD/MM/YYYY hh:mm a" className="w-full" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

export default TaskModal;
