import { Avatar, Button, Card, Form, Input, List, Typography } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { openNotification } from '../../helpers';
import { API_HOST } from '../../helpers/constants';
import { fetcher, headers } from '../../utils';

function Notes(props) {
  const { applicationId, leadId } = useParams();
  const [parentEntity, setParentEntity] = useState();
  const [form] = Form.useForm();

  const id = leadId || applicationId;
  const parent = leadId ? 'leads' : applicationId ? 'applications' : 'accounts';

  const url = `${API_HOST}/${parent}/${id}`;

  const { data: responseData } = useSWR(id ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setParentEntity(responseData);
    }
  }, [responseData]);

  const onNoteSubmit = async (values) => {
    try {
      await axios.post(`${API_HOST}/${parent}/${id}/notes`, values, { headers });
      openNotification('Success', 'Note Saved Successfully');
      mutate(url);
      form.resetFields();
    } catch (error) {
      openNotification('Cannot save Notes', error.response?.data?.message, true);
    }
  };

  return (
    <div {...props}>
      <div>
        <Typography.Title level={4}>Notes</Typography.Title>
        <Card>
          <Form form={form} onFinish={onNoteSubmit}>
            <Form.Item name="description" rules={[{ required: true, message: 'Required' }]}>
              <Input.TextArea rows={3} />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </div>
          </Form>
        </Card>
        <List
          style={{ marginTop: 20 }}
          itemLayout="horizontal"
          dataSource={parentEntity?.notes || []}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                title={
                  <p className="text-xs text-gray-400">
                    {moment(item.createdAt).format('DD/MM/YYYY hh:mm a')} by ss
                  </p>
                }
                description={<p className="text-gray-600">{item.description}</p>}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

export default Notes;
