import { Button, Col, Row, Breadcrumb, Space, Table, Typography, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState } from 'react';
import { CheckCircleFilled, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { openNotification } from '../../helpers';
import { useBlogPosts } from '../../hooks/useBlogPosts';
import { deleteBlogPost } from '../../services/blog-posts.http';

function BlogPostsList() {
  const [allParams, setAllParams] = useState({});

  const { blogPosts, meta, isLoading, revalidate } = useBlogPosts(allParams);

  const onDeleteClick = async (id) => {
    try {
      await deleteBlogPost(id);
      revalidate();
      openNotification('Success', `Deleted successfully!`);
    } catch (e) {
      console.log(e);
    }
  };

  const onTableChange = async (pagination, filters, sorter) => {
    const options = {
      // term: searchText,
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      sort: sorter.order === 'descend' ? `-${sorter.field}` : sorter.field,
      ...filters
    };
    setAllParams(options);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, row) => {
        return (
          <div className="ml-2">
            <Link to={`/blog-posts/${row._id}/edit`} className="font-semibold">
              {text}
            </Link>
            <p className="text-sm text-gray-500">{row.slug}</p>
          </div>
        );
      }
    },
    {
      title: 'Blog',
      dataIndex: ['blogInfo', 'name'],
      key: 'blog'
    },
    {
      title: 'Active?',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (text) =>
        text ? (
          <CheckCircleFilled className="text-green-500 text-lg" />
        ) : (
          <CloseCircleOutlined className="text-red-500 text-lg" />
        )
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    },

    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/blog-posts/${record._id}/edit`}>Edit</Link>
          <Popconfirm
            title="Are you sure to delete?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onDeleteClick(record._id)}>
            <a href="#">Delete</a>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb items={[{ title: <Link to="/">Home</Link> }, { title: 'Blog Posts' }]} />
      <Row gutter={24} className="mt-4 mb-2">
        <Col span={12}>
          <Typography.Title level={4}>Blog Posts List</Typography.Title>
        </Col>
        <Col span={12} className="text-right">
          <Link to="/blog-posts/create">
            <Button type="primary" icon={<PlusOutlined />}>
              Add Blog Post
            </Button>
          </Link>
        </Col>
      </Row>

      <Table
        dataSource={blogPosts}
        columns={columns}
        size="middle"
        onChange={onTableChange}
        rowKey={(row) => row._id}
        pagination={{
          total: meta?.total,
          pageSize: meta?.limit
        }}
        loading={isLoading}
      />
    </div>
  );
}

export default BlogPostsList;
