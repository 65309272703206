/* eslint-disable react/prop-types */
import { CheckOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import dayjs from 'dayjs';

export default function SettlementChecklistItem({ data, onChecklistStateUpdate }) {
  const onMarkBtnClick = () => {
    if (data.reviewedAt) onChecklistStateUpdate({ title: data.title });
    else onChecklistStateUpdate({ title: data.title, reviewedAt: new Date().toISOString() });
  };
  return (
    <Row>
      <Col span={2}>
        {data.reviewedAt ? (
          <CheckOutlined className="!text-green-400 font-extrabold !text-2xl" />
        ) : (
          <WarningFilled className="!text-yellow-400 !text-lg" />
        )}
      </Col>
      <Col span={14}>
        <p className="m-0 font-semibold">{data?.title}</p>
        <p>{data.reviewedAt ? dayjs(data.reviewedAt).format('DD/MM/YYYY hh:mm a') : '--'}</p>
      </Col>
      <Col span={8}>
        {!data.reviewedAt ? (
          <Button type="primary" size="small" onClick={onMarkBtnClick}>
            Mark as reviewed
          </Button>
        ) : (
          <Button size="small" onClick={onMarkBtnClick}>
            Unmark
          </Button>
        )}
      </Col>
    </Row>
  );
}
