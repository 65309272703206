/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Drawer,
  Menu,
  Row,
  Skeleton,
  Typography,
  Form,
  Input,
  Select,
  Alert
} from 'antd';
import { UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import axios from 'axios';
import {
  API_HOST,
  STATUS_AWAITING_DOCS,
  STATUS_NEW,
  STATUS_READY_FOR_SUBMISSION,
  STATUS_STARTED
} from '../../../helpers/constants';
import { Title } from '../../../types';
import { openNotification } from '../../../helpers';
import { fetcher } from '../../../utils';

const { SubMenu } = Menu;

function ApplicantsLayout({ children }) {
  const location = useLocation();
  const [isAddApplicantDrawerVisible, setIsAddApplicantDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const { leadId, applicationId, applicantId } = useParams();
  const [application, setApplication] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const url = `${API_HOST}/applications/${applicationId}`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);
  const isLeadView = location.pathname.startsWith('/leads');

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
    }
  }, [responseData]);

  const getUrlPrefix = (url) => {
    return leadId ? `/leads/${leadId}${url}` : url;
  };

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      await axios.post(`${API_HOST}/applications/${applicationId}/applicants`, values);
      form.resetFields();
      setIsAddApplicantDrawerVisible(false);
      openNotification('Saved Applicant', 'Saved Applicant');
      mutate(url);
    } catch (e) {
      openNotification('Error Saving', 'something broke', true);
    }
    setIsLoading(false);
  };

  const isAddingApplicantDisabled = () => {
    return ![
      STATUS_NEW,
      STATUS_STARTED,
      STATUS_AWAITING_DOCS,
      STATUS_READY_FOR_SUBMISSION
    ].includes(application.status);
  };

  // eslint-disable-next-line no-nested-ternary
  const selectedKey = location.pathname.endsWith('/employment')
    ? `${applicantId}-employment`
    : location.pathname.includes('/address')
      ? `${applicantId}-address`
      : `${applicantId}-personal`;

  return application ? (
    <div className="bg-white">
      <Row gutter={24}>
        <Col span={4}>
          <Menu selectedKeys={selectedKey} defaultOpenKeys={[applicantId]} mode="inline">
            {application?.applicants?.map((applicant) => {
              return (
                <SubMenu
                  key={applicant._id}
                  icon={<UserOutlined />}
                  title={
                    <div>
                      <strong>{applicant?.userInfo?.firstName}</strong>
                      <Typography.Text type="secondary" className="ml-2 text-xs">
                        ({applicant.type})
                      </Typography.Text>
                    </div>
                  }>
                  <Menu.Item key={`${applicant._id}-personal`}>
                    <Link
                      to={getUrlPrefix(
                        `/applications/${applicationId}/applicants/${applicant._id}/personal`
                      )}>
                      Personal
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={`${applicant._id}-addresses`}>
                    <Link
                      to={getUrlPrefix(
                        `/applications/${applicationId}/applicants/${applicant._id}/addresses`
                      )}>
                      Address
                    </Link>
                  </Menu.Item>
                  <Menu.Item key={`${applicant._id}-employments`}>
                    <Link
                      to={getUrlPrefix(
                        `/applications/${applicationId}/applicants/${applicant._id}/employments`
                      )}>
                      Employment
                    </Link>
                  </Menu.Item>
                </SubMenu>
              );
            })}
          </Menu>
          <Button
            icon={<UserAddOutlined />}
            style={{ margin: '20px 30px' }}
            disabled={isAddingApplicantDisabled() && !isLeadView}
            onClick={() => setIsAddApplicantDrawerVisible(true)}>
            Add Applicant
          </Button>
          {isAddingApplicantDisabled() && !isLeadView ? (
            <Alert
              type="warning"
              showIcon
              className="ml-4"
              description={"Can't add applicant due to application status"}
            />
          ) : null}
        </Col>
        <Col span={20}>
          <div className="my-2">{children}</div>
        </Col>
        <Drawer
          width={500}
          title="Add Applicant"
          open={isAddApplicantDrawerVisible}
          onClose={() => setIsAddApplicantDrawerVisible(false)}
          closable>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Row gutter={8}>
              <Col span={5}>
                <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                  <Select>
                    {Object.keys(Title).map((key) => (
                      <Select.Option key={key} value={key}>
                        {Title[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={19}>
                <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Middle Name" name="middleName">
              <Input />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Mobile" name="mobile" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Button type="primary" loading={isLoading} htmlType="submit">
              Save
            </Button>
          </Form>
        </Drawer>
      </Row>
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default ApplicantsLayout;
