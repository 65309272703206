import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  message
} from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import dayjs from 'dayjs';
import TagInput from 'antd-tag-input';
import 'antd-tag-input/dist/style.css';
import { UploadOutlined } from '@ant-design/icons';
import * as slugify from 'slugify';
import { Actions } from '../../types';
import { fileToBase64, openNotification } from '../../helpers';
import { useBlogPost } from '../../hooks/useBlogPost';
import { useBlogs } from '../../hooks/useBlogs';
import { useBlogAuthors } from '../../hooks/useBlogAuthors';
import { createBlogPost, updateBlogPost } from '../../services/blog-posts.http';

const { Dragger } = Upload;

function BlogPostForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );
  const [tags, setTags] = useState([]);
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [image, setImage] = useState();

  const { id } = params;

  const { blogPost } = useBlogPost(id);
  const { blogAuthors } = useBlogAuthors();
  const { blogs } = useBlogs();
  const editor = useRef();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  useEffect(() => {
    if (blogPost) {
      form.setFieldsValue({
        ...blogPost,
        publishedAt: blogPost.publishedAt ? dayjs(blogPost.publishedAt) : null
      });
      setImage({ url: blogPost.imgUrl });
    }
  }, [blogPost]);

  const onSlugifyClick = () => {
    const slug = slugify(form.getFieldValue('title'), {
      lower: true
    });
    form.setFieldsValue({ slug });
  };

  useEffect(() => {
    if (mode === Actions.add) {
      form.setFieldsValue({ publishedAt: dayjs() });
    }
  }, [form]);

  const onSubmit = async (values) => {
    console.log(values);
    // return;
    try {
      const { data } =
        mode === Actions.add ? await createBlogPost(values) : await updateBlogPost(id, values);
      navigate(`/blog-posts/${data._id}/edit`);
      setMode(Actions.edit);
      openNotification('Success', 'Blog Post saved successfully!');
    } catch (error) {
      openNotification('Error', 'Blog Post saving failed!', true);
      console.log(error);
    }
  };

  const getProps = () => ({
    name: 'file',
    multiple: false,
    // headers,
    showUploadList: false,
    accept: '.jpg,.png,.gif',
    async onChange(info) {
      const { status } = info.file;
      if (status === 'uploading') {
        let base64;
        let file;
        for (const f of info.fileList) {
          base64 = await fileToBase64(f.originFileObj);

          file = { url: URL.createObjectURL(f.originFileObj) };
        }
        setImage(file);
        form.setFieldsValue({ img: base64 });
      } else if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    }
  });

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/blog-posts">Blog Posts</Link> },
          { title: 'Blog Post' }
        ]}
      />
      <Typography.Title level={4}>Blog Post</Typography.Title>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={16}>
            <div className="bg-white p-5 shadow-lg rounded-md">
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Button onClick={onSlugifyClick} className="mb-4">
                Fill Slug
              </Button>
              <Form.Item label="Slug" name="slug" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Content" name="content">
                <SunEditor
                  height="250px"
                  getSunEditorInstance={getSunEditorInstance}
                  placeholder="Please type here..."
                  setContents={form.getFieldValue('content')}
                  setOptions={{
                    buttonList: [
                      [
                        'formatBlock',
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'fontColor',
                        'hiliteColor',
                        'list',
                        'table',
                        'link'
                      ],
                      ['fullScreen']
                    ]
                  }}
                />
              </Form.Item>
            </div>

            <div className="bg-white mt-4 p-5 shadow-lg rounded-md">
              <h2 className="text-xl">Meta information</h2>
              <Form.Item label="Title" name="metaTitle">
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Description" name="metaDescription">
                <Input.TextArea size="large" rows={3} />
              </Form.Item>
              <Form.Item label="Keywords" name="metaKeywords">
                <TagInput
                  value={metaKeywords}
                  placeholder="please fill in..."
                  onChange={(strArr) => setMetaKeywords(strArr)}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={6}>
            <div className="bg-white p-5 shadow-lg rounded-md">
              <Dragger {...getProps()}>
                <div className="block">
                  <div className="mt-2 flex flex-col space-y-4">
                    <UploadOutlined className="text-4xl text-gray-400" />
                    Upload
                  </div>
                </div>
              </Dragger>
              {image?.url ? (
                <div className="my-3 border p-1">
                  <img src={image?.url} className="w-full" alt="" />
                </div>
              ) : null}
              <Form.Item name="img" />
              <Form.Item label="Blog" name="blog" rules={[{ required: true, message: 'Required' }]}>
                <Select
                  size="large"
                  options={blogs?.map((b) => ({ value: b._id, label: b.name }))}
                />
              </Form.Item>
              <Form.Item
                label="Author"
                name="author"
                rules={[{ required: true, message: 'Required' }]}>
                <Select
                  size="large"
                  options={blogAuthors?.map((b) => ({ value: b._id, label: b.name }))}
                />
              </Form.Item>
              <Form.Item
                label="Publish At"
                name="publishedAt"
                valuePropName={'checked'}
                rules={[{ required: true, message: 'Required' }]}>
                <DatePicker size="large" format="DD/MM/YYYY hh:mm a" showTime />
              </Form.Item>
              <Form.Item label="Tags" name="tags">
                <TagInput
                  value={tags}
                  placeholder="please fill in..."
                  onChange={(strArr) => setTags(strArr)}
                />
              </Form.Item>
              <Form.Item label="Active" name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={12}>
            <Button htmlType="submit" type="primary" size="large">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default BlogPostForm;
