import { CarTwoTone, HomeTwoTone } from '@ant-design/icons';
import {
  STATUS_ADDITIONAL_INFO,
  STATUS_APPROVED,
  STATUS_AWAITING_DOCS,
  STATUS_CONDITIONALLY_APPROVED,
  STATUS_CONTACTED,
  STATUS_DECLINED,
  STATUS_INTERESTED,
  STATUS_LOST,
  STATUS_NEW,
  STATUS_PRE_SETTLEMENT,
  STATUS_QUALIFIED,
  STATUS_READY_FOR_SUBMISSION,
  STATUS_SETTLED,
  STATUS_STARTED,
  STATUS_SUBMITTED_TO_LENDER,
  STATUS_UNQUALIFIED,
  STATUS_WITHDRAWN,
  STATUS_WON
} from '../helpers/constants';
import { Domain, Frequency } from '../types';

export const toCurrency = (number, maximumFractionDigits = 0) =>
  new Intl.NumberFormat('en-AU', {
    maximumFractionDigits,
    style: 'currency',
    currency: 'AUD',
    currencyDisplay: 'narrowSymbol'
  }).format(number);

export const extractNumeric = (text) => text.replace(/\D+/g, '');

export function isJsonParsable(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getTotalDuration = (applicant, arrayElementName) => {
  let yearsDuration = 0;
  let monthsDuration = 0;
  applicant[arrayElementName].forEach((element) => {
    yearsDuration += element.yearsDuration;
    monthsDuration += element.monthsDuration;
  });
  if (monthsDuration > 11) {
    monthsDuration -= 12;
    // eslint-disable-next-line no-plusplus
    yearsDuration++;
  }
  return { yearsDuration, monthsDuration };
};

export const clearStorageAndRedirect = () => {
  window.localStorage.removeItem('loanspad');
  window.location.href = '/auth/login';
};

export const getLocalStorageData = () => {
  const loanspadStr = window.localStorage.getItem('loanspad');
  let loanspad;
  if (isJsonParsable(loanspadStr)) {
    loanspad = JSON.parse(loanspadStr);
  }
  return loanspad;
};

export const getLoggedInUser = () => {
  const ls = getLocalStorageData();
  if (!ls) return null;

  return ls.user;
};

export function getShortId(id = '') {
  return id.slice(-6);
}

export const getToken = () => {
  const ls = getLocalStorageData();
  if (!ls) return null;

  return ls.token;
};

export const token = getToken();

export const headers = { Authorization: `Bearer ${token}` };

export const fetcher = (url) =>
  fetch(url, { headers }).then((r) => {
    if (r.status === 401) {
      clearStorageAndRedirect();
    }
    return r.json();
  });

export const currency = (amount) =>
  Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0
  }).format(amount);

export function calculatePerMonthAmount(amount, frequency) {
  if (!amount) return 0;

  let amountPerMonth = 0;
  if (frequency === Frequency.Yearly) {
    amountPerMonth = amount / 12;
  } else if (frequency === Frequency.Monthly) {
    amountPerMonth = amount;
  } else if (frequency === Frequency.Fortnightly) {
    amountPerMonth = (amount * 26) / 12;
  } else if (frequency === Frequency.Weekly) {
    amountPerMonth = (amount * 52) / 12;
  }

  return amountPerMonth.toFixed(2);
}

export function getLeadStatusColor(status) {
  const colorMap = {
    [STATUS_NEW]: 'blue',
    [STATUS_CONTACTED]: 'cyan',
    [STATUS_INTERESTED]: 'gold',
    [STATUS_UNQUALIFIED]: 'red',
    [STATUS_QUALIFIED]: 'green',
    [STATUS_LOST]: 'red',
    [STATUS_WON]: '#87d068'
  };
  return colorMap[status];
}

export function getApplicationStatusColor(status) {
  const colorMap = {
    [STATUS_NEW]: 'gray',
    [STATUS_STARTED]: 'blue',
    [STATUS_AWAITING_DOCS]: 'blue',
    [STATUS_READY_FOR_SUBMISSION]: 'blue',
    [STATUS_SUBMITTED_TO_LENDER]: 'blue',
    [STATUS_APPROVED]: 'blue',
    [STATUS_CONDITIONALLY_APPROVED]: 'blue',
    [STATUS_DECLINED]: 'red',
    [STATUS_ADDITIONAL_INFO]: 'blue',
    [STATUS_PRE_SETTLEMENT]: 'blue',
    [STATUS_SETTLED]: '#87d068',
    [STATUS_WITHDRAWN]: 'blue'
  };
  return colorMap[status];
}

export function getDomainIcon(loanType) {
  if (loanType === Domain.Car) {
    return <CarTwoTone className="text-2xl" twoToneColor="#999" />;
  }
  if (loanType === Domain.Home) {
    return <HomeTwoTone className="text-2xl" twoToneColor="#999" />;
  }
  return null;
}

export const isAdmin = (user) => user.role.toLowerCase() === 'admin';
export const isBroker = (user) => user.role.toLowerCase() === 'broker';
export const isCustomer = (user) => user.role.toLowerCase() === 'customer';
