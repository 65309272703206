import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Typography
} from 'antd';
import { memo } from 'react';
import PropTypes from 'prop-types';
function LoanDetailsRateFeesSection({
  rowGutter,
  isReadOnly,
  data,
  showEditLinks = false,
  onEditClick = () => {}
}) {
  const loanDetailsItems = data && [
    {
      key: 'purchasePrice',
      label: 'Purchase price',
      children: <p className="font-semibold">$ {data.purchasePrice}</p>,
      span: 1
    },
    {
      key: 'deposit',
      label: 'Deposit',
      children: <p className="font-semibold">$ {data.deposit}</p>,
      span: 1
    },
    {
      key: 'naf',
      label: 'NAF',
      children: <p className="font-semibold">$ {data.naf}</p>,
      span: 1
    },
    {
      key: 'ballonPercent',
      label: 'Balloon percent',
      children: <p className="font-semibold">{data.balloonPercent} %</p>,
      span: 1
    },
    {
      key: 'balloonAmount',
      label: 'Balloon amount',
      children: <p className="font-semibold">$ {data.balloonAmount}</p>,
      span: 1
    },
    {
      key: 'loanTerm',
      label: 'Loan term',
      children: <p className="font-semibold">{data.loanTerm}</p>,
      span: 1
    }
  ];

  const rateAndFeeItems = data && [
    {
      key: 'baseRate',
      label: 'Base rate',
      children: <p className="font-semibold">{data.baseRate} %</p>,
      span: 1
    },
    {
      key: 'customerRate',
      label: 'Customer rate',
      children: <p className="font-semibold">{data.customerRate} %</p>,
      span: 1
    },
    {
      key: 'monthlyRepayment',
      label: 'Monthly repayment',
      children: <p className="font-semibold">$ {data.monthlyRepayment}</p>,
      span: 1
    },
    {
      key: 'lenderMonthlyFee',
      label: 'Lender monthly fee',
      children: <p className="font-semibold">$ {data.lenderMonthlyFee}</p>,
      span: 1
    },
    {
      key: 'lenderFee',
      label: 'Lender fee',
      children: <p className="font-semibold">$ {data.lenderFee}</p>,
      span: 1
    },
    {
      key: 'ppsrFee',
      label: 'PPSR fee',
      children: <p className="font-semibold">$ {data.ppsrFee}</p>,
      span: 1
    },
    {
      key: 'originationFee',
      label: 'Origination fee',
      children: <p className="font-semibold">$ {data.originationFee}</p>,
      span: 1
    },
    {
      key: 'partnerFee',
      label: 'Partner referral fee',
      children: <p className="font-semibold">$ {data.partnerFee}</p>,
      span: 1
    },
    {
      key: 'lendingCommission',
      label: 'Lending commission',
      children: <p className="font-semibold">$ {data.lendingCommission}</p>,
      span: 1
    }
  ];

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        {/* <Typography.Title level={4}>Loan Details</Typography.Title> */}
        <div className="flex justify-between items-end gap-2 mb-2">
          <Typography.Title level={4} className="!mb-0">
            Loan Details
          </Typography.Title>
          {showEditLinks && (
            <Button type="link" size="small" className="!font-semibold" onClick={onEditClick}>
              Edit
            </Button>
          )}
        </div>
      </Divider>
      {isReadOnly ? (
        <Descriptions items={loanDetailsItems} layout="vertical" colon={false} bordered />
      ) : (
        <>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item label="Purchase price" name="purchasePrice" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Deposit" name="deposit" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item label="NAF" name="naf" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Balloon percent" name="balloonPercent" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonAfter="%" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item label="Balloon amount" name="balloonAmount" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Loan term" name="loanTerm" rules={[{ required: true }]}>
                <Select>
                  {new Array(7).fill('').map((_, i) => (
                    <Select.Option value={`${i + 1} year${i > 0 ? 's' : ''}`} key={i}>
                      {i + 1} year{i > 0 && 's'}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Divider orientation="left" orientationMargin="0">
        {/* <Typography.Title level={4}>Rate & Fees</Typography.Title> */}
        <div className="flex justify-between items-end gap-2 mb-2">
          <Typography.Title level={4} className="!mb-0">
            Rate & Fees
          </Typography.Title>
          {showEditLinks && (
            <Button type="link" size="small" className="!font-semibold" onClick={onEditClick}>
              Edit
            </Button>
          )}
        </div>
      </Divider>
      {isReadOnly ? (
        <Descriptions items={rateAndFeeItems} layout="vertical" colon={false} bordered />
      ) : (
        <>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item label="Base rate" name="baseRate" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Monthly repayment"
                name="monthlyRepayment"
                rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item label="Customer rate" name="customerRate" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Lender monthly fee"
                name="lenderMonthlyFee"
                rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item label="Lender fee" name="lenderFee" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="PPSR fee" name="ppsrFee" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item label="Origination fee" name="originationFee" rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Partner referral fee"
                name="partnerFee"
                rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item
                label="Lending commission"
                name="lendingCommission"
                rules={[{ required: true }]}>
                <InputNumber className="w-full" addonBefore="$" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default memo(LoanDetailsRateFeesSection);

LoanDetailsRateFeesSection.propTypes = {
  rowGutter: PropTypes.number,
  isReadOnly: PropTypes.bool,
  data: PropTypes.object,
  showEditLinks: PropTypes.bool,
  onEditClick: PropTypes.func
};
