import { Breadcrumb, Button, Col, Popconfirm, Row, Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState } from 'react';
import { CheckCircleFilled, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { openNotification } from '../../helpers';
import { useFaqs } from '../../hooks/useFaqs';
import { deleteFaq } from '../../services/faqs.http';

function FaqsList() {
  const [allParams, setAllParams] = useState({});
  const { faqs, meta, isLoading, revalidate } = useFaqs(allParams);

  const onDeleteClick = async (id) => {
    try {
      await deleteFaq(id);
      revalidate();
      openNotification('Success', `Deleted successfully!`);
    } catch (e) {
      console.log(e);
    }
  };

  const onTableChange = async (pagination, filters, sorter) => {
    const options = {
      // term: searchText,
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      sort: sorter.order === 'descend' ? `-${sorter.field}` : sorter.field,
      ...filters
    };

    setAllParams(options);
  };

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      render: (text, row) => {
        return (
          <div className="ml-2">
            <Link to={`/faqs/${row._id}/edit`} className="font-semibold">
              {text}
            </Link>
            <p className="text-sm text-gray-500">{row.slug}</p>
          </div>
        );
      }
    },

    {
      title: 'Active?',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (text) =>
        text ? (
          <CheckCircleFilled className="text-green-500 text-lg" />
        ) : (
          <CloseCircleOutlined className="text-red-500 text-lg" />
        )
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    },

    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/faqs/${record._id}/edit`}>Edit</Link>
          <Popconfirm
            title="Are you sure to delete?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onDeleteClick(record._id)}>
            <a href="#">Delete</a>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb items={[{ title: <Link to="/">Home</Link> }, { title: 'Faqs' }]} />
      <Row gutter={24} className="mt-4 mb-2">
        <Col span={12}>
          <Typography.Title level={4}>Faqs List</Typography.Title>
        </Col>
        <Col span={12} className="text-right">
          <Link to="/faqs/create">
            <Button type="primary" icon={<PlusOutlined />}>
              Add Faq
            </Button>
          </Link>
        </Col>
      </Row>
      <Table
        size="middle"
        dataSource={faqs}
        columns={columns}
        onChange={onTableChange}
        rowKey={(row) => row._id}
        pagination={{
          total: meta?.total,
          pageSize: meta?.limit
        }}
        loading={isLoading}
      />
    </div>
  );
}

export default FaqsList;
