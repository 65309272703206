import {
  Alert,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Typography,
  Button,
  Descriptions
} from 'antd';
import { memo, useEffect, useState } from 'react';
import {
  APPROVED,
  DECLINED,
  DECLINED_REASON_OTHER,
  DECLINED_REASONS,
  LOAN_DECISIONS,
  WITHDRAWN,
  WITHDRAWN_REASON_OTHER,
  WITHDRAWN_REASONS
} from '../../../types';
import LoanDetailsRateFeesSection from './LoanDetailsRateFeesSection';
import { useParams } from 'react-router-dom';
import { API_HOST } from '../../../helpers/constants';
import useSWR from 'swr';
import { fetcher, headers } from '../../../utils';
import dayjs from 'dayjs';
import axios from 'axios';
import { openNotification } from '../../../helpers';

function LoanDecision() {
  const [form] = Form.useForm();
  const [decision, setDecision] = useState(null);
  const [reason, setReason] = useState(null);

  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application, mutate } = useSWR(applicationUrl, fetcher);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submitDecisionEndpoint = `${applicationUrl}/submit-decision`;

  const submissionData = application?.loanDetails?.submission;
  const decisionData = application?.loanDetails?.decision;
  const isApplicationApproved = decisionData?.outcomeOfApplication == APPROVED;

  const approvalItems = decisionData && [
    {
      key: 'approvalExpiry',
      label: 'Expiry of the approval',
      children: (
        <p className="font-semibold">
          {dayjs(decisionData.approvalExpiry).format('DD/MM/YYYY hh:mm a')}
        </p>
      ),
      span: 1
    },
    {
      key: 'settlementEta',
      label: 'Settlement ETA',
      children: (
        <p className="font-semibold">
          {dayjs(decisionData.settlementEta).format('DD/MM/YYYY hh:mm a')}
        </p>
      ),
      span: 1
    },
    {
      key: 'approvalConditions',
      label: 'Conditions of approval',
      children: <p className="font-semibold">{decisionData.approvalConditions}</p>,
      span: 1
    },
    {
      key: 'hasLoanDetailsBeenUpdated',
      label: 'Has loan details been updated during approval?',
      children: (
        <p className="font-semibold">{decisionData.hasLoanDetailsBeenUpdated ? 'Yes' : 'No'}</p>
      ),
      span: 3
    }
  ];

  const submitDecision = async (data) => {
    try {
      setIsSubmitting(true);
      await axios.post(submitDecisionEndpoint, data, { headers });
      setIsSubmitting(false);
      openNotification('Decision data saved successfully');
      await axios.patch(applicationUrl, { status: data.outcomeOfApplication }, { headers });
      mutate();
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      openNotification('Could not save decision data', error.response?.data?.message, true);
    }
  };

  const hasLoanDetailsBeenUpdated = Form.useWatch('hasLoanDetailsBeenUpdated', form);

  useEffect(() => {
    setReason(null);
    form.setFieldValue('declinedReason', null);
    form.setFieldValue('withdrawnReason', null);
  }, [decision]);

  useEffect(() => {
    setDecision(decisionData?.outcomeOfApplication);
  }, [decisionData]);

  if (application && !submissionData)
    return (
      <Alert
        type="error"
        showIcon
        message="Please submit loan details first to access Decision tab"
        className="mt-5"
      />
    );

  return (
    <div className="pr-8 mt-4">
      {!decisionData && (
        <Alert
          message={<Typography.Text strong>Application has been submitted</Typography.Text>}
          description={
            <span className="!text-xs">
              Application was submitted at{' '}
              {dayjs(submissionData?.submittedAt).format('DD/MM/YYYY hh:mm a')}
            </span>
          }
          type="success"
          showIcon
        />
      )}
      {isApplicationApproved && (
        <Alert
          message={<Typography.Text strong>Application has been approved</Typography.Text>}
          description={
            <span className="!text-xs">
              Application was approved at{' '}
              {dayjs(decisionData?.decidedAt).format('DD/MM/YYYY hh:mm a')}
            </span>
          }
          type="success"
          showIcon
        />
      )}
      <div className="pr-8 mt-8">
        <Divider orientation="left" orientationMargin="0">
          <Typography.Title level={4}>Decision</Typography.Title>
        </Divider>
        <Form
          form={form}
          layout="vertical"
          onFinish={submitDecision}
          //redefining below few properties because DatePicker of antd does not understand ISOString
          initialValues={
            (decisionData && {
              ...decisionData,
              settlementEta: dayjs(decisionData.settlementEta),
              decidedAt: dayjs(decisionData.decidedAt),
              approvalExpiry: dayjs(decisionData.approvalExpiry)
            }) ||
            submissionData
          }>
          <Row>
            <Col span={decisionData ? 24 : 12}>
              {decisionData && (
                <Descriptions
                  items={[
                    {
                      key: 'outcomeOfApplication',
                      label: 'Outcome of the submitted application',
                      children: (
                        <p className="font-semibold">{decisionData.outcomeOfApplication}</p>
                      ),
                      span: 3
                    }
                  ]}
                  layout="vertical"
                  colon={false}
                  bordered
                />
              )}
              {!decisionData && (
                <Form.Item
                  label="Outcome of the submitted application:"
                  name="outcomeOfApplication"
                  rules={[{ required: true }]}>
                  <Select
                    options={LOAN_DECISIONS.map((dec) => ({ label: dec, value: dec }))}
                    onChange={(val) => setDecision(val)}
                    placeholder="Select"
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          {decision == APPROVED && (
            <>
              {decisionData && (
                <Descriptions
                  items={approvalItems}
                  layout="vertical"
                  colon={false}
                  bordered
                  className="mt-6"
                />
              )}
              {!decisionData && (
                <>
                  <Row gutter={36}>
                    <Col span={8}>
                      <Form.Item
                        label="Expiry of the approval:"
                        name="approvalExpiry"
                        rules={[{ required: true }]}>
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Settlement ETA:"
                        name="settlementEta"
                        rules={[{ required: true }]}>
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={36}>
                    <Col span={8}>
                      <Form.Item
                        label="Conditions of approval:"
                        name="approvalConditions"
                        rules={[{ required: true }]}>
                        <Input.TextArea rows={4} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Has loan details been updated during approval?"
                        name="hasLoanDetailsBeenUpdated"
                        rules={[{ required: true }]}
                        initialValue={false}>
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {/* ---------------------------- */}
          {decision == DECLINED && (
            <Row>
              {decisionData && (
                <Descriptions
                  items={[
                    {
                      key: 'declinedReason',
                      label: 'Reason for application being declined:',
                      children: <p className="font-semibold">{decisionData.declinedReason}</p>,
                      span: 3
                    },
                    {
                      key: 'customReason',
                      label: decisionData.customReason && 'Custom reason:',
                      children: decisionData.customReason && (
                        <p className="font-semibold">{decisionData.customReason}</p>
                      ),
                      span: 3
                    }
                  ]}
                  layout="horizontal"
                  colon={false}
                  className="mt-4"
                />
              )}
              {!decisionData && (
                <Col span={12}>
                  <Form.Item
                    label="Reason for application being declined:"
                    name="declinedReason"
                    rules={[{ required: true }]}>
                    <Select
                      options={DECLINED_REASONS.map((res) => ({ label: res, value: res }))}
                      placeholder="Select"
                      onChange={(val) => setReason(val)}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
          {decision == WITHDRAWN && (
            <Row>
              {decisionData && (
                <Descriptions
                  items={[
                    {
                      key: 'withdrawnReason',
                      label: 'Reason for application being withdrawn:',
                      children: <p className="font-semibold">{decisionData.withdrawnReason}</p>,
                      span: 3
                    },
                    {
                      key: 'customReason',
                      label: decisionData.customReason && 'Custom reason:',
                      children: decisionData.customReason && (
                        <p className="font-semibold">{decisionData.customReason}</p>
                      ),
                      span: 3
                    }
                  ]}
                  layout="horizontal"
                  colon={false}
                  className="mt-4"
                />
              )}
              {!decisionData && (
                <Col span={12}>
                  <Form.Item
                    label="Reason for application being withdrawn:"
                    name="withdrawnReason"
                    rules={[{ required: true }]}>
                    <Select
                      options={WITHDRAWN_REASONS.map((res) => ({ label: res, value: res }))}
                      placeholder="Select"
                      onChange={(val) => setReason(val)}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
          {[DECLINED_REASON_OTHER, WITHDRAWN_REASON_OTHER].includes(reason) && !decisionData && (
            <Row>
              <Col span={12}>
                <Form.Item label="Custom reason:" name="customReason" rules={[{ required: true }]}>
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          )}
          {decision == APPROVED && (
            <Row>
              <Col style={{ width: '100%' }}>
                <LoanDetailsRateFeesSection
                  rowGutter={80}
                  isReadOnly={!hasLoanDetailsBeenUpdated}
                  data={decisionData || submissionData}
                />
              </Col>
            </Row>
          )}

          {!decisionData && (
            <Form.Item className="mt-6">
              <Button htmlType="submit" type="primary" loading={isSubmitting}>
                Submit decision
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </div>
  );
}

export default memo(LoanDecision);
