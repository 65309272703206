/* eslint-disable no-underscore-dangle */
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography
} from 'antd';
import axios from 'axios';
import { useState, useEffect } from 'react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import dayjs from 'dayjs';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import * as slugify from 'slugify';
import TagInput from 'antd-tag-input';
import { Actions } from '../../types';
import { API_HOST } from '../../helpers/constants';
import { openNotification } from '../../helpers';
import { usePage } from '../../hooks/usePage';
import { headers } from '../../utils';

function PageForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const [metaKeywords, setMetaKeywords] = useState([]);
  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );

  const { id } = params;

  const { page } = usePage(id);

  useEffect(() => {
    if (page) {
      form.setFieldsValue({
        ...page,
        publishedAt: page.publishedAt ? dayjs(page.publishedAt) : null
      });
    }
  }, [page]);

  useEffect(() => {
    if (mode === Actions.add) {
      form.setFieldsValue({ publishedAt: dayjs() });
    }
  }, [form]);

  const onSlugifyClick = () => {
    const slug = slugify(form.getFieldValue('title'), {
      lower: true
    });
    form.setFieldsValue({ slug });
  };

  const onSubmit = async (values) => {
    const verb = mode === Actions.add ? 'post' : 'put';
    const url = mode === Actions.add ? `${API_HOST}/pages` : `${API_HOST}/pages/${id}`;

    try {
      const { data: savedPage } = await axios[verb](url, values, { headers });
      navigate(`/pages/${savedPage._id}/edit`);
      setMode(Actions.edit);
      openNotification('Success', `Page saved successfully!`);
    } catch (error) {
      openNotification('Error', 'Page saving failed!', true);
      console.log(error);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/pages">Pages</Link> },
          { title: 'Page' }
        ]}
      />
      <Typography.Title level={4}>Page</Typography.Title>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={16}>
            <div className="bg-white p-5 shadow-lg rounded-md">
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Button onClick={onSlugifyClick} className="mb-4">
                Fill Slug
              </Button>
              <Form.Item label="Slug" name="slug" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Content" name="content">
                {/* <SunEditor
                  height="250px"
                  placeholder="Please type here..."
                  setContents={form.getFieldValue('content')}
                /> */}
                <Input.TextArea rows={10} />
              </Form.Item>
            </div>
            <div className="bg-white mt-4 p-5 shadow-lg rounded-md">
              <h2 className="text-xl">Meta information</h2>
              <Form.Item label="Title" name="metaTitle">
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Description" name="metaDescription">
                <Input.TextArea size="large" rows={3} />
              </Form.Item>
              <Form.Item label="Keywords" name="metaKeywords">
                <TagInput
                  value={metaKeywords}
                  placeholder="please fill in..."
                  onChange={(strArr) => setMetaKeywords(strArr)}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={6}>
            <div className="bg-white p-5 shadow-lg rounded-md">
              <Form.Item
                label="Publish At"
                name="publishedAt"
                // valuePropName={'publishedAt'}
                rules={[{ required: true, message: 'Required' }]}>
                <DatePicker size="large" format="DD/MM/YYYY hh:mm a" showTime />
              </Form.Item>
              <Form.Item label="Active" name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={12}>
            <Button htmlType="submit" type="primary" size="large">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default PageForm;
