/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Switch,
  Typography,
  Upload,
  message
} from 'antd';
import { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { fileToBase64, openNotification } from '../../helpers';
import { Actions } from '../../types';
import { useBlogAuthor } from '../../hooks/useBlogAuthor';
import { createBlogAuthor, updateBlogAuthor } from '../../services/blog-authors.http';

const { Dragger } = Upload;

function BlogAuthorForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );
  const [image, setImage] = useState();

  const { id } = params;

  const { blogAuthor } = useBlogAuthor(id);

  useEffect(() => {
    if (blogAuthor) {
      form.setFieldsValue(blogAuthor);
      setImage({ url: blogAuthor.imgUrl });
    }
  }, [blogAuthor]);

  const onSubmit = async (values) => {
    try {
      const { data } =
        mode === Actions.add ? await createBlogAuthor(values) : await updateBlogAuthor(id, values);
      navigate(`/blog-authors/${data._id}/edit`);
      setMode(Actions.edit);
      openNotification('Success', 'Blog Author saved successfully!');
    } catch (error) {
      openNotification('Error', 'Blog Author saving failed!', true);
      console.log(error);
    }
  };

  const getProps = () => ({
    name: 'file',
    multiple: false,
    // headers,
    showUploadList: false,
    accept: '.jpg,.png,.gif',
    async onChange(info) {
      const { status } = info.file;
      if (status === 'uploading') {
        let base64;
        let file;
        for (const f of info.fileList) {
          base64 = await fileToBase64(f.originFileObj);

          file = { url: URL.createObjectURL(f.originFileObj) };
        }
        setImage(file);
        form.setFieldsValue({ img: base64 });
      } else if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    }
  });

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/blog-authors">Blog Authors</Link> },
          { title: 'Blog Author' }
        ]}
      />
      <Typography.Title level={4}>Blog Author</Typography.Title>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={16}>
            <div className="bg-white p-5 shadow-lg rounded-md">
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
            </div>
            <div className="bg-white p-5 shadow-lg rounded-md">
              <Dragger {...getProps()}>
                <div className="block">
                  <div className="mt-2 flex flex-col space-y-4">
                    <UploadOutlined className="text-4xl text-gray-400" />
                    Upload
                  </div>
                </div>
              </Dragger>
              {image?.url ? (
                <div className="my-4 border p-1 inline-block">
                  <img src={image?.url} className="w-52" alt="" />
                </div>
              ) : null}
              <Form.Item name="img" />
              <Form.Item label="Active" name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={12}>
            <Button htmlType="submit" type="primary" size="large">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default BlogAuthorForm;
