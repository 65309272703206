import { Card, Col, Row, Statistic } from 'antd';
import { AreaChart } from './charts/AreaChart';
import MonthlySalesChart from './charts/MonthlySalesChart';
import LoansByLenderChart from './charts/LoansByLenderChart';
import TasksWidget from './dashboard/widgets/Tasks';

export default function Home() {
  return (
    <div>
      <Row gutter={12}>
        <Col span={6}>
          <Card className="shadow">
            <Statistic title="NAF Today" value={112893} prefix="$" className="mb-2" />
            <AreaChart />
          </Card>
        </Col>
        <Col span={6}>
          <Card className="shadow">
            <Statistic title="Commission Today" value={12000} prefix="$" className="mb-2" />
            <AreaChart />
          </Card>
        </Col>
        <Col span={6}>
          <Card className="shadow">
            <Statistic title="NAF Today" value={112893} prefix="$" className="mb-2" />
            <AreaChart />
          </Card>
        </Col>
        <Col span={6}>
          <Card className="shadow">
            <Statistic title="NAF Today" value={112893} prefix="$" className="mb-2" />
            <AreaChart />
          </Card>
        </Col>
      </Row>
      <Row gutter={12} className="mt-3">
        <Col span={12}>
          <TasksWidget title="Tasks for Leads" entity="lead" />
        </Col>
        <Col span={12}>
          <TasksWidget title="Tasks for Applications" entity="application" />
        </Col>
      </Row>
      <Row gutter={12} className="mt-3">
        <Col span={12}>
          <Card title="Monthly Sales" className="shadow">
            <MonthlySalesChart />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Loans by Lender" className="shadow">
            <LoansByLenderChart />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
