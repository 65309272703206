/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Card } from 'antd';
import { API_HOST } from '../../helpers/constants';
import { fetcher } from '../../utils';
import ApplicationWidget from './ApplicationWidget';

function ApplicationsView({ children }) {
  const { leadId } = useParams();
  const [lead, setLead] = useState(null);
  const url = `${API_HOST}/leads/${leadId}`;

  const { data: responseData } = useSWR(leadId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setLead(responseData);
    }
  }, [responseData]);

  return (
    <Card title="Application">
      <ApplicationWidget applications={lead?.applications} leadId={leadId} children={children} />
    </Card>
  );
}

export default ApplicationsView;
