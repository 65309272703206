import useSWR, { mutate } from 'swr';
import { API_HOST, disableRevalidation } from '../helpers/constants';
import { fetcher } from '../utils';

export function useLeads(params = {}) {
  const url = `${API_HOST}/leads`;

  if (params.filters?.dateRange) {
    const startDate = params.filters?.dateRange[0].format();
    const endDate = params.filters?.dateRange[1].format();

    params.filters.startDate = startDate;
    params.filters.endDate = endDate;
  }

  const urlParams = new URLSearchParams(params).toString();
  const urlWithParams = [url, urlParams].join('?');
  const {
    data: responseData,
    error,
    isLoading
  } = useSWR(urlWithParams, fetcher, disableRevalidation);

  return {
    leads: responseData?.data || [],
    meta: responseData?.meta,
    isLoading,
    isError: error,
    revalidate: () => mutate([url, params])
  };
}
