/* eslint-disable react/prop-types */
import { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Alert } from 'antd';
import { CloseCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_HOST } from '../../helpers/constants';

export function VerifyTokenForm({ token, msg, email, type }) {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [searchParams] = useSearchParams();

  let tmt;

  const state = searchParams.get('state');

  if (token) {
    if (msg) {
      clearTimeout(tmt);
      return (
        <p>
          <CloseCircleOutlined twoToneColor="#ff0000" /> {msg}
        </p>
      );
    }
    const state = `${btoa(email)}.${btoa(token)}.${btoa(type)}`;
    if (email) {
      tmt = setTimeout(
        () => navigate(`/auth/set-password?state=${state}`, { replace: true }),
        1000
      );
    }

    return (
      <>
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 22 }} /> Email Verified
      </>
    );
  }

  const onFinish = async (values) => {
    const { token } = values;

    const email = atob(state);

    setIsProcessing(true);
    try {
      await axios.post(`${API_HOST}/auth/verify`, { code: token, email });
      const state = `${btoa(email)}.${btoa(token)}.${btoa('recovery')}`;

      navigate(`/auth/set-password?state=${state}`, { replace: true });
    } catch (e) {
      if (e.response) {
        setMessage(e.response.data.message);
        setIsAlertVisible(true);
      }
    }
    setIsProcessing(false);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true
      }}
      onFinish={onFinish}>
      {isAlertVisible && (
        <Alert description={message} type="error" showIcon style={{ marginBottom: 20 }} />
      )}
      <p>Enter the Password Recovery Code sent you in the recovery email.</p>
      <Form.Item
        name="token"
        rules={[
          {
            required: true,
            message: 'Please input the token!'
          }
        ]}>
        <Input
          size="large"
          // prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Token"
        />
      </Form.Item>

      <Form.Item>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={isProcessing}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
