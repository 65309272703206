import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Descriptions, Divider, Popconfirm, Typography } from 'antd';
import { useState } from 'react';
import WarrantyDrawer from './WarrantyDrawer';
import InsuranceDrawer from './InsuranceDrawer';
import { openNotification } from '../../../helpers';
import axios from 'axios';
import { API_HOST } from '../../../helpers/constants';
import { useParams } from 'react-router-dom';
import { currency, fetcher, headers } from '../../../utils';
import useSWR from 'swr';

export default function WarrantyInsurance() {
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const warrantyApiEndpoint = `${applicationUrl}/settlement/warranty`;
  const insuranceApiEndpoint = `${applicationUrl}/settlement/insurance`;

  const [isWarrantyDrawerOpen, setIsWarrantyDrawerOpen] = useState(false);
  const [isWarrantySubmitting, setIsWarrantySubmitting] = useState(false);
  const [isInsuranceDrawerOpen, setIsInsuranceDrawerOpen] = useState(false);
  const [isInsuranceSubmitting, setIsInsuranceSubmitting] = useState(false);

  const { data: application, mutate } = useSWR(applicationUrl, fetcher);

  const warrantyData = application.loanDetails.settlement?.warranty;
  const insuranceData = application.loanDetails.settlement?.insurance;

  const onWarrantySave = async (data) => {
    try {
      setIsWarrantySubmitting(true);
      await axios.post(warrantyApiEndpoint, data, { headers: headers });
      setIsWarrantySubmitting(false);
      setIsWarrantyDrawerOpen(false);
      openNotification('Saved warranty details successfully!');
      mutate();
    } catch (error) {
      console.log(error);
      setIsWarrantySubmitting(false);
      openNotification('Could not save warranty details', error.response?.data?.message, true);
    }
  };

  const deleteWarranty = async () => {
    try {
      await axios.delete(warrantyApiEndpoint, { headers: headers });
      openNotification('Deleted warranty details successfully!');
      mutate();
    } catch (error) {
      console.log(error);
      openNotification('Could not delete warranty details', error.response?.data?.message, true);
    }
  };

  const onInsuranceSave = async (data) => {
    try {
      setIsInsuranceSubmitting(true);
      await axios.post(insuranceApiEndpoint, data, { headers: headers });
      setIsInsuranceSubmitting(false);
      setIsInsuranceDrawerOpen(false);
      openNotification('Saved insurance details successfully!');
      mutate();
    } catch (error) {
      console.log(error);
      setIsInsuranceSubmitting(false);
      openNotification('Could not save insurance details', error.response?.data?.message, true);
    }
  };

  const deleteInsurance = async () => {
    try {
      await axios.delete(insuranceApiEndpoint, { headers: headers });
      openNotification('Deleted insurance details successfully!');
      mutate();
    } catch (error) {
      console.log(error);
      openNotification('Could not delete insurance details', error.response?.data?.message, true);
    }
  };

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Typography.Title level={4}>Warranty</Typography.Title>
      </Divider>
      {warrantyData && (
        <Descriptions layout="vertical" className="mb-4">
          <Descriptions.Item label="Provider">
            <span className="font-semibold">{warrantyData.provider}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Product No.">
            <span className="font-semibold">{warrantyData.productNumber}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Wholesale Amount">
            <span className="font-semibold">{currency(warrantyData.wholesaleAmount)}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Retail Amount">
            <span className="font-semibold">{currency(warrantyData.retailAmount)}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Commission">
            <span className="font-semibold">{currency(warrantyData.commission)}</span>
          </Descriptions.Item>
        </Descriptions>
      )}
      <Button
        icon={!warrantyData && <PlusOutlined className="mr-2" />}
        type="primary"
        onClick={() => setIsWarrantyDrawerOpen(true)}>
        {warrantyData ? 'Update' : 'Add'} Warranty
      </Button>
      {warrantyData && (
        <Popconfirm
          title="Delete warranty"
          description="Do you want to delete the warranty?"
          okText="Delete"
          okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
          cancelButtonProps={{ size: 'middle' }}
          onConfirm={deleteWarranty}>
          <Button
            icon={warrantyData && <DeleteOutlined className="mr-2" />}
            danger
            className="ml-4">
            Delete Warranty
          </Button>
        </Popconfirm>
      )}

      <Divider orientation="left" orientationMargin="0" className="!mt-16">
        <Typography.Title level={4}>Insurance</Typography.Title>
      </Divider>
      {insuranceData && (
        <Descriptions layout="vertical" className="mb-4">
          <Descriptions.Item label="Provider">
            <span className="font-semibold">{insuranceData.provider}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Policy No.">
            <span className="font-semibold">{insuranceData.policyNumber}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Frequency">
            <span className="font-semibold">{insuranceData.frequency}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Premium Amount">
            <span className="font-semibold">{currency(insuranceData.premiumAmount)}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Commission Amount">
            <span className="font-semibold">{currency(insuranceData.commissionAmount)}</span>
          </Descriptions.Item>
        </Descriptions>
      )}
      <Button
        icon={!insuranceData && <PlusOutlined className="mr-2" />}
        type="primary"
        onClick={() => setIsInsuranceDrawerOpen(true)}>
        {insuranceData ? 'Update' : 'Add'} Insurance
      </Button>
      {insuranceData && (
        <Popconfirm
          title="Delete insurance"
          description="Do you want to delete the insurance?"
          okText="Delete"
          okButtonProps={{ danger: true, type: 'primary', size: 'middle' }}
          cancelButtonProps={{ size: 'middle' }}
          onConfirm={deleteInsurance}>
          <Button
            icon={insuranceData && <DeleteOutlined className="mr-2" />}
            danger
            className="ml-4">
            Delete Insurance
          </Button>
        </Popconfirm>
      )}
      {isWarrantyDrawerOpen && (
        <WarrantyDrawer
          isOpen={isWarrantyDrawerOpen}
          onClose={() => setIsWarrantyDrawerOpen(false)}
          onSave={onWarrantySave}
          isSubmitting={isWarrantySubmitting}
          data={warrantyData}
        />
      )}
      {isInsuranceDrawerOpen && (
        <InsuranceDrawer
          isOpen={isInsuranceDrawerOpen}
          onClose={() => setIsInsuranceDrawerOpen(false)}
          onSave={onInsuranceSave}
          isSubmitting={isInsuranceSubmitting}
          data={insuranceData}
        />
      )}
    </>
  );
}
