/* eslint-disable react/prop-types */
import { Button, Col, Descriptions, Divider, Flex, Form, Row, Typography } from 'antd';
import LoanDetailsRateFeesSection from '../application/loan-details/LoanDetailsRateFeesSection';
import LoanIdModal from '../application/loan-details/LoanIdModal';

export default function CommonSettlementSection({
  currentTabData,
  previousTabData,
  loanDetails,
  setIsLoanIdModalOpen,
  isLoanIdModalOpen,
  onLoanIdModalSubmission,
  isEditing,
  setIsEditing,
  saveData,
  onLoanDetailsEditClick,
  isSubmitting,
  isUpdatingLoanId,
  form,
  alwaysShowEditsInHeader
}) {
  const shouldShowLenderEdit = alwaysShowEditsInHeader || !currentTabData;
  const shouldShowRateFeesEdit =
    (alwaysShowEditsInHeader && !isEditing) || (!currentTabData && !isEditing);
  const shouldShowSaveButton = (alwaysShowEditsInHeader && isEditing) || !currentTabData;
  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <div className="flex justify-between items-end gap-2 mb-2">
          <Typography.Title level={4} className="!mb-0">
            Lender
          </Typography.Title>
          {shouldShowLenderEdit && (
            <Button
              type="link"
              size="small"
              className="!font-semibold"
              onClick={() => setIsLoanIdModalOpen(true)}>
              Edit
            </Button>
          )}
        </div>
      </Divider>
      <Row gutter={80}>
        <Col span={12}>
          <Descriptions
            key="lender"
            colon={false}
            layout="vertical"
            items={[
              {
                label: 'Name',
                children: <p className="font-semibold">{loanDetails?.submission?.lender}</p>,
                span: 2
              },
              {
                label: 'Loan ID',
                children: <p className="font-semibold">{loanDetails?.loanId || '--'}</p>,
                span: 2
              }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ width: '100%' }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={saveData}
            initialValues={
              currentTabData || {
                ...previousTabData
              }
            }>
            <LoanDetailsRateFeesSection
              rowGutter={80}
              isReadOnly={!isEditing}
              data={currentTabData || previousTabData}
              showEditLinks={shouldShowRateFeesEdit}
              onEditClick={onLoanDetailsEditClick}
            />

            <Flex align="center" gap={24}>
              {shouldShowSaveButton && (
                <Form.Item className="mt-6">
                  <Button htmlType="submit" type="primary" loading={isSubmitting}>
                    Save pre-settlement
                  </Button>
                </Form.Item>
              )}
              {isEditing && (
                <Button type="default" onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
              )}
            </Flex>
          </Form>
        </Col>
      </Row>
      {isLoanIdModalOpen && (
        <LoanIdModal
          isModalOpen={isLoanIdModalOpen}
          handleCancel={() => setIsLoanIdModalOpen(false)}
          onFinish={onLoanIdModalSubmission}
          isLoading={isUpdatingLoanId}
          preFillData={{ loanId: loanDetails?.loanId }}
        />
      )}
    </>
  );
}
