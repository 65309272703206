import { Button, Col, Divider, Form, Input, Row, Select, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { openNotification } from '../../helpers';
import { Actions } from '../../types';
import { useUser } from '../../hooks/useUser';
import { createUser, updateUser } from '../../services/users.http';

function UserForm() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams();
  const { userId } = params;

  const location = useLocation();

  const [mode] = useState(location.pathname.endsWith('/edit') ? Actions.edit : Actions.add);

  const { user } = useUser(userId);

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
  }, [user]);

  const onSubmit = async (values) => {
    try {
      const { data } =
        mode === Actions.add ? await createUser(values) : await updateUser(userId, values);
      openNotification('Success', `User saved successfully!`);
      navigate(`/users/${data._id}/edit`, { replace: true });
    } catch (error) {
      openNotification('Error', `User could not saved!`, true);
      console.log(error);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Typography.Title level={4}>User</Typography.Title>
      <div className="bg-white p-5">
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mobile"
                name="mobile"
                rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Required' }]}>
                <Select size="large">
                  {[
                    'admin',
                    'pre-sales',
                    'broker',
                    'manager',
                    'settlement',
                    'sales',
                    'partner'
                  ].map((s) => (
                    <Select.Option key={s} value={s}>
                      {s.toUpperCase()}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item label="Enabled?" name="isEnabled" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>

                <Col span={8} />
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row gutter={24}>
            <Col span={12}>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default UserForm;
