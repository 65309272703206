import { Breadcrumb, Button, Col, Divider, Form, Input, Row, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { openNotification } from '../../helpers';
import { Actions } from '../../types';
import { useFaq } from '../../hooks/useFaq';
import { createFaq, updateFaq } from '../../services/faqs.http';

function FaqForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );

  const { id } = params;

  const { faq } = useFaq(id);

  useEffect(() => {
    if (faq) {
      form.setFieldsValue(faq);
    }
  }, [faq]);

  // const onSlugifyClick = () => {
  //   const slug = slugify(form.getFieldValue('name'), {
  //     lower: true
  //   });
  //   form.setFieldsValue({ slug });
  // };

  const onSubmit = async (values) => {
    try {
      const { data } = mode === Actions.add ? await createFaq(values) : await updateFaq(id, values);
      navigate(`/faqs/${data._id}/edit`);
      setMode(Actions.edit);
      openNotification('Success', 'Faq saved successfully!');
    } catch (error) {
      openNotification('Error', 'Faq saving failed!', true);
      console.log(error);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/faqs">Faqs</Link> },
          { title: 'Faq' }
        ]}
      />
      <Typography.Title level={4}>Faq</Typography.Title>

      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <div className="bg-white p-5">
              <Form.Item
                label="Question"
                name="question"
                rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Answer"
                name="answer"
                rules={[{ required: true, message: 'Required' }]}>
                <Input.TextArea rows={15} />
              </Form.Item>
              <Form.Item label="Active" name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider />
        <Button htmlType="submit" type="primary" size="large">
          Save
        </Button>
      </Form>
    </div>
  );
}

export default FaqForm;
