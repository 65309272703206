/* eslint-disable react/prop-types */
import { Card, Table, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { fetcher, getLeadStatusColor, headers } from '../../../utils';
import { API_HOST } from '../../../helpers/constants';
import TaskModal from '../../common/TaskModal';
import axios from 'axios';
import { Actions } from '../../../types';

function TasksWidget({ title, entity }) {
  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const mode = useRef(null);
  const [selectedTask, setSelectedTask] = useState(null);

  const [rows, setRows] = useState([]);

  //add status as: status[]=-Done&status[]=To Do ....
  const url = `${API_HOST}/tasks?entity=${entity}&status[]=-Done`;

  const { data: responseData, mutate } = useSWR(url, fetcher);

  useEffect(() => {
    if (responseData) {
      setRows(responseData.data);
    }
  }, [responseData]);

  const columns = [
    {
      title: 'Client name',
      dataIndex: 'user',
      key: 'clientName',
      render: (user) => (
        <p>
          {user?.firstName} {user?.lastName}
        </p>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, row) => {
        return (
          <div className="ml-2">
            {/* <Link to={`/tasks/${row._id}/edit`} className="font-semibold"> */}
            <p
              onClick={() => openTaskModal(row._id)}
              className="font-semibold text-blue-500 cursor-pointer">
              {text}
            </p>
            <p className="text-sm text-gray-500">{row.slug}</p>
          </div>
        );
      }
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '13%'
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (text) => <Tag color={getLeadStatusColor(text)}>{text}</Tag>
    }
  ];

  const openTaskModal = async (taskId) => {
    try {
      const { data: taskData } = await axios.get(`${API_HOST}/tasks/${taskId}`, {
        headers: headers
      });
      setIsTaskModalVisible(true);
      mode.current = Actions.edit;
      setEntityId(taskData[entity]);
      setSelectedTask({ ...taskData, dueAt: taskData.dueAt ? dayjs(taskData.dueAt) : null });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Card title={title} styles={{ body: { paddingBlock: 0 } }}>
        <Table size="small" columns={columns} dataSource={rows} />
      </Card>
      {isTaskModalVisible && (
        <TaskModal
          isOpen={isTaskModalVisible}
          onCancel={() => setIsTaskModalVisible(false)}
          entity={entity}
          entityId={entityId}
          mode={mode}
          task={selectedTask}
          onSubmissionCallback={() => {
            mutate();
            setIsTaskModalVisible(false);
            setSelectedTask(null);
          }}
        />
      )}
    </>
  );
}

export default TasksWidget;
