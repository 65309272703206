/* eslint-disable no-underscore-dangle */
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Typography
} from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { openNotification } from '../../helpers';
import { STATUS_LOST, STATUS_UNQUALIFIED } from '../../helpers/constants';
import { Actions } from '../../types';
import { useLeadReason } from '../../hooks/useLeadReason';
import { createLeadReason, updateLeadReason } from '../../services/lead-reasons.http';

function LeadReasonForm() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [mode, setMode] = useState(
    location.pathname.endsWith('/edit') ? Actions.edit : Actions.add
  );

  const { id } = params;

  const { leadReason } = useLeadReason(id);

  useEffect(() => {
    if (leadReason) {
      form.setFieldsValue(leadReason);
    }
  }, [leadReason]);

  const onSubmit = async (values) => {
    try {
      const { data } =
        mode === Actions.add ? await createLeadReason(values) : updateLeadReason(id, values);
      navigate(`/lead-reasons/${data._id}/edit`);
      setMode(Actions.edit);
      openNotification('Success', 'Lead Reason saved successfully!');
    } catch (error) {
      openNotification('Error', 'LeadReason saving failed!', true);
      console.log(error);
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/lead-reasons">Lead Reasons</Link> },
          { title: 'Lead Reason' }
        ]}
      />
      <Typography.Title level={4}>LeadReason</Typography.Title>

      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <div className="bg-white p-5">
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label="Stage"
                name="stage"
                rules={[{ required: true, message: 'Required' }]}>
                <Select size="large">
                  <Select.Option value={STATUS_UNQUALIFIED}>{STATUS_UNQUALIFIED}</Select.Option>
                  <Select.Option value={STATUS_LOST}>{STATUS_LOST}</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item label="Active" name="isActive" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={12}>
            <Button htmlType="submit" type="primary" size="large">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default LeadReasonForm;
