import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
  Switch
} from 'antd';
import { memo, useState } from 'react';
import { LOAN_FEATURES, LOAN_PURPOSES } from '../../types';
import { API_HOST, CAR_LOAN, HOME_LOAN, PERSONAL_LOAN } from '../../helpers/constants';
import axios from 'axios';
import { openNotification } from '../../helpers';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { fetcher, headers } from '../../utils';

function RequirementsTab() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application } = useSWR(applicationUrl, fetcher);

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      await axios.put(`${applicationUrl}/requirements`, values, { headers });
      mutate(applicationUrl);
      openNotification('Requirements saved successfully');
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      openNotification('Could not save requirements', err.response?.data?.message, true);
    }
  };
  if (!application) return <Skeleton />;

  return (
    <div className="px-6 pt-2">
      <Card type="inner" title="Preliminary Requirements">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          wrapperCol={14}
          initialValues={application.requirements}>
          <Row>
            <Col span={10}>
              <Form.Item
                label="Purpose of obtaining the loan"
                name="purposeOfLoan"
                rules={[{ required: true, message: 'Purpose of obtaining the loan is required' }]}>
                <Select
                  options={LOAN_PURPOSES.map((val) => ({ label: val, value: val }))}
                  placeholder="Select"
                />
              </Form.Item>
              <Form.Item
                label="Loan type"
                name="loanType"
                rules={[{ required: true, message: 'Loan type is required' }]}>
                <Select
                  options={[HOME_LOAN, CAR_LOAN, PERSONAL_LOAN].map((val) => ({
                    label: val,
                    value: val
                  }))}
                  placeholder="Select"
                />
              </Form.Item>
              <Form.Item
                label="Customer's monthly repayment budget"
                name="monthlyRepaymentBudget"
                rules={[{ required: true, message: 'Monthly repayment budget is required' }]}>
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  className="w-1/3"
                />
              </Form.Item>
              <Form.Item
                label="Customer wanted loan features"
                name="loanFeatures"
                rules={[{ required: true, message: 'Please select at least one option' }]}>
                <Checkbox.Group className="w-full">
                  <Card className="w-full">
                    <Row>
                      <Space direction="vertical">
                        {LOAN_FEATURES.map((feature) => (
                          <Col span={24} key={feature}>
                            <Checkbox value={feature}>{feature}</Checkbox>
                          </Col>
                        ))}
                      </Space>
                    </Row>
                  </Card>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                label="Does the customer require credit to pay for additional expenses such as insurance?"
                name="isCreditRequiredForAdditionalExpenses"
                initialValue={false}>
                <Switch checkedChildren="Yes" unCheckedChildren="No" className="ml-3" />
              </Form.Item>
              <Form.Item
                label="Is the customer aware of the costs involved in financing the insurance?"
                name="isAwareOfAdditionalCosts"
                initialValue={false}>
                <Switch checkedChildren="Yes" unCheckedChildren="No" className="ml-3" />
              </Form.Item>
              <Divider />
              <Form.Item>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
}

export default memo(RequirementsTab);
