import { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  FileOutlined,
  MailOutlined,
  PlusOutlined,
  WarningFilled,
  InboxOutlined,
  FileZipOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
  Upload,
  Skeleton,
  Drawer,
  Transfer
} from 'antd';
import useSWR, { mutate } from 'swr';
import dayjs from 'dayjs';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { fetcher, headers } from '../../utils';
import { API_HOST, APPLICANT_PRIMARY, DOCUMENTS } from '../../helpers/constants';
import { diffArray, getApplicantFullName, openNotification } from '../../helpers';

const { Dragger } = Upload;

function Documents() {
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const [applicant, setApplicant] = useState(null);
  const [isShared, setIsShared] = useState(false);
  const [primaryApplicant, setPrimaryApplicant] = useState(null);
  const [isDocumentDrawerVisible, setIsDocumentDrawerVisible] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isZipDownloading, setIsZipDownloading] = useState(false);

  const url = `${API_HOST}/applications/${applicationId}`;
  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setApplication(responseData);
      setPrimaryApplicant(responseData.applicants.find((a) => a.type === APPLICANT_PRIMARY));
    }
  }, [responseData]);

  const allDocuments = DOCUMENTS.map((d) => ({ key: d, title: d })).sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  const onAddDocumentsClick = (applicantId, isShared = false) => {
    setApplicant(applicantId);
    setIsShared(isShared);
    const applicant = application?.applicants?.find((a) => a._id === applicantId);
    const tk = !isShared
      ? applicant.requestedDocuments?.map((d) => d.type)
      : application.requestedSharedDocuments?.map((d) => d.type);
    console.log({ tk });
    setTargetKeys(tk);
    setExistingDocuments(tk);
    setIsDocumentDrawerVisible(true);
  };

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onAddDocuments = async (shouldSendEmail = false) => {
    setisLoading(true);
    let durl = `${url}/applicants/${applicant}/documents/bulk`;
    const difference = diffArray(targetKeys, existingDocuments);
    try {
      const documents = difference.map((d) => ({ type: d }));

      if (documents.length < 1) {
        setisLoading(false);
        openNotification(
          `Adding Documents Failed`,
          `Please add documents to the right panel first`,
          true
        );
        return;
      }

      const query = [];
      if (isShared) {
        query.push(`isShared=${isShared}`);
      }
      if (shouldSendEmail) {
        query.push(`shouldSendEmail=${shouldSendEmail}`);
      }

      let qUrl;
      if (query.length) {
        qUrl = query.join('&');
        durl = `${durl}?${qUrl}`;
      }

      await axios.post(durl, documents, { headers });
      openNotification(`Saved Document`, `Saved Document`);
      setIsDocumentDrawerVisible(false);
      mutate(url);
      setisLoading(false);
    } catch (error) {
      openNotification(`Saving Document Failed`, `Saving Document Failed`, true);
      setisLoading(false);
      console.error(error.message);
    }
  };

  const props = {
    name: 'file',
    multiple: true,
    customRequest: async ({ data, file, onError, onSuccess }) => {
      const { applicantId } = data;

      const formData = new FormData();
      if (data) {
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
      }
      formData.append('file', file);

      let query;
      if (data.isShared) {
        query = `isShared=true`;
      }

      try {
        let durl = `${url}/applicants/${applicantId}/documents/upload`;
        durl = query ? `${durl}?${query}` : durl;

        await axios.put(durl, formData, { headers });
        onSuccess('Ok');
        openNotification('Document Uploaded', 'Document Uploaded Successfully');
        mutate(url);
      } catch (e) {
        console.log('Upload Error: ', e);
        openNotification('Document Upload Failed', 'Document could not be Uploaded', true);
        onError(e);
      }
    }
  };

  const onDeleteDocument = async (applicantId, documentId) => {
    try {
      await axios.delete(`${url}/applicants/${applicantId}/documents/${documentId}`);
      openNotification(`Deleted Document`, `Deleted Document`);
      setIsDocumentDrawerVisible(false);
      mutate(url);
    } catch (error) {
      openNotification(`Document deletion failed`, `Document deletion failed`);
      console.log(error.message);
    }
  };

  const onDeleteRequestedDocument = async (applicantId, requestedDocumentId) => {
    try {
      await axios.delete(
        `${url}/applicants/${applicantId}/documents/requested/${requestedDocumentId}`
      );
      openNotification(`Deleted Document Type`, `Deleted Document Type`);
      setIsDocumentDrawerVisible(false);
      mutate(url);
    } catch (error) {
      openNotification(`Document Type deletion failed`, `Document Type deletion failed`);
      console.log(error.message);
    }
  };

  const downloadAllFilesAsZip = async () => {
    try {
      setIsZipDownloading(true);
      const response = await axios.get(`${url}/documents-zip/`, {
        headers: headers,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/zip' });
      const url2 = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('download', `application-${application.shortId}-documents.zip`);
      document.body.appendChild(link);
      link.href = url2;
      link.click();
      link.remove();
      setIsZipDownloading(false);
    } catch (error) {
      console.log(error);
      setIsZipDownloading(false);
      let responseObj = await error.response.data.text();
      openNotification('Could not download documents', JSON.parse(responseObj)?.message, true);
    }
  };

  const onFileDownloadClick = async (filename, key) => {
    const apiUrl = `${API_HOST}/files?key=${key}`;
    try {
      const res = await axios.get(apiUrl);
      const fileResponse = await axios.get(res.data.url, { responseType: 'blob' });
      const blob = new Blob([fileResponse.data], { type: fileResponse.headers['content-type'] });
      const url2 = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.href = url2;
      link.click();
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  const getApplicantDocuments = (requestedDocuments, documents, applicantId, isShared = false) => {
    const docsTree = {};
    requestedDocuments?.forEach((rd) => {
      docsTree[rd.type] = docsTree[rd.type] || {};
      const docs = documents.filter((d) => d.type === rd.type);
      docsTree[rd.type].id = rd._id;
      docsTree[rd.type].requestedAt = rd.requestedAt;
      docsTree[rd.type].docs = docs;
    });

    return Object.keys(docsTree).map((type) => {
      const docType = docsTree[type];
      // console.log(docType)
      return (
        <Col span={6} key={type}>
          <Card bodyStyle={{ padding: 10 }} className="border-gray-400 shadow mb-2">
            <Row gutter={24}>
              <Col span={21}>
                <p>
                  {' '}
                  {!docType.docs[0]?.uploadedAt ? (
                    <WarningFilled style={{ color: 'orange' }} className="text-xl mr-2" />
                  ) : null}
                  <Typography.Text strong>{type}</Typography.Text>
                </p>
              </Col>
              <Col span={3} className="text-right">
                <Tooltip title="Delete Document Type" placement="bottom">
                  <Popconfirm
                    placement="top"
                    title="Are you sure to delete document type at all?"
                    onConfirm={() => onDeleteRequestedDocument(applicantId, docType.id)}
                    okText="Yes"
                    cancelText="No">
                    <DeleteOutlined className="text-red-600 text-lg" />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
            <div>
              {/* <div className='mb-2'>
                {d.name ? <Typography.Text><a href="!#">{d.name}</a></Typography.Text> : null}
              </div> */}
              <Dragger
                data={{ type, applicantId, isShared }}
                {...props}
                style={{ width: '100%' }}
                className="my-1">
                <p style={{ padding: '0 15px' }}>
                  <InboxOutlined style={{ fontSize: 22, marginRight: 10 }} />
                  Click or drag file to this area to upload
                </p>
              </Dragger>
              <p>
                <Typography.Text style={{ fontSize: '0.65rem' }} type="secondary">
                  Requested: {dayjs(docType?.requestedAt).format('DD/MM/YYYY hh:mm a')}
                </Typography.Text>
              </p>
              <div className="mt-2">
                {docType?.docs?.map((d) => {
                  return (
                    <p className="mb-2 border border-dashed py-1 px-2" key={d._id}>
                      <Row gutter={24}>
                        <Col span="18">
                          <a
                            rel="noreferrer"
                            href={`/applications/${application._id}/files?key=${d.url}`}
                            target="_blank"
                            className="text-blue-500">
                            {d.fileName}
                          </a>
                          <br />
                          {d.uploadedAt ? (
                            <Typography.Text style={{ fontSize: '0.65rem' }} type="secondary">
                              Uploaded: {dayjs(d.uploadedAt).format('DD/MM/YYYY hh:mm a')}
                            </Typography.Text>
                          ) : null}
                        </Col>
                        <Col span="3" className="">
                          <DownloadOutlined
                            className="!text-blue-600 cursor-pointer"
                            onClick={() => onFileDownloadClick(d.fileName, d.url)}
                          />
                        </Col>
                        <Col span="2">
                          <Tooltip title="Delete Document" placement="bottom">
                            <Popconfirm
                              placement="top"
                              title="Are you sure to delete document"
                              onConfirm={() => onDeleteDocument(applicantId, d._id)}
                              okText="Yes"
                              cancelText="No">
                              <DeleteOutlined className="!text-red-600 text-sm" />
                            </Popconfirm>
                          </Tooltip>
                        </Col>
                      </Row>
                    </p>
                  );
                })}
              </div>
            </div>
          </Card>
        </Col>
      );
    });
  };

  return application ? (
    <div className="mt-2">
      <Button
        icon={<FileZipOutlined className="mr-2" />}
        className="ml-auto !block mr-8 mb-2"
        onClick={downloadAllFilesAsZip}
        loading={isZipDownloading}>
        Download all files as zip
      </Button>
      {application.applicants?.map((a) => (
        <Card
          title={getApplicantFullName(a.userInfo)}
          key={a._id}
          style={{ marginBottom: 20 }}
          bodyStyle={{ padding: 15 }}
          type="inner">
          <Row gutter={6}>
            {getApplicantDocuments(a.requestedDocuments, a.documents, a._id, false)}
          </Row>
          <div style={{ marginTop: 20 }}>
            <Space size="large">
              <Button icon={<FileOutlined />} onClick={() => onAddDocumentsClick(a._id, false)}>
                Add Documents
              </Button>
            </Space>
          </div>
        </Card>
      ))}

      {application.applicants?.length > 1 && (
        <Card title="Shared Documents" style={{ marginBottom: 20 }}>
          {getApplicantDocuments(
            application.requestedSharedDocuments,
            application.sharedDocuments,
            application.applicants[0]?._id,
            true
          )}
          <div style={{ marginTop: 20 }}>
            <Space size="large">
              <Button
                icon={<FileOutlined />}
                onClick={() => onAddDocumentsClick(primaryApplicant._id, true)}>
                Add Documents
              </Button>
            </Space>
          </div>
        </Card>
      )}

      <Drawer
        title="Request Documents"
        width={800}
        open={isDocumentDrawerVisible}
        onClose={() => setIsDocumentDrawerVisible(false)}
        closable>
        <Transfer
          showSearch
          onChange={onChange}
          onSelectChange={onSelectChange}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          dataSource={allDocuments}
          titles={['Request New Documents', 'Requested Documents']}
          render={(item) => item.title}
          oneWay
          listStyle={{
            width: 400,
            height: 700
          }}
        />
        <Space size="large" className="mt-4">
          <Button icon={<MailOutlined />} loading={isLoading} onClick={() => onAddDocuments(true)}>
            Email &amp; Add{' '}
          </Button>
          <Button
            icon={<PlusOutlined />}
            loading={isLoading}
            className="bg-blue-100 text-blue-600 border-blue-500"
            onClick={() => onAddDocuments(false)}>
            Add Only
          </Button>
        </Space>
      </Drawer>
    </div>
  ) : (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );
}

export default Documents;
