import { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import useSWR, { mutate } from 'swr';
import { nanoid } from 'nanoid';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Typography
} from 'antd';
import Search from 'antd/lib/input/Search';
import { useParams } from 'react-router-dom';
import { Countries } from '../../../helpers/countries';
import { API_HOST } from '../../../helpers/constants';
import { fetcher } from '../../../services/http';
import { openNotification } from '../../../helpers';
import { EmploymentType, State } from '../../../types';
import EmploymentCard from './EmploymentCard';
import { getTotalDuration } from '../../../utils';

function Employment() {
  const [form] = Form.useForm();
  const [isEmploymentDrawerVisible, setIsEmploymentDrawerVisible] = useState(false);
  const { applicationId, applicantId } = useParams();
  const [applicant, setApplicant] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [employment, setEmployment] = useState(null);
  const [mode, setMode] = useState('add');
  const [isDurationAlertVisible, setIsDurationAlertVisible] = useState(false);

  const url = `${API_HOST}/applications/${applicationId}`;

  const { data: responseData } = useSWR(applicationId ? url : null, fetcher);

  useEffect(() => {
    if (responseData) {
      const applicant = responseData.applicants.find((a) => a._id === applicantId);

      const { yearsDuration } = getTotalDuration(applicant, 'employments');
      setIsDurationAlertVisible(yearsDuration < 3);

      setApplicant(applicant);
    }
  }, [responseData, applicantId]);

  const onSubmit = async (values) => {
    let durl = `${url}/applicants/${applicantId}/employments`;
    let method = 'post';
    if (mode === 'edit') {
      durl = `${durl}/${employment._id}`;
      method = 'put';
    }

    try {
      setIsLoading(true);
      await axios[method](durl, values);
      openNotification('Saved Applicant', 'Saved Applicant');
      form.resetFields();
      setIsEmploymentDrawerVisible(false);
      mutate(url);
    } catch (e) {
      openNotification('Saving Failed', 'Cound not Save Applicant', true);
      console.log(e.message);
    }
    setIsLoading(false);
  };

  const onEditClick = (employment) => {
    setEmployment(employment);
    setMode('edit');
    form.setFieldsValue({
      ...employment,
      startDate: employment.startDate ? dayjs(employment.startDate) : null,
      endDate: employment.endDate ? dayjs(employment.endDate) : null
    });
    setIsEmploymentDrawerVisible(true);
  };

  const onDeleteClick = async (employmentId) => {
    try {
      await axios.delete(`${url}/applicants/${applicantId}/employments/${employmentId}`);
      openNotification('Deleted Employment', 'Employment deleted successfully');
      form.resetFields();
      setEmployment(null);
      setMode('add');
      mutate(url);
    } catch (e) {
      openNotification('Deletion Failed', 'Cound not Delete Employment', true);
      console.log(e.message);
    }
  };

  return (
    <div>
      <Typography.Title level={4} className="mt-5">
        Employments
      </Typography.Title>

      {isDurationAlertVisible && (
        <Alert
          message="Minimum 3 years of Employment is required"
          type="warning"
          showIcon
          style={{ margin: '15px 0' }}
        />
      )}
      {applicant?.employments?.map((employment) => (
        <EmploymentCard
          key={employment._id}
          employment={employment}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      ))}
      {applicant?.employments?.length === 0 ? <Empty /> : null}

      <Button
        type="default"
        className="mt-4"
        icon={<PlusOutlined />}
        onClick={() => setIsEmploymentDrawerVisible(true)}>
        Add Employment
      </Button>
      <Drawer
        title="Employment"
        open={isEmploymentDrawerVisible}
        width={900}
        onClose={() => setIsEmploymentDrawerVisible(false)}
        closable>
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Employer Name" rules={[{ required: true }]} name="employerName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Position" rules={[{ required: true }]} name="position">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="ABN" rules={[{ required: true }]} name="abn">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Industry" rules={[{ required: true }]} name="industry">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Contact Name" rules={[{ required: true }]} name="contactName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phone" rules={[{ required: true }]} name="phone">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Row gutter={24}>
                <Col span={10}>
                  <Form.Item label="Start Date" rules={[{ required: true }]} name="startDate">
                    <DatePicker
                      format="DD/MM/YYYY"
                      disabledDate={(current) => {
                        return current > new Date();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label="End Date" name="endDate">
                    <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label="Current" name="isCurrent" valuePropName="checked">
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Time at Employer (Years)"
                    rules={[{ required: true }]}
                    name="yearsDuration">
                    <Select>
                      {[...Array(50)].map((x, i) => (
                        <Select.Option key={nanoid()} value={i}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Time at Employer (Months)"
                    rules={[{ required: true }]}
                    name="monthsDuration">
                    <Select>
                      {[...Array(12)].map((x, i) => (
                        <Select.Option key={nanoid()} value={i}>
                          {i}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Employment Type" rules={[{ required: true }]} name="type">
                    <Select
                      options={Object.keys(EmploymentType).map((key) => ({
                        value: EmploymentType[key],
                        lebel: EmploymentType[key]
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Address" rules={[{ required: true }]} name="fullAddress">
                <Search />
              </Form.Item>
            </Col>
            <Col span={12} />
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Unit #" name="unitNumber">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="Street Number" rules={[{ required: true }]} name="streetNumber">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item label="Street Name" rules={[{ required: true }]} name="streetName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Suburb" rules={[{ required: true }]} name="city">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Postcode" rules={[{ required: true }]} name="postcode">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="State" name="state" rules={[{ required: true }]}>
                    <Select>
                      {Object.keys(State).map((key) => (
                        <Select.Option key={key} value={key}>
                          {State[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true }]}
                initialValue="AUS">
                <Select>
                  {Countries.map((c) => (
                    <Select.Option key={c.code} value={c.code}>
                      {c.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
        </Form>
      </Drawer>
    </div>
  );
}

export default Employment;
