import axios from 'axios';
import { API_HOST } from '../helpers/constants';
import { headers } from '../utils';

const baseUrl = `${API_HOST}/blocks`;

export const createBlock = (payload) => {
  return axios.post(`${baseUrl}`, payload, { headers });
};

export const deleteBlock = (id) => {
  return axios.delete(`${baseUrl}/${id}`, { headers });
};

export const updateBlock = (id, payload) => {
  return axios.put(`${baseUrl}/${id}`, payload, { headers });
};
