import { Breadcrumb, Button, Col, Popconfirm, Row, Space, Table, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState } from 'react';
import { CheckCircleFilled, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { deleteLender } from '../../services/lenders.http';
import { useLenders } from '../../hooks/useLenders';
import { openNotification } from '../../helpers';

export default function LendersList() {
  const [allParams, setAllParams] = useState({});

  const { lenders, meta, isLoading, revalidate } = useLenders(allParams);

  const navigate = useNavigate();

  const onDeleteClick = async (id) => {
    try {
      await deleteLender(id);
      revalidate();
      openNotification('Success', `Deleted successfully!`);
    } catch (e) {
      console.log(e);
    }
  };

  const onAddlenderClick = () => {
    navigate('/lenders/create', { replace: true });
  };

  const onTableChange = async (pagination, filters, sorter) => {
    const options = {
      // term: searchText,
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      sort: sorter.order === 'descend' ? `-${sorter.field}` : sorter.field,
      ...filters
    };

    setAllParams(options);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Short Name',
      dataIndex: 'shortName',
      key: 'shortName'
    },
    {
      title: 'Is Partner?',
      dataIndex: 'isPartner',
      key: 'isPartner',
      align: 'center',
      render: (text) =>
        text ? (
          <CheckCircleFilled className="text-green-500 text-lg" />
        ) : (
          <CloseCircleOutlined className="text-red-500 text-lg" />
        )
    },
    {
      title: 'Is Active?',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (text) =>
        text ? (
          <CheckCircleFilled className="text-green-500 text-lg" />
        ) : (
          <CloseCircleOutlined className="text-red-500 text-lg" />
        )
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/lenders/${record._id}`}>Edit</Link>
          <Popconfirm
            title="Are you sure to delete?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onDeleteClick(record._id)}>
            <a href="#">Delete</a>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        className="my-4"
        items={[{ title: <Link to="/">Home</Link> }, { title: 'Lender' }]}
      />
      <Row gutter={24} className="mt-4 mb-2">
        <Col span={12}>
          <Typography.Title level={4}>Lenders List</Typography.Title>
        </Col>
        <Col span={12} className="text-right">
          <Button type="primary" icon={<PlusOutlined />} onClick={onAddlenderClick}>
            Add lender
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={lenders}
        columns={columns}
        onChange={onTableChange}
        rowKey={(row) => row._id}
        pagination={{
          total: meta?.total,
          pageSize: meta?.limit
        }}
        loading={isLoading}
      />
    </div>
  );
}
