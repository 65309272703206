/* eslint-disable react/prop-types */
import { Button, Col, Descriptions, Drawer, Form, InputNumber, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { currency } from '../../../utils';

export default function WarrantyDrawer({ onClose, isOpen, onSave, isSubmitting, data }) {
  const [form] = Form.useForm();
  const [isCommissionInvalid, setIsCommissionInvalid] = useState(false);

  const wholesaleAmount = Form.useWatch('wholesaleAmount', form);
  const retailAmount = Form.useWatch('retailAmount', form);
  const commission = retailAmount - wholesaleAmount || 0;

  useEffect(() => {
    if (commission < 0) {
      setIsCommissionInvalid(true);
    } else {
      setIsCommissionInvalid(false);
    }
  }, [commission]);

  return (
    <Drawer title="Warranty" onClose={onClose} open={isOpen}>
      <Form form={form} layout="vertical" onFinish={(data) => onSave(data)} initialValues={data}>
        <Row>
          <Col span={24}>
            <Form.Item label="Provider" name="provider" rules={[{ required: true }]}>
              <Select>
                <Select.Option value="Demo Provider">Demo Provider</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Product No." name="productNumber" rules={[{ required: true }]}>
              <InputNumber className="w-full" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Wholesale Amount" name="wholesaleAmount" rules={[{ required: true }]}>
              <InputNumber className="w-full" addonBefore="$" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Retail Amount" name="retailAmount" rules={[{ required: true }]}>
              <InputNumber className="w-full" addonBefore="$" />
            </Form.Item>
          </Col>
          <Col span={24} className="mt-3">
            <Descriptions>
              <Descriptions.Item label="Commission:">
                {isCommissionInvalid ? (
                  <span className="text-red-500">Commission cannot be negative</span>
                ) : (
                  <span className="font-semibold">{currency(commission)}</span>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Form.Item className="text-right">
              <Button
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                disabled={isCommissionInvalid}>
                Save Warranty
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
