/* eslint-disable react/prop-types */
import { Card, Col, Row, Typography } from 'antd';
import { APPLICANT_PRIMARY, APPLICANT_SECONDARY } from '../../../helpers/constants';
import { calculatePerMonthAmount, currency } from '../../../utils';

function NetPosition({ application }) {
  const primaryApplicant = application.applicants.find((a) => a.type === APPLICANT_PRIMARY);
  const secondaryApplicants = application.applicants.filter((a) => a.type === APPLICANT_SECONDARY);

  const hasSecondaryApplicants = application.applicants.length > 1;

  const getIncomePerMonth = (applicant) => {
    const currentEmployment = applicant?.employments?.find((e) => e.isCurrent);
    if (!currentEmployment) {
      return 0;
    }
    return calculatePerMonthAmount(
      currentEmployment.netIncome,
      currentEmployment.netIncomeFrequency
    );
  };

  const getOtherIncomePerMonth = (applicant) => {
    const inc = applicant?.incomes?.reduce(
      (a, c) => Number(a) + Number(calculatePerMonthAmount(c.netIncome, c.frequency)),
      0
    );
    if (Number.isNaN(inc)) return 0;
    return inc;
  };

  const getExpensesPerMonth = (applicant) => {
    const ex = applicant?.expenses?.reduce(
      (a, c) => Number(a) + Number(calculatePerMonthAmount(c.amount, c.frequency)),
      0
    );
    if (Number.isNaN(ex)) return 0;
    return ex;
  };

  const getLiabilitiesPerMonth = (application, applicantId) => {
    let liabilities = 0;
    if (applicantId) {
      liabilities = application.liabilities.filter((liability) =>
        liability.ownership.some((o) => o.applicant === applicantId && o.percentage === '100')
      );
    } else {
      liabilities = application.liabilities.filter((liability) =>
        liability.ownership.some((o) => o.percentage !== '100')
      );
    }
    return liabilities.reduce(
      (a, c) =>
        Number(a) + Number(calculatePerMonthAmount(c.installmentAmount, c.installmentFrequency)),
      0
    );
  };

  const sumIncomes =
    Number(getIncomePerMonth(primaryApplicant)) +
    Number(getIncomePerMonth(secondaryApplicants[0])) +
    Number(getOtherIncomePerMonth(primaryApplicant));
  const sumExpenses =
    getExpensesPerMonth(primaryApplicant) + getExpensesPerMonth(secondaryApplicants[0]);
  const sumLiabilities =
    getLiabilitiesPerMonth(application, primaryApplicant?._id) +
    getLiabilitiesPerMonth(application, secondaryApplicants[0]?._id) +
    getLiabilitiesPerMonth(application);

  const netPosition = sumIncomes - sumExpenses - sumLiabilities;

  return (
    <div style={{ margin: '0 10px' }}>
      <Row gutter={12}>
        <Col span={7}>
          <Card type="inner" title="Income/mth" bodyStyle={{ padding: '10px 20px' }}>
            <Row gutter={12}>
              <Col span={6}>
                <Typography.Text strong>Primary</Typography.Text>
                <p>{currency(getIncomePerMonth(primaryApplicant))}</p>
              </Col>
              {hasSecondaryApplicants && (
                <Col span={6}>
                  <Typography.Text strong>Secondary</Typography.Text>
                  <p>{currency(getIncomePerMonth(secondaryApplicants[0]))}</p>
                </Col>
              )}
              <Col span={6}>
                <Typography.Text strong>Other</Typography.Text>
                <p>{currency(getOtherIncomePerMonth(primaryApplicant))}</p>
              </Col>
              <Col span={6}>
                <Typography.Text strong>Total</Typography.Text>
                <p>{currency(sumIncomes)}</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card type="inner" title="Expenses/mth" bodyStyle={{ padding: '10px 20px' }}>
            <Row gutter={12}>
              <Col span={8}>
                <Typography.Text strong>Primary</Typography.Text>
                <p>{currency(getExpensesPerMonth(primaryApplicant))}</p>
              </Col>
              {hasSecondaryApplicants && (
                <Col span={8}>
                  <Typography.Text strong>Secondary</Typography.Text>
                  <p>{currency(getExpensesPerMonth(secondaryApplicants[0]) || 0)}</p>
                </Col>
              )}
              <Col span={8}>
                <Typography.Text strong>Total</Typography.Text>
                <p>{currency(sumExpenses)}</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={7}>
          <Card type="inner" title="Liabilities/mth" bodyStyle={{ padding: '10px 20px' }}>
            <Row gutter={12}>
              <Col span={6}>
                <Typography.Text strong>Primary</Typography.Text>
                <p>{currency(getLiabilitiesPerMonth(application, primaryApplicant._id))}</p>
              </Col>
              {hasSecondaryApplicants && (
                <Col span={6}>
                  <Typography.Text strong>Secondary</Typography.Text>
                  <p>{currency(getLiabilitiesPerMonth(application, secondaryApplicants[0]._id))}</p>
                </Col>
              )}
              <Col span={6}>
                <Typography.Text strong>Joint</Typography.Text>
                <p>{currency(getLiabilitiesPerMonth(application))}</p>
              </Col>
              <Col span={6}>
                <Typography.Text strong>Total</Typography.Text>
                <p>{currency(sumLiabilities)}</p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={4}>
          <Card type="inner" title="Net Position/mth" bodyStyle={{ padding: '10px 5px' }}>
            <div className={netPosition > 0 ? 'bg-green-200' : 'bg-red-400'}>
              <p className="px-4 py-2">{currency(netPosition)}</p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default NetPosition;
