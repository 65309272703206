import { CheckCircleFilled, ExclamationCircleFilled, EyeOutlined } from '@ant-design/icons';
import { Col, Flex, Row, Typography } from 'antd';
import clsx from 'clsx';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
export default function ComplianceBanner({ complianceDoc, onViewClick }) {
  const { type, createdAt, signedAt } = complianceDoc;
  const getGeneratedDetails = () => {
    if (!createdAt) return <Typography.Text strong>Not Generated</Typography.Text>;
    if (!signedAt)
      return (
        <>
          <Typography.Text strong>Generated, Not Signed</Typography.Text>
          <Typography.Text className="text-stone-500 text-xs block">
            Generated at: {dayjs(createdAt).format('DD/MM/YYYY hh:mm a')}
          </Typography.Text>
        </>
      );
    if (signedAt)
      return (
        <>
          <Typography.Text strong>Generated & Signed</Typography.Text>
          <Typography.Text className="text-stone-500 text-xs block">
            Generated at: {dayjs(createdAt).format('DD/MM/YYYY hh:mm a')}
          </Typography.Text>
          <Typography.Text className="text-stone-500 text-xs block">
            Signed at: {dayjs(signedAt).format('DD/MM/YYYY hh:mm a')}
          </Typography.Text>
        </>
      );
  };
  return (
    <Flex
      className={clsx('py-4 pl-4 pr-8 rounded', {
        'bg-unactioned': !createdAt,
        'bg-amber-100': createdAt && !signedAt,
        'bg-green-100': signedAt
      })}
      align="center">
      <div className="w-[75%] flex items-center">
        {createdAt && !signedAt && (
          <ExclamationCircleFilled className="text-2xl !text-amber-400 mr-4 block" />
        )}
        {signedAt && <CheckCircleFilled className="text-2xl !text-green-400 mr-4 block" />}
        <Typography.Text className={clsx('block', { 'ml-10': !createdAt })} strong>
          {type}
        </Typography.Text>
      </div>
      <Row className={clsx({ 'w-[20%]': createdAt })}>
        <Col>{getGeneratedDetails()}</Col>
      </Row>
      {createdAt && (
        <Row className="w-[5%]">
          <Col className="ml-auto">
            <EyeOutlined
              className="text-2xl cursor-pointer"
              onClick={() => onViewClick(complianceDoc)}
            />
          </Col>
        </Row>
      )}
    </Flex>
  );
}

ComplianceBanner.propTypes = {
  complianceDoc: PropTypes.object,
  onViewClick: PropTypes.func
};
