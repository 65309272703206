import { MailOutlined, PhoneOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Breadcrumb,
  Button,
  Card,
  Col,
  Input,
  List,
  Row,
  Table,
  Tabs,
  Tag,
  Timeline,
  Typography
} from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import dayjs from 'dayjs';
import axios from 'axios';
import { FaHospitalUser } from 'react-icons/fa';
import { fetcher, getDomainIcon, getLeadStatusColor, headers } from '../../utils';
import { API_HOST } from '../../helpers/constants';
import { currency, getApplicantFullName } from '../../helpers';

const { TabPane } = Tabs;
const notesData = [
  {
    title: 'Ant Design Title 1'
  },
  {
    title: 'Ant Design Title 2'
  },
  {
    title: 'Ant Design Title 3'
  },
  {
    title: 'Ant Design Title 4'
  }
];

function AccountView() {
  const [account, setAccount] = useState();
  const [leads, setLeads] = useState([]);
  const [leadsMeta, setLeadsMeta] = useState();
  const [applications, setApplications] = useState([]);
  const [applicationsMeta, setApplicationsMeta] = useState();
  const params = useParams();
  const { accountId } = params;
  const url = `${API_HOST}/accounts/${accountId}`;
  const leadsUrl = `${API_HOST}/leads?account=${accountId}`;
  const applicationsUrl = `${API_HOST}/applications?account=${accountId}`;

  const { data: responseData } = useSWR(accountId ? url : null, fetcher);
  const { data: leadsResponseData } = useSWR(accountId ? leadsUrl : null, fetcher);
  const { data: applicationsResponseData } = useSWR(accountId ? applicationsUrl : null, fetcher);

  useEffect(() => {
    if (responseData) {
      setAccount(responseData);
    }
  }, [responseData]);

  useEffect(() => {
    if (leadsResponseData) {
      setLeads(leadsResponseData.data);
    }
  }, [leadsResponseData]);

  useEffect(() => {
    if (applicationsResponseData) {
      setApplications(applicationsResponseData.data);
    }
  }, [applicationsResponseData]);

  const fetchLeadsData = async (params = {}) => {
    const { data: responseData } = await axios(leadsUrl, { params, headers });
    const { data, meta } = responseData;
    setLeads(data);
    setLeadsMeta(meta);
  };

  const fetchApplicationsData = async (params = {}) => {
    const { data: responseData } = await axios(applicationsUrl, { params, headers });
    const { data, meta } = responseData;
    setApplications(data);
    setApplicationsMeta(meta);
  };

  const leadsColumns = [
    {
      title: 'Id',
      dataIndex: 'shortId',
      key: 'shortId',
      render: (text, row) => (
        <Link to={`/leads/${row._id}`}>
          {getDomainIcon(row.application?.domain)}
          <p className="text-xs my-0">{text}</p>
        </Link>
      )
    },

    {
      title: 'Name',
      dataIndex: ['applications', 0, 'applicants', 0, 'userInfo', 'firstName'],
      key: 'name'
    },
    {
      title: 'Mobile',
      dataIndex: ['applications', 0, 'applicants', 0, 'userInfo', 'mobile'],
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: ['applications', 0, 'type'],
      key: 'type'
    },
    {
      title: 'Purchase Price',
      dataIndex: ['applications', 0, 'properties', 0, 'purchasePrice'],
      key: 'purchasePrice',
      render: (text) => currency(text)
    },
    {
      title: 'Deposit Amt',
      dataIndex: ['applications', 0, 'depositAmount'],
      key: 'depositAmount',
      render: (text) => {
        if (text) return currency(text);
        return 'N/A';
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Tag color={getLeadStatusColor(text)}>{text}</Tag>
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => dayjs(text).format('DD/MM/YYYY hh:mm a')
    }
  ];

  const onTableChange = async (pagination, filters, sorter) => {
    const options = {
      // term: searchText,
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      sort: sorter.order === 'descend' ? `-${sorter.field}` : sorter.field,
      ...filters
    };

    await fetchLeadsData(options);
  };

  const applicationColumns = [
    {
      title: 'Id',
      dataIndex: 'shortId',
      key: 'shortId',
      render: (text, row) => (
        <Link to={`/applications/${row._id}`}>
          {getDomainIcon(row.domain)}
          <p className="text-xs my-0">{text}</p>
        </Link>
      )
    },

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, row) => getApplicantFullName(row.applicants[0].userInfo)
    },
    {
      title: 'Mobile',
      dataIndex: ['applicants', 0, 'userInfo', 'mobile'],
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'No. of Applicants',
      dataIndex: 'numApplicants',
      render: (text) => (text === '1' ? 'Single' : 'Joint')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    }
  ];

  const onApplicationsTableChange = async (pagination, filters, sorter) => {
    const options = {
      // term: searchText,
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      sort: sorter.order === 'descend' ? `-${sorter.field}` : sorter.field,
      ...filters
    };
    await fetchApplicationsData(options);
  };

  return (
    <div style={{ margin: 10 }}>
      <Breadcrumb
        className="mb-3"
        items={[
          { title: <Link to="/">Home</Link> },
          { title: <Link to="/accounts">Accounts</Link> },
          { title: account?.shortId }
        ]}
      />
      <Card>
        <Row gutter={10}>
          <Col span={2}>
            <div className="p-3 bg-blue-600 rounded-md w-14 h-14">
              <FaHospitalUser className="text-3xl text-white" />
            </div>
          </Col>
          <Col span={4}>
            <Typography.Text>Account</Typography.Text>
            <p className="text-2xl">{`${account?.firstName} ${account?.lastName}`}</p>
          </Col>
          <Col span={4}>
            <Typography.Text>Phone</Typography.Text>
            <p className="mt-2">
              <PhoneOutlined /> <a href={`tel:${account?.mobile}`}>{account?.mobile}</a>
            </p>
          </Col>
          <Col span={6}>
            <Typography.Text>Email</Typography.Text>
            <p className="mt-2">
              <MailOutlined /> <a href={`mailto:${account?.email}`}>{account?.email}</a>
            </p>
          </Col>
          <Col span={4}>
            <Typography.Text>Owner</Typography.Text>
            <p className="mt-2">
              {' '}
              <Avatar icon={<UserOutlined />} size="small" /> John Doe
            </p>
          </Col>
        </Row>
      </Card>
      <div style={{ marginTop: 20 }}>
        <Row gutter={24}>
          <Col span={16}>
            <Card title="Applications">
              <Table
                dataSource={applications}
                columns={applicationColumns}
                onChange={onApplicationsTableChange}
                rowKey={(row) => row.shortId}
                pagination={{
                  total: applicationsMeta?.total,
                  pageSize: applicationsMeta?.limit
                }}
              />
            </Card>

            <Card title="Leads" bodyStyle={{ padding: 0 }} className="mt-4">
              <Table
                dataSource={leads}
                columns={leadsColumns}
                onChange={onTableChange}
                rowKey={(row) => row.shortId}
                pagination={{
                  total: leadsMeta?.total,
                  pageSize: leadsMeta?.limit
                }}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Notes">
              <Input.TextArea rows={3} />
              <div style={{ textAlign: 'right', paddingTop: 5 }}>
                <Button type="primary">Save</Button>
              </div>
              <List
                style={{ marginTop: 20 }}
                itemLayout="horizontal"
                dataSource={notesData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                      title={<a href="https://ant.design">{item.title}</a>}
                      description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                  </List.Item>
                )}
              />
            </Card>
            <Card className="mt-2">
              <div className="site-layout-background" style={{ padding: 10, paddingTop: 0 }}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Timeline" key="1">
                    <Timeline>
                      <Timeline.Item color="green">Create a services site 2015-09-01</Timeline.Item>
                      <Timeline.Item color="green">Create a services site 2015-09-01</Timeline.Item>
                      <Timeline.Item color="red">
                        <p>Solve initial network problems 1</p>
                        <p>Solve initial network problems 2</p>
                        <p>Solve initial network problems 3 2015-09-01</p>
                      </Timeline.Item>
                      <Timeline.Item>
                        <p>Technical testing 1</p>
                        <p>Technical testing 2</p>
                        <p>Technical testing 3 2015-09-01</p>
                      </Timeline.Item>
                      <Timeline.Item color="gray">
                        <p>Technical testing 1</p>
                        <p>Technical testing 2</p>
                        <p>Technical testing 3 2015-09-01</p>
                      </Timeline.Item>
                      <Timeline.Item color="gray">
                        <p>Technical testing 1</p>
                        <p>Technical testing 2</p>
                        <p>Technical testing 3 2015-09-01</p>
                      </Timeline.Item>
                      <Timeline.Item color="#00CCFF" dot={<SmileOutlined />}>
                        <p>Custom color testing</p>
                      </Timeline.Item>
                    </Timeline>
                  </TabPane>
                  <TabPane tab="SMSs" key="2">
                    Content of Tab Pane 2
                  </TabPane>
                  <TabPane tab="Notes" key="3">
                    Content of Tab Pane 3
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AccountView;
