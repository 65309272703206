/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { Card, Col, Row, Typography, Drawer } from 'antd';
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FcHome } from 'react-icons/fc';
import { currency } from '../../helpers';
import Application from '../application/Application';

function ApplicationWidget({ children, applications = [], leadId }) {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const showApplicationDrawer = () => setIsDrawerOpen(true);
  const hideApplicationDrawer = () => {
    navigate(`/leads/${leadId}/applications`);
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (applicationId) {
      setIsDrawerOpen(true);
    }
  }, [applicationId]);

  return (
    <>
      {applications.map((application) => (
        <div className="grid grid-cols-3 gap-4" key={application._id}>
          <Link
            to={`/leads/${leadId}/applications/${application._id}/applicants/${application.applicants[0]._id}/personal`}>
            <Card
              bodyStyle={{ padding: 10 }}
              className="mb-2 bg-blue-50 rounded-lg border-emerald-200 hover:bg-emerald-50">
              <Row gutter={8}>
                <Col span={6}>
                  {/* <img src='/files_re_v09g.svg' alt="application" width={100} height={'100%'} /> */}
                  <FcHome className="text-7xl" />
                </Col>
                <Col span={17}>
                  <Typography.Text strong style={{ display: 'block' }}>
                    Application - {1} - ({application.shortId})
                  </Typography.Text>
                  <Typography.Text
                    type="secondary"
                    style={{ display: 'block', fontSize: '0.65rem' }}>
                    {dayjs(application.createdAt).format('DD/MM/YYYY hh:mm a')}
                  </Typography.Text>
                  <Row gutter={24}>
                    <Col span={8}>
                      <div className="mt-2">
                        <Typography.Text strong style={{ display: 'block' }}>
                          Value
                        </Typography.Text>
                        <p className="">{currency(application.properties[0].purchasePrice)}</p>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="mt-2">
                        <Typography.Text strong style={{ display: 'block' }}>
                          Deposit
                        </Typography.Text>
                        <p className="">{currency(application.depositAmount)}</p>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="mt-2">
                        <Typography.Text strong style={{ display: 'block' }}>
                          Loan Term
                        </Typography.Text>
                        <p className="">{application.loanTermYears} Yrs</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Link>
        </div>
      ))}
      <Drawer
        open={isDrawerOpen}
        title="Details"
        placement="right"
        width="82%"
        onClose={hideApplicationDrawer}>
        <Application isWidget>{children}</Application>
      </Drawer>
    </>
  );
}

export default ApplicationWidget;
