import useSWR from 'swr';
import { API_HOST } from '../helpers/constants';
import { fetcher } from '../utils';

export function usePage(id) {
  const url = `${API_HOST}/pages/${id}`;

  const { data: responseData, error, isLoading, mutate } = useSWR(id ? url : null, fetcher);

  const revalidate = () => mutate(url);

  return {
    page: responseData,
    isLoading,
    isError: error,
    revalidate
  };
}
