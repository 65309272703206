/* eslint-disable react/prop-types */
import { Col, Typography } from 'antd';
import { currency } from '../../helpers';

export default function CarLoanFields({ application }) {
  return application?.type === 'Refinance' ? (
    <>
      <Col span={4}>
        <Typography.Text strong>Current Loan ($)</Typography.Text>
        <p className="mt-1">{currency(application?.currentLoanAmount)}</p>
      </Col>
      <Col span={4}>
        <Typography.Text strong>Curr. Int Rate(%)</Typography.Text>
        <p className="mt-1">{application?.currentInterestRate}%</p>
      </Col>
      <Col span={4}>
        <Typography.Text strong>Current Lender</Typography.Text>
        <p className="mt-1">{application?.currentLender}</p>
      </Col>
      <Col span={5}>
        <Typography.Text strong>Refinance Reason</Typography.Text>
        <p className="mt-1">{application?.refinanceReason}</p>
      </Col>
    </>
  ) : (
    <>
      <Col span={4}>
        <Typography.Text strong>Purchase Price</Typography.Text>
        <p className="mt-1">{currency(application.purchasePrice)}</p>
      </Col>
      <Col span={3}>
        <Typography.Text strong>Deposit</Typography.Text>
        <p className="mt-1">{currency(application.depositAmount)}</p>
      </Col>
      <Col span={4}>
        <Typography.Text strong>Buying Situation</Typography.Text>
        <p className="mt-1">{application.buyingSituation || '--'}</p>
      </Col>
    </>
  );
}
