/* eslint-disable react/prop-types */
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Menu,
  Row,
  Select,
  Typography
} from 'antd';
import SettlementChecklistItem from './SettlementChecklistItem';
import { Link, useParams } from 'react-router-dom';
import { API_HOST, SETTLEMENT_STATUSES, STATUS_SETTLED } from '../../../helpers/constants';
import { fetcher, headers } from '../../../utils';
import useSWR from 'swr';
import axios from 'axios';
import { openNotification } from '../../../helpers';
import dayjs from 'dayjs';
import { useState } from 'react';

export default function Settlement({ children }) {
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application, mutate } = useSWR(applicationUrl, fetcher);
  const checklistUpdateEndpoint = `${applicationUrl}/settlement/checklist`;
  const settlementEndpoint = `${applicationUrl}/settle-application`;
  const settlementStatusEndpoint = `${applicationUrl}/settlement/status`;
  const settlementData = application.loanDetails?.settlement;
  const [isSettlingApplication, setIsSettlingApplication] = useState(false);

  const fallbackChecklistItems = [
    {
      title: 'Identification'
    },
    {
      title: 'Documents'
    },
    {
      title: 'Income'
    },
    {
      title: 'Expenses'
    },
    {
      title: 'Compliance'
    },
    {
      title: 'Revenue Details'
    }
  ];

  const checklistData = settlementData?.checklist || fallbackChecklistItems;

  const items = [
    {
      key: 'settlementAgent',
      label: 'Settlement Agent',
      children: <p className="font-semibold">John Doe</p>,
      span: 3
    },

    {
      key: 'assignedAt',
      label: 'Assigned',
      children: <p className="font-semibold">John Doe</p>,
      span: 3
    },
    {
      key: 'settlementEta',
      label: 'Settlement ETA',
      children: (
        <p className="font-semibold">
          {dayjs(application?.loanDetails?.decision?.settlementEta).format('DD/MM/YYYY hh:mm a') ||
            '--'}
        </p>
      ),
      span: 3
    }
  ];

  const sideMenuItems = [
    {
      key: 'documents',
      label: <Link to={`/applications/${applicationId}/settlement/documents`}>Documents</Link>
    },
    {
      key: 'sellerDetails',
      label: (
        <Link to={`/applications/${applicationId}/settlement/seller-details`}>Seller details</Link>
      )
    },
    {
      key: 'loanDetails',
      label: <Link to={`/applications/${applicationId}/settlement/loan-details`}>Loan Details</Link>
    },
    {
      key: 'warrantyInsurance',
      label: (
        <Link to={`/applications/${applicationId}/settlement/warranty-insurance`}>
          Warranty & Insurance
        </Link>
      )
    }
  ];

  const onChecklistStateUpdate = async (updatedItem) => {
    const payload = checklistData.map((item) =>
      item.title == updatedItem.title ? updatedItem : item
    );
    try {
      await axios.put(checklistUpdateEndpoint, payload, { headers: headers });
      mutate();
    } catch (error) {
      console.log(error);
      openNotification(
        'Could not update settlement checklist',
        error.response?.data?.message,
        true
      );
    }
  };

  const settleApplication = async () => {
    try {
      setIsSettlingApplication(true);
      await axios.post(settlementEndpoint, null, { headers });
      setIsSettlingApplication(false);
      openNotification('Application settled successfully!');
      await axios.patch(applicationUrl, { status: STATUS_SETTLED }, { headers });
      mutate();
    } catch (error) {
      console.log(error);
      setIsSettlingApplication(false);
      openNotification('Could not settle application', error.response?.data?.message, true);
    }
  };

  const onSettlementStatusChange = async (status) => {
    try {
      await axios.put(settlementStatusEndpoint, { status }, { headers });
      mutate();
    } catch (error) {
      console.log(error);
      openNotification('Could not update settlement status', error.response?.data?.message, true);
    }
  };

  return (
    <div className="mx-5">
      <Alert
        message={<Typography.Text strong>Settlement</Typography.Text>}
        description={
          settlementData?.settledAt ? (
            <span>
              Application was settled at{' '}
              {dayjs(settlementData?.settledAt).format('DD/MM/YYYY hh:mm a')}
            </span>
          ) : (
            <span className="!text-xs">Use the CTA aside to settle the application</span>
          )
        }
        type={settlementData?.settledAt ? 'success' : 'info'}
        showIcon
        action={
          !settlementData?.settledAt && (
            <Button
              className="mt-3 mr-2"
              type="primary"
              onClick={settleApplication}
              loading={isSettlingApplication}>
              Mark as settled
            </Button>
          )
        }
      />

      <Form className="mt-6">
        <Form.Item label={<span className="font-semibold">Settlement Status</span>}>
          <Select
            style={{ width: '260px' }}
            options={SETTLEMENT_STATUSES.map((s) => ({ label: s, value: s }))}
            onChange={onSettlementStatusChange}
            defaultValue={settlementData?.status}></Select>
        </Form.Item>
      </Form>
      <Row gutter={8} className="mt-4">
        <Col span={20}>
          {/* Settlement Checklist */}
          <Card type="inner" title="Settlement Checklist">
            <Row gutter={[116, 24]} className="pt-4">
              {checklistData.map((item) => (
                <Col span={12} key={item.title}>
                  <SettlementChecklistItem
                    data={item}
                    onChecklistStateUpdate={onChecklistStateUpdate}
                  />
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
        {/* Settlement agent, ETA, etc */}
        <Col span={4}>
          <Card size="small">
            <Descriptions items={items} layout="vertical" colon={false} />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row className="mt-8">
        <Col span={4}>
          <Menu items={sideMenuItems} defaultSelectedKeys={['documents']} />
        </Col>
        <Col span={20} className="px-4 pt-1">
          {children}
        </Col>
      </Row>
    </div>
  );
}
