import { useParams } from 'react-router-dom';
import { API_HOST } from '../../../helpers/constants';
import useSWR from 'swr';
import { fetcher, headers } from '../../../utils';
import { useState } from 'react';
import { Form } from 'antd';
import CommonSettlementSection from '../../common/CommonSettlementSection';
import axios from 'axios';
import { openNotification } from '../../../helpers';

export default function SettlementLoanDetails() {
  const { applicationId } = useParams();
  const applicationUrl = `${API_HOST}/applications/${applicationId}`;
  const { data: application, mutate } = useSWR(applicationUrl, fetcher);
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoanIdModalOpen, setIsLoanIdModalOpen] = useState(false);
  const [isUpdatingLoanId, setIsUpdatingLoanId] = useState(false);
  const loanIdUpdateEndpoint = `${applicationUrl}/loan-id`;
  const loanDetailsUpdateEndpoint = `${applicationUrl}/settlement/loan-details`;

  const loanDetails = application?.loanDetails;
  const preSettlementData = loanDetails?.preSettlement;
  const settlementData = loanDetails?.settlement;

  const saveSettlementLoanDetails = async (data) => {
    try {
      setIsSubmitting(true);
      await axios.put(loanDetailsUpdateEndpoint, data, { headers });
      setIsSubmitting(false);
      setIsEditing(false);
      openNotification('Loan details updated successfully');
      mutate();
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      openNotification('Could not update settlement', error.response?.data?.message, true);
    }
  };

  const onLoanDetailsEditClick = () => {
    setIsEditing(true);
  };

  const onLoanIdModalSubmission = async (data) => {
    try {
      setIsUpdatingLoanId(true);
      await axios.put(loanIdUpdateEndpoint, data, { headers });
      mutate();
      setIsUpdatingLoanId(false);
      setIsLoanIdModalOpen(false);
      openNotification('Loan ID updated successfully');
    } catch (error) {
      console.log(error);
      setIsUpdatingLoanId(false);
      openNotification('Could not update Loan ID', error.response?.data?.message, true);
    }
  };

  return (
    <div className="ml-2">
      <CommonSettlementSection
        currentTabData={settlementData}
        previousTabData={preSettlementData}
        loanDetails={loanDetails}
        setIsLoanIdModalOpen={setIsLoanIdModalOpen}
        isLoanIdModalOpen={isLoanIdModalOpen}
        onLoanIdModalSubmission={onLoanIdModalSubmission}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        saveData={saveSettlementLoanDetails}
        onLoanDetailsEditClick={onLoanDetailsEditClick}
        isSubmitting={isSubmitting}
        isUpdatingLoanId={isUpdatingLoanId}
        form={form}
        alwaysShowEditsInHeader={true}
      />
    </div>
  );
}
